import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col, Button, Card, FormControl} from 'react-bootstrap';
import * as apiCalls from '../services/api';
import {addError, removeError} from '../store/actions/errors';
import {addSeries} from '../store/actions/fredcache';
import {loadFredSeries, importFredSeries} from './FredSeries.js';

class FredTagsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
            apiinfo: 'FRED economic data API',
            apiurl:  'https://fred.stlouisfed.org/docs/api/fred/',
            newtag: '', 
			tags: [],
			series_id:    '',
			series_title: '',
			observation_start: '', 
			observation_end: '',
			frequency: 'month', 
			last_updated: '',
			varnames: [], 
			vardata: [],
            limit:  100,
            page:     1,
            maxpage:  1,
            tags_changed: false,
            series_imported: false,
            import_as: '',
        };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.timeformat = [
            {name: 'day',        id: 'd'}, 
            {name: 'week',       id: 'w'}, 
            {name: 'biweek',     id: 'bw'}, 
            {name: 'month',      id: 'm'}, 
            {name: 'quarter',    id: 'q'}, 
            {name: 'semiannual', id: 'sa'}, 
            {name: 'annual',     id: 'a'}
        ];

		this.series   = [];
        this.series_page_fn = null;

		this.controller = null;
	}

	componentDidMount() {
        this.controller = new window.AbortController();
	}

	componentWillUnmount() {
        this.controller.abort();
  	}

	async loadTagSeries() {
        let {tags, page, limit, maxpage} = this.state;

        var resp  = null;
        try {
            document.body.style.cursor = "wait";
            resp = await apiCalls.fredTagSeries(tags, limit, page, {signal:this.controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }
        if (!resp) {
            return;
        }
        if (!resp.series || resp.series.length < 1) {
            this.props.addError('Tags ' + tags + ' not found', 'alert-danger');
            this.series = [];
            await this.setState({tags: [], newtag: ''});
            return;
        }
        if (typeof resp.limit !== 'undefined') {
            limit   = resp.limit;
            //page  = 1 + Math.floor((resp.series.length-1) / resp.limit);
            maxpage = 1 + Math.floor(resp.count / resp.limit);
        }

        this.series = resp.series;
        this.series_page_fn = this.loadTagSeries;
        await this.setState({limit, page, maxpage});

		await this.loadSeries();
	}

	async loadSeries() {
        let {series_id} = this.state;
        if (series_id === '') {
            if (this.series.length > 0) {
                series_id = this.series[0].id;
            }
        }
        let seriesInfo = await loadFredSeries(
            series_id,
            {series:this.props.fredcache.series, addSeries: this.props.addSeries}, 
            this.props.addError, 
            this.controller
        );
        if (!seriesInfo) {
            return;
        }
		this.setState({
			series_id: series_id, 
			series_title: seriesInfo.title,
			observation_start: seriesInfo.observation_start,
			observation_end: seriesInfo.observation_end,
			frequency: seriesInfo.frequency.toLowerCase(),
            import_as: series_id,
            tags_changed: false,
            series_imported: false
		});
	}

	async handleChange(event) {
        var {tags, newtag} = this.state;
        this.props.removeError();
        switch(event.target.name) {
            case 'newtag':
                await this.setState({'newtag': event.target.value, tags_changed: true});
                break;
			case 'tags-add':
                newtag.split(' ').forEach((s) => {
                    s = s.toLowerCase(s);
                    if (s !== '' & !tags.includes(s)) tags.push(s);
                });
                await this.setState({tags: tags, newtag: ''});
                await this.loadTagSeries();
                break;
            case 'tags-clear':
                await this.setState({tags: [], newtag: '', tags_changed: false});
                break;
			case 'series_id':
                await this.setState({series_id: event.target.value, series_imported: true});
				this.loadSeries();
				break;
            case 'page':
                if (this.series_page_fn) {
                    this.series_page_fn();
                }
                break;
			default:
                await this.setState({[event.target.name]: event.target.value, series_imported: false});
		}
	}

	async handleSubmit(event) {
        let {series_id, frequency, observation_start, observation_end, import_as} = this.state;

        let series = await importFredSeries(
            series_id, 
            frequency, 
            observation_start, 
            observation_end, 
            import_as,
            this.props.addError,
            this.controller
        );
        if (!series) {
            return;
        }
		this.props.importSeries(series.import_as, series.timeformat, 
			series.timeindex, series.timeutc, series.timestamps, series.observations);
        this.setState({series_imported: true});
	}

	render() {
		const {newtag, tags, series_title, series_id, observation_start, observation_end, frequency,
            apiinfo, apiurl, maxpage, import_as, tags_changed, series_imported} = this.state;

        let frequencyOptions = [];
        if (this.timeformat) {
		    frequencyOptions = this.timeformat.map((tf, id) =>
                <option key={id} value={tf.id}>{tf.name}</option>);
        }

        let tags_list = '';
        if (tags.length > 0) {
            tags_list = tags.join(' ');
        }

        let seriesOptions = [];
        if (this.series) {
            seriesOptions = this.series.map((s) => <option key={s.id} value={s.id}>{s.title}</option>);
        }

        let pageOptions = [];
        if (maxpage > 1) {
            pageOptions = new Array(maxpage);
            for (var i = 1; i <=maxpage; i++) {
                pageOptions[i-1] = <option key={i} value={i}>{'page '+i}</option>;
            }
        }
    
		return (
			<Container className="mt-2" fluid>

                {apiinfo ? 
                    <Card className="m-1 notification" style={{'backgroundColor': 'whitesmoke'}}>
                        {apiinfo} 
                        <a href={apiurl} target="_blank" rel="noreferrer noopener">{apiurl}</a>
                    </Card>: ''
                }

				<Row className="g-1">
                    
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">{`Search tags: ${tags_list}`}</Card.Header>
                            <Card.Body className="card-body">
                                <FormControl type="text" id="newtag" name="newtag" value={newtag} 
                                    onChange={this.handleChange}>
                                </FormControl>
                                {tags_changed ? 
                                <Button className="m-2" variant="primary" id="tags-clear" name="tags-clear" 
                                    onClick={this.handleChange}>
                                    Clear
                                </Button> :
                                <Button className="m-2" variant="primary" id="tags-clear" name="tags-clear" 
                                onClick={this.handleChange} disabled>
                                    Clear
                                </Button>
                                }
                                {tags_changed ? 
                                <Button className="m-2" variant="primary" id="tags-add" name="tags-add" 
                                    onClick={this.handleChange}>
                                    Add
                                </Button> :
                                <Button className="m-2" variant="primary" id="tags-add" name="tags-add" 
                                    onClick={this.handleChange} disabled>
                                    Add
                                </Button>
                                }
                            </Card.Body>
                        </Card>
                    
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Series</Card.Header>
                            <Card.Body className="card-body">
                                <Row className="g-1">
                                    <Col xs="8">
                                    <select className="form-control" name="series_id" onChange={this.handleChange}>
                                        {seriesOptions}
                                    </select>
                                    </Col>
                                    <Col xs="4">
                                    <select className="form-control" name="page" onChange={this.handleChange}>
                                        {pageOptions}
                                    </select>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
				</Row>

                <Row className="g-1">
                    <label className="series-title" area-label="Series title">
                        {series_title}
                    </label>
                </Row>

		        <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Start
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control" 
                                    name = "observation_start" 
                                    value={observation_start} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
                    
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                End
                            </Card.Header>
                            <Card.Body className="card-body">
		        		        <input type="text" className="form-control"
                                    name = "observation_end" 
		        			        value={observation_end} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
					</Col>

					<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Frequency
                            </Card.Header>
                            <Card.Body className="card-body">
                                <select className="form-control" name="frequency" 
                                    onChange={this.handleChange} value={frequency}>
                                    {frequencyOptions}
                                </select>
                            </Card.Body>
                        </Card>
		        	
                        {series_id !== '' ? 
                            <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Import as
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control"
                                    name = "import_as" 
		        			        value={import_as} onChange={this.handleChange} />
                            </Card.Body>
                            </Card>
                            : '' 
                        }
                    </Col>
                </Row>
                {import_as !== '' & !series_imported ? 
                    <Button className="mt-2" variant="primary" id="categories-up" 
                        onClick={this.handleSubmit}>
                        Submit
                    </Button>
                    : '' 
                }
			</Container>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {fredcache: state.fredcache};
}

export default connect(mapStateToProps, {addSeries, addError, removeError})(FredTagsForm);