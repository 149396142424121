import {ADD_TSCHART, REMOVE_TSCHART} from '../actionTypes';

// action creators

export function addTschart(tschart) {
	return {
		type: ADD_TSCHART,
		tschart
	}
}

export function removeTschart(varname) {
	return {
		type: REMOVE_TSCHART,
		varname
	}
}