import React, {Component} from 'react';
import {connect} from "react-redux";
import {Tab, Nav} from 'react-bootstrap';

import {removeError} from '../store/actions/errors';
import {loadLoglik} from '../store/actions/model';
import {MODEL_STATUS_COMPILED, MODEL_STATUS_SAMPLED} from '../store/actionTypes';

import ModelDescription from '../components/ModelDescription';
import DataSources  from './DataSources';
import DataGraphs   from './DataGraphs';
import DataTable    from './DataTable';
import ModelUpdate  from './ModelUpdate';
import FcastSample  from './FcastSample';
import IrfSample    from './IrfSample';

class ModelShow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			keytab: '1'
		}
		this.handleSelectTab  = this.handleSelectTab.bind(this);
        this.onUnload  = this.onUnload.bind(this);
	}

    onUnload(event) {
        const {status} = this.props;
        if (status === MODEL_STATUS_SAMPLED || status === MODEL_STATUS_COMPILED) {
            event.preventDefault();
            return event.returnValue = "";
        }
    }

    async componentDidMount() {
        const {user_id, id} = this.props;
        // set model in global redux state
        // call twice!!
 		await this.props.loadLoglik(id, user_id);

        this.props.removeError();

        window.addEventListener('beforeunload', this.onUnload);
 	}

    componentWilUnmount() {
        window.removeEventListener('beforeunload', this.onUnload);
    }

	handleSelectTab(keytab) {
        this.props.removeError();
		this.setState({keytab});
	}
	
	render() {
        const {errors, user_id, id, status, mcmcsample, loglik} = this.props;
        if (!loglik) {
            return <div></div>;
        }
        const {variables} = this.props.datatable;
        const {irf, forecast} =  loglik;
		// transition={true} throws 'Warning: findDOMNode is deprecated in StrictMode...'
        const {keytab} = this.state;
        // React.StrictMode renders twice!!
		return (
			<div>
				<ModelDescription loglik={loglik} short={false} hasname={true} />

                <div className="text-center">
                    {errors.message && (<div className={"m-2 mt-4 alert "+errors.style}> {errors.message} </div>)}
                </div>

                <Tab.Container activeKey={keytab} onSelect={this.handleSelectTab}>
                    <Nav variant="pills" className="main-tabs justify-content-center mt-2 p-2">
                        <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={1}>Data</Nav.Link>
                        </Nav.Item>
                        {variables.length > 0 ? 
                            <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={2}>Graphs</Nav.Link>
                            </Nav.Item> : null
                        }
                        {variables.length > 0 ? 
                            <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={3}>Table</Nav.Link>
                            </Nav.Item> : null
                        }
                        {status >= MODEL_STATUS_COMPILED ? 
                            <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={4}>Update</Nav.Link>
                            </Nav.Item> : null
                        }
                        {forecast & mcmcsample != null ?
                            <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={5}>Forecast</Nav.Link>
                            </Nav.Item> : null
                        }
                        {irf & mcmcsample != null ?
                            <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={6}>IRF</Nav.Link>
                            </Nav.Item> : null
                        }
                    </Nav>
                    <Tab.Content className="main-tabs-container m-2">
                        <Tab.Pane eventKey={1}>
                            {keytab === '1' ? <DataSources /> : ''}
                        </Tab.Pane>

                        <Tab.Pane eventKey={2}>
                            {keytab === '2' ? <DataGraphs user_id={user_id} id={id} /> : ''}
                        </Tab.Pane>

                        <Tab.Pane eventKey={3}>
                            {keytab === '3' ? <DataTable user_id={user_id} id={id} /> : ''}
                        </Tab.Pane>

                        {status >= MODEL_STATUS_COMPILED ? 
                            <Tab.Pane eventKey={4}>
                                {keytab === '4' ? <ModelUpdate user_id={user_id} id={id} /> : ''}
                            </Tab.Pane> : null
                        }

                        {forecast & mcmcsample != null ? 
                            <Tab.Pane eventKey={5}>
                                {keytab === '5' ? 
                                    <FcastSample user_id={user_id} id={id} /> : ''
                                }
                            </Tab.Pane> : null
                        }

                        {irf & mcmcsample != null ? 
                            <Tab.Pane eventKey={6}>
                                {keytab === '6' ? 
                                    <IrfSample user_id={user_id} id={id} /> : ''
                                }
                            </Tab.Pane> : null
                        }

                    </Tab.Content>
                </Tab.Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
        errors:     state.errors,
        status:     state.model.status, 
        loglik:     state.model.loglik,
        datatable:  state.datatable,
        mcmcsample: state.model.mcmcsample
    };
}

export default connect(mapStateToProps, {removeError, loadLoglik})(ModelShow);
