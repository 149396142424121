import {ADD_TSCHART, REMOVE_TSCHART} from '../actionTypes';

const initialState = {
	tscharts: [], // TsChart() objects
};

// variables reducer function (state, action)
const datagraphs = (state = initialState, action) => {
    let newState = {};
	switch (action.type) {
        case ADD_TSCHART:
            newState = {...state};
            newState.tscharts = [...state.tscharts, action.tschart];
            return newState;
		case REMOVE_TSCHART:
            //newState = Object.assign({}, state);
			newState = {...state};
            if (!action.varname) {
                return state;
            }
			newState.tscharts = state.tscharts.filter((v) => v.varname !== action.varname);
            return newState;
		default:
			return state;
	}
}

export default datagraphs;