class McmcChart {
	constructor(parentRef, sample, clientRatio) {

		this.parentRef = parentRef;

		this.canvases = [];
		this.sample = sample;

        this.canvas_width  = 0;
        this.canvas_height = 0;

		this.x_padding = 8;
		this.y_padding = 8;

        this.histobins = 13;
		this.colorLine = undefined;//"#0f40ee";
	    this.lineWidth = 2;
		this.colorFill = undefined;//"#444";

        if (!clientRatio) {
            this.clientRatio = 0.8;
        }
        else {
            this.clientRatio = clientRatio;
        }
	}

	setup_canvas(className) {

		var canvas = document.createElement("canvas");
		canvas.className = className;

		this.parentRef.appendChild(canvas);
		// use common size
        canvas.width  = this.canvas_width;
		canvas.height = this.canvas_height;
		// setup high DPI text
		// Get the device pixel ratio, falling back to 1.
		var dpr = window.devicePixelRatio || 1;
		// Get the size of the canvas in CSS pixels.
		//var rect = canvas.getBoundingClientRect();
		// Give the canvas pixel dimensions of their CSS
		// size * the device pixel ratio.
		//canvas.width  = rect.width * dpr;
		//canvas.height = rect.height * dpr;
		var ctx = canvas.getContext('2d');
		// Scale all drawing operations by the dpr, so you
		// don't have to worry about the difference.
		ctx.scale(dpr, dpr);

		return {canvas, ctx};
	}

	/**
	 * Base64 to Blob
	 *
	 * @param {string} data Data.
	 * @param {string} type Content type.
	 * @return {!Blob} Blob.
	 */
	base64toBlob = function(data, type) {
		//atob(): decodes a string of data which has been encoded using Base64 encoding
		const bytes = window.atob(data);
		let length = bytes.length;
		let out = new Uint8Array(length);
		// Loop and convert.
		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}
		return new Blob([out], { type: type });
	};

	save() {
		var image = this.canvases[0].toDataURL("image/png");
		//show it in current page
		//document.write('<img src="'+image+'"/>');
		image = image.split(',')[1];
		let blob = this.base64toBlob(image, 'image/png');

        let link = document.createElement("a");
        link.download = 'mcmcchart.png';
        //link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
	}

    drawTracePlots() {
		var i, res;
		if (!this.sample) {
			return;
		}
        this.canvas_width = this.parentRef.clientWidth*this.clientRatio;
		if(this.canvas_width > 800) {
            this.canvas_width = 800;
        }
		this.canvas_height = this.canvas_width/4;
		for (i = 0; i < this.sample.dimension(); i++) {
			res = this.setup_canvas("mcmcplot");
			this.canvases.push(res.canvas);
			//this.sample.plot_trace(i, res.canvas, this.x_padding, this.y_padding, 
			//	this.colorLine, this.lineWidth, this.colorFill);
			this.sample.plot_trace(i, res.canvas, this.x_padding, this.y_padding, 
				this.colorLine, this.lineWidth, this.colorFill);
		}
	}

	drawHistogramPlots() {
        var i, res;
		if (!this.sample) {
			return;
		}
        this.canvas_width = this.parentRef.clientWidth*this.clientRatio;
		if(this.canvas_width > 800) {
            this.canvas_width = 800;
        }
		this.canvas_height = this.canvas_width/4;

        for (i = 0; i < this.sample.dimension(); i++) {
			res = this.setup_canvas("mcmcplot");
            this.canvases.push(res.canvas);
			//this.sample.plot_trace(i, res.canvas, this.x_padding, this.y_padding, 
			//	this.colorLine, this.lineWidth, this.colorFill);
			this.sample.plot_histogram(i, res.canvas, this.x_padding, this.y_padding, 
				this.histobins, this.colorLine, this.lineWidth, this.colorFill);
		}
	}

    drawPiePlots() {
		var res;
		if (!this.sample) {
			return;
		}
        this.canvas_width = this.parentRef.clientWidth*this.clientRatio;
		if(this.canvas_width > 800) {
            this.canvas_width = 800;
        }
		this.canvas_height = this.canvas_width/4;

		res = this.setup_canvas("mcmcplot");
		this.canvases.push(res.canvas);
        this.sample.plot_piechart(res.canvas, 2, 4, 64, 
            this.colorLine, this.lineWidth, this.colorFill);
	}
    
    drawFcastPlots() {
        var i, res;
        //this.sample is FcastSample
		if (!this.sample) {
			return;
		}
        this.canvas_width = this.parentRef.clientWidth*this.clientRatio;
		if(this.canvas_width > 800) {
            this.canvas_width = 800;
        }
		this.canvas_height = this.canvas_width/4;
        for (i = 0; i < this.sample.dimension(); i++) {
            res = this.setup_canvas("mcmcplot");
			this.canvases.push(res.canvas);
            this.sample.plot(i, res.canvas, this.x_padding, this.y_padding, 
				this.colorLine, this.lineWidth, this.colorFill);
		}
    }

    drawIrfPlots() {
        var i, res;
        //this.sample is FcastSample
		if (!this.sample) {
			return;
		}
        this.canvas_width = this.parentRef.clientWidth*this.clientRatio;
		if(this.canvas_width > 800) {
            this.canvas_width = 800;
        }
		this.canvas_height = this.canvas_width/4;
        for (i = 0; i < this.sample.dimension(); i++) {
			res = this.setup_canvas("mcmcplot");
			this.canvases.push(res.canvas);
            this.sample.plot(i, res.canvas, this.x_padding, this.y_padding, 
				this.colorLine, this.lineWidth, this.colorFill);
		}
    }

}

export default McmcChart;