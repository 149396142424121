const APIURL        = process.env.APIURL || 'https://bayeswork.com/api';
const APIAUTHURL    = APIURL + '/auth';
const APIJSFILESURL = APIURL + '/jsfile';
const APIMODELURL   = APIURL + '/user';
const FREDURL       = APIURL + '/fred';
const POLYGONURL    = APIURL + '/polygon';
const EIAURL        = APIURL + '/eia';
const NOAAURL       = APIURL + '/noaa';

// if successful, all api functions return Promise, resp.json()
// and so they have to be called with -await apiFunc()-

export function authRequest(type, userData) {
    return fetch(`${APIAUTHURL}/${type}`, {
        method: 'post',
        headers: new Headers({'Content-Type':'application/json'}),
        body: JSON.stringify(userData)
    })
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {    
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
}

export function loadBaseScripts() {
    // have to send all fetch request to the API post (e.g. 8081)
    // set proxy in package.json!
    return fetch(APIJSFILESURL)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

export function loadModels(user_id, token) {
    // have to send all fetch request to the API post (e.g. 8081)
    // set proxy in package.json!
    let headers = {'Content-Type':'application/json'};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(`${APIMODELURL}/${user_id}/bmodels`, {headers})
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {    
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

export function addModel(model, user_id, token) {
    let headers = {'Content-Type':'application/json'};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(`${APIMODELURL}/${user_id}/bmodels`, {
        method: 'post',
        headers, 
        body: JSON.stringify(model)
    })
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

export function showModel(id, user_id, token) {
    const showURL = `${APIMODELURL}/${user_id}/bmodels/${id}`;
    let headers = {'Content-Type':'application/json'};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(showURL, {
        method: 'get',
        headers
    })
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

export function deleteModel(id, user_id, token) {
    const deleteURL = `${APIMODELURL}/${user_id}/bmodels/${id}`;
    let headers = {'Content-Type':'application/json'};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(deleteURL, {
        method: 'delete',
        headers
    })
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

export function updateModel(model, user_id, token) {
    const id = model._id;
    const updateURL = `${APIMODELURL}/${user_id}/bmodels/${id}`;
    let headers = {'Content-Type':'application/json'};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(updateURL, {
        method: 'put',
        headers, 
        body: JSON.stringify({
            //depvars: model.depvars, 
            //indepvars: model.indepvars,
            estsize: model.estsize,
            params: model.params,
        })
    })
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    });
}

///////////////////////////////////////////////////////////////////////////////
// fred

export function fredCategory(category_id, init) {
    return fetch(FREDURL+'/category/'+category_id, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredCategoryChildren(category_id, init) {
    return fetch(FREDURL+'/category/children/'+category_id, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredCategorySeries(category_id, limit, page, init) {
    return fetch(FREDURL+'/category/series/'+category_id+'/'+limit+'/'+page, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredSources(init) {
    return fetch(FREDURL+'/sources', init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, errorMesage: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredSourceReleases(source_id, init) {
    return fetch(FREDURL+'/source/releases/'+source_id, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredReleases(init) {
    return fetch(FREDURL+'/releases', init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredReleaseSeries(release_id, limit, page, init) {
    return fetch(FREDURL+'/release/series/'+release_id+'/'+limit+'/'+page, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredTagSeries(tags, limit, page, init) {
    // input tags: string array
    // output is ;-separated tags
    tags = tags.join(';');
    console.log("fredTagSeries", tags);
    return fetch(FREDURL+'/tags/series/'+tags+'/'+limit+'/'+page, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredSeries(series_id, init) {
    return fetch(FREDURL+'/series/'+series_id, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function fredObservations(series_id, frequency, start, end, init) {
    let url = FREDURL+'/series/observations/'+series_id+'/'+frequency+'/'+start+'/'+end;
    return fetch(url, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

///////////////////////////////////////////////////////////////////////////////
// polygon

export function polygonTickers(init) {
    return fetch(POLYGONURL+'/tickersStart', init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function polygonTickerAggregates(ticker, multiplier, timespan, from, to, init) {
    let url = POLYGONURL + 
            '/tickerAggregates/' + ticker + 
            '/' + multiplier + 
            '/' + timespan + 
            '/' + from + 
            '/' + to;
    return fetch(url, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

///////////////////////////////////////////////////////////////////////////////
// eia

export function eiaRoutes(route_ids, init) {
    var call = EIAURL+'/routes';
    if (route_ids !== '') {
        call += ('/'+route_ids.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function eiaFacets(route_ids, facet_id, init) {
    var call = EIAURL+'/facets';
    if (route_ids !== '') {
        call += ('/'+route_ids.replace(/\//g,'_'));
    }
    call += ('/'+facet_id);
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function eiaMetaData(route_ids, facet_id, facet_val, init) {
    var call = EIAURL+'/metadata';
    if (route_ids !== '') {
        call += ('/'+route_ids.replace(/\//g,'_'));
    }
    if (facet_id !== '') {
        call += ('/'+facet_id+'_'+facet_val);
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function eiaData(route_ids, data_id, facet_ids, facet_vals, frequency, start, end, init) {
    var call = EIAURL+'/data';
    if (route_ids !== '') {
        call += ('/'+route_ids.replace(/\//g,'_'));
    }
    call += ('/' + data_id);
    call += ('/' + frequency);
    var facetstr = '';
    facet_ids.forEach((id, i) => {
            if (facet_vals[i] === '') {
                return;
            }
            if (i > 0) {
                facetstr += '__';
            }
            facetstr += (id+'_'+facet_vals[i]);
    });
    if (facetstr !== '') {
        call += ('/' + facetstr);
    }
    else {
        call += '/__';
    }
    if (start !== '') {
        call += ('/'+start);
    }
    else {
        call += '/';
    }
    if (end !== '') {
        call += ('/'+end);
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

///////////////////////////////////////////////////////////////////////////////
// NOAA

export function noaaDatasets(dataset_id, init) {
    var call = NOAAURL+'/datasets';
    if (dataset_id !== '') {
        call += ('/'+dataset_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaData(datasetid, datatypeid, locationid, stationid, startdate, enddate, init) {
    var call = NOAAURL+'/data';
    call += '/'+datasetid;
    call += '/'+datatypeid;
    call += '/'+locationid;
    call += '/'+stationid;
    call += '/'+startdate;
    call += '/'+enddate;
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaDataCategories(category_id, init) {
    var call = NOAAURL+'/datacategories';
    if (category_id !== '') {
        call += ('/'+category_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaDataTypes(type_id, init) {
    var call = NOAAURL+'/datatypes';
    if (type_id !== '') {
        call += ('/'+type_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaLocationCategories(category_id, init) {
    var call = NOAAURL+'/locationcategories';
    if (category_id !== '') {
        call += ('/'+category_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaLocations(location_id, init) {
    var call = NOAAURL+'/locations';
    if (location_id !== '') {
        call += ('/'+location_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}

export function noaaStations(station_id, init) {
    var call = NOAAURL+'/stations';
    if (station_id !== '') {
        call += ('/'+station_id.replace(/\//g,'_'));
    }
    return fetch(call, init)
    .then(resp => {
        if (!resp.ok) {
            if (resp.status >= 400 && resp.status <= 500) {
                return resp.json()
                .then(data => {
                    let err = {status: resp.status, message: data.error.message};
                    throw err;
                });
            }
            else {
                let err = {status: 502, message: 'Server not responding'};
                throw err;
            }
        }
        return resp.json();
    })
    .catch(err => {
        if (init && init.signal && init.signal.aborted) {
            return undefined;
        }
        throw err;
    });
}
