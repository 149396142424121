import {EIA_ADD_ROUTE, EIA_ADD_FACET_VALUES, EIA_LAST_ROUTE} from '../actionTypes';

const initialState = {
	routes: [],
	facets: [],
    last_route_id:   '',
    last_route_name: '',
};

// model reducer function (state, action)
const eiacache = (state = initialState, action) => {
    var newState = {};
	switch (action.type) {
		case EIA_ADD_ROUTE:
            newState = {...state};
            if (newState.routes.filter((r) => (r.path === action.path)).length > 0) {
                return newState;    
            }
            newState.routes = [...state.routes, {
                path:             action.path, 
                children:         action.children,
                data:             action.data,
                facets:           action.facets,
                defaultFrequency: action.defaultFrequency,
                frequency:        action.frequency,
            }];
            return newState;
        case EIA_ADD_FACET_VALUES:
            newState = {...state};
            if (newState.facets.filter((f) => 
            (f.route_id === action.route_id && f.facet_id === action.facet_id)).length > 0) {
                return newState;
            }
            newState.facets = [...state.facets, 
                {route_id: action.route_id, facet_id: action.facet_id, values: action.values}];
            return newState;
        case EIA_LAST_ROUTE:
            newState = {...state};
            newState.last_route_id   = action.route_id;
            newState.last_route_name = action.route_name;
            return newState;
        default:
            return state;
    }
}

export default eiacache;