export const ADD_ERROR    = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_VARIABLES  = "SET_VARIABLES";
export const SET_VARLABELS  = "SET_VARLABELS";
export const SET_VARFACTS   = "SET_VARFACTS";
export const SET_VARLOGS    = "SET_VARLOGS";
export const SET_VARLAGS    = "SET_VARLAGS";
export const SET_CSVFILE    = "SET_CSVFILE";
export const SET_TIMEVAR    = "SET_TIMEVAR";

export const ADD_VARIABLE    = "ADD_VARIABLE";
export const REMOVE_VARIABLE = "REMOVE_VARIABLE";

export const SET_DATASET    = "SET_DATASET";
export const SET_LOGLIK     = "SET_LOGLIK";
export const SET_PRIORS     = "SET_PRIORS";
export const ADD_PRIOR      = "ADD_PRIOR";
export const REMOVE_PRIOR   = "REMOVE_PRIOR";
export const SET_PARAMS     = "SET_PARAMS";
export const SET_TIMESERIES = "SET_TIMESERIES";

export const SET_MODEL_STATUS      = "SET_MODEL_STATUS";
export const MODEL_STATUS_NONE     = 0;
export const MODEL_STATUS_COMPILED = 1;
export const MODEL_STATUS_SAMPLED  = 2;
export const MODEL_STATUS_UPDATED  = 3;

export const ADD_TSCHART    = "ADD_TSCHART";
export const REMOVE_TSCHART = "REMOVE_TSCHART";

export const SET_MCMC_SAMPLE  = "SET_MCMC_SAMPLE";
export const SET_FCAST_SAMPLE = "SET_FCAST_SAMPLE";
export const SET_IRF_SAMPLE   = "SET_IRF_SAMPLE";
export const SET_OIRF_SAMPLE  = "SET_OIRF_SAMPLE";
export const SET_DM_SAMPLE    = "SET_DM_SAMPLE";

export const SET_ESTIMATE_SIZE = "SET_ESTIMATE_SIZE";

export const EIA_ADD_ROUTE        = "EIA_ADD_ROUTE";
export const EIA_ADD_FACET_VALUES = "EIA_ADD_FACET_VALUES";
export const EIA_LAST_ROUTE       = "EIA_LAST_ROUTE";

export const FRED_ADD_SOURCES  = "FRED_ADD_SOURCES";
export const FRED_ADD_RELEASES = "FRED_ADD_RELEASES";
export const FRED_ADD_CATEGORY = "FRED_ADD_CATEGORY";
export const FRED_ADD_SERIES   = "FRED_ADD_SERIES";
export const FRED_ADD_CATEGORY_SERIES = "FRED_ADD_CATEGORY_SERIES";
export const FRED_ADD_RELEASE_SERIES  = "FRED_ADD_RELEASE_SERIES";