import {authRequest} from '../../services/api';
import {SET_CURRENT_USER} from '../actionTypes';
import {addError, removeError} from './errors';

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    };
}

export function logout() {
    return dispatch => {
        // delete the Bearer header from all future requests
        localStorage.clear();
        dispatch(setCurrentUser({}));
        dispatch(removeError());
    }
}

export function authUser(type, userData) {
    // returns a function
    return dispatch => {
        // wrap our thunk in a promise so we can wait for the API call
        return new Promise((resolve, reject) => {
            return authRequest(type, userData)
            .then(({ token, ...user }) => {
                localStorage.setItem("jwtToken",  token);
                localStorage.setItem("lastLogin", user.lastLogin);
                dispatch(setCurrentUser(user));
                dispatch(removeError());
                resolve();
            })
            .catch(err => {
                dispatch(addError(err.message, 'alert-danger'));
                reject();
            });
        });
    };
}