import {FRED_ADD_SOURCES, FRED_ADD_RELEASES, FRED_ADD_CATEGORY, 
    FRED_ADD_SERIES, FRED_ADD_CATEGORY_SERIES, FRED_ADD_RELEASE_SERIES} from '../actionTypes';

const initialState = {
	sources:  [],
	releases: [],
    categories: [],
    category_series: [],
    release_series: [],
    series:     [],
};

// model reducer function (state, action)
const fredcache = (state = initialState, action) => {
    var newState = {};
	switch (action.type) {
        case FRED_ADD_SOURCES:
            newState = {...state};
            if (newState.sources.length < 1) {
                newState.sources = action.sources;
            }
            return newState;
        case FRED_ADD_RELEASES:
            newState = {...state};
            if (newState.releases.filter((r) => (r.source === action.source)).length > 0) {
                return newState;
            }
            newState.releases = [...state.releases, {source: action.source, values: action.values}];
            return newState;
		case FRED_ADD_CATEGORY:
            newState = {...state};
            if (newState.categories.filter((r) => (r.id === action.id)).length > 0) {
                return newState;    
            }
            newState.categories = [...state.categories, {
                id:               action.id, 
                name:             action.name, 
                parent_id:        action.parent_id, 
                children:         action.children,
            }];
            return newState;
        case FRED_ADD_SERIES:
            newState = {...state};
            if (newState.series.filter((f) => (f.id === action.id)).length > 0) {
                return newState;
            }
            newState.series = [...state.series, {
                id:   action.id, 
                info: action.info,
            }];
            return newState;
        case FRED_ADD_CATEGORY_SERIES:
            newState = {...state};
            if (newState.category_series.filter((f) => 
            (f.category === action.category && f.page === action.page)).length > 0) {
                return newState;
            }
            newState.category_series = [...state.category_series, {
                category: action.category, 
                limit:    action.limit, 
                page:     action.page, 
                maxpage:  action.maxpage, 
                values:   action.values
            }];
            return newState;
        case FRED_ADD_RELEASE_SERIES:
            newState = {...state};
            if (newState.release_series.filter((f) => 
            (f.release === action.release && f.page === action.page)).length > 0) {
                return newState;
            }
            newState.release_series = [...state.release_series, {
                release: action.release, 
                limit:   action.limit, 
                page:    action.page, 
                maxpage: action.maxpage, 
                values:  action.values
            }];
            return newState;
        default:
            return state;
    }
}

export default fredcache;