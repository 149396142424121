import React, {Component} from 'react';
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import {Container, Row, Col, Button, InputGroup, FormControl, Form} from 'react-bootstrap';
import {parsePositiveNumber} from './utils';

import {addError, removeError} from '../store/actions/errors';

class ModelForm extends Component {

    static MAX_LAGS = 8;

	constructor(props) {
		super(props);
		this.state = {
            name:      '', 
            classname: '', 
            cparams:   [],
            vars:      [],
            priors:    [],
            lags:       1,
            rank:       1,
            lambda:     [0.1,0.5,1,100],
            submitted: false
        };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

    componentDidMount() {
        this.props.addError(`Select a statistical model`, 'alert-success');
    }

	handleChange(event) {
        var {classname, cparams, vars, lags, rank, lambda} = this.state;
        var alert = null;

        var target = event.target.name.split('.');

        if (target.length >= 2 && target[0] === 'lambda') {
            let i = parseInt(target[1]);
            let val = parsePositiveNumber(event.target.value);
            if (i >= 0 && i < 4) {
                lambda[i] = val;
                this.setState({lambda});
            }
            return;
        }

        if (target.length >= 2 && target[0] === 'vars') {
            target = target.slice(1).join('.');
            let varnames = event.target.value;
            let vars = this.state.vars.map((g) => 
                (g.groupname === target ? {groupname: g.groupname, varnames} : g)
            );
            if (classname === "SUR" && target === "dependent") {
                var xvars = vars.slice(1);
                vars = vars.slice(0,1);
                var yvars = vars[0].varnames.split(' ');
                yvars.forEach((y, i) => {
                    if (y === '') {
                        return;
                    }
                    if (i < xvars.length) {
                        vars.push({groupname: `eq${i+1} regressors`, varnames: xvars[i].varnames});
                    }
                    else {
                        vars.push({groupname: `eq${i+1} regressors`, varnames: ''});
                    }
                });
            }
		    this.setState({vars});
            return;
        }

        if (event.target.name === "name") {
            let names = this.props.modelNames();
            if (names.includes(event.target.value)) {
                alert = `A model with name ${event.target.value} already exists.`;
                this.props.addError(alert, 'alert-danger');
                this.setState({name:event.target.value});
                return;
            }
        }

        this.props.removeError();

        if (event.target.name === "classname") {
            switch (event.target.value) {
                case  "mvn":
                case  "MVN":
                    cparams = [];
                    vars = [
                        {groupname: 'dependent',   varnames: 'y1 y2'}, 
                        {groupname: 'independent', varnames: 'x'}
                    ];
                    this.props.addError(`Multivariate normal regression: specify names of dependent and independent variables.`, 'alert-warning');
                    break;
                case  "sur":
                case  "SUR":
                    cparams = [];
                    vars = [
                        {groupname: 'dependent',       varnames: 'y1 y2'}, 
                        {groupname: 'eq1 regressors',  varnames: 'x'},
                        {groupname: 'eq2 regressors',  varnames: 'x'}
                    ];
                    this.props.addError(`Seemingly unrelated regressions: specify names of dependent variables and their regressors.`, 'alert-warning');
                    break;
                case  "var":
                case  "VAR":
                    cparams = [
                        {name: 'lags',   value: lags},
                        {name: 'lambda', value: lambda}
                    ];
                    vars = [
                        {groupname: 'endogenous', varnames: 'y1 y2'}, 
                        {groupname: 'exogenous',  varnames: ''}
                    ];
                    this.props.addError(`Vector autoregression: specify names of endogenous and exogenous variables, number of lags, etc.`, 'alert-warning');
                    break;
                case  "vec":
                case  "VEC":
                    cparams = [
                        {name: 'lags', value: lags},
                        {name: 'rank', value: rank}
                    ];
                    vars = [
                        {groupname: 'endogenous', varnames: 'y1 y2'}, 
                        {groupname: 'exogenous',  varnames: ''}
                    ];
                    this.props.addError(`Vector error-correction: specify names of endogenous and exogenous variables, number of lags and rank.`, 'alert-warning');
                    break;
                default:
            }
        }

        this.setState({[event.target.name]: event.target.value, cparams, vars});
	}

	handleSubmit(event) {
        var model = this.state;
        let {classname, name, cparams, vars, lags, rank, lambda} = model;

        var i, j, l, d, k, names, resp, rkd;

        var regparamind = [];
        var params      = [];
        var regparams   = [];
        var reglabels   = '';

        var sigmaparams = null;
        var sigmainit   = null;

        var dependent   = [];
        var independent = [];

        var responses  = [];
        var regressors = [];

        var endogvars = [];
        var exogvars  = [];

        var alert = null;

        if (!classname || classname === '') {
            alert = 'Class name cannot be empty.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        if (!name || name === '') {
            alert = 'Model name cannot be empty.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        names = this.props.modelNames();
        if (names.includes(name)) {
            alert = `A model with name ${name} already exists.`;
            this.props.addError(alert, 'alert-danger');
            return;
        }

        switch (classname) {
            case "mvn":
            case "MVN":
                if (vars.length !== 2) {
                    alert = 'Invalid variable list';
                    this.props.addError(alert, 'alert-danger');
                    return;
                }

                dependent   = [];
                independent = [];
                if (vars[0].varnames !== '') {
                    dependent = vars[0].varnames.split(' ');
                }
                if (vars[1].varnames !== '') {
                    independent  = vars[1].varnames.split(' ');
                }
                if (dependent.length < 1) {
                    alert = 'Please enter at least one dependent variable';
                    this.props.addError(alert, 'alert-danger');
                    return;
                }

                d = dependent.length;
                k = independent.length+1;
                rkd = k*d;
                
                regparams = [];
                params = [];
                dependent.forEach((p) => {
                    params.push(p);
                });
                independent.forEach((p) => regparams.push(p));    
                regparams.push('_intercept')

                reglabels = '';
                params = [];
                dependent.forEach((e) => {
                    reglabels += ` ${e}_coef`;
                    regparams.forEach((p) => params.push(
                        {name: `${e}:${p}`, dim: 1, initvalue: 0, value: 0, variance: 1}));
                });
                regparamind = params.map((p,i) => (i));

                sigmaparams = new Array(d*(d+1)/2);
                sigmainit   = new Array(d*(d+1)/2);
                l = 0;
                for (i = 0; i < d; i++) {
                    for (j = i; j < d; j++) {
                        sigmaparams[l] = 'Sigma_'+(j+1)+'_'+(i+1);
                        if (j === i) {
                            sigmainit[l] = 1;
                        }
                        else {
                            sigmainit[l] = 0;
                        }
                        l++;
                    }
                }
                params.push({name: 'Sigma', dim: [d,d], initvalue: sigmainit, 
                            value: sigmainit, variance: sigmainit});

                model.vars = [
                    {groupname: 'dependent',   varnames: dependent}, 
                    {groupname: 'independent',  varnames: independent}, 
                ];
                model.params = params;
                model.priors = [
                    {
                        paramind: regparamind,
                        paramlabel: reglabels,
                        dim: [d, lags*d+k],
                        distribution: 'mvnormal',
                        display: 'MVN(b0, V0)',
                        hyperparams: [
                            {name: 'b0',  domain: 0, initvalue: [0], dim: [1, rkd],   value: [0]},
                            {name: 'V0',  domain: 1, initvalue: [1], dim: [rkd, rkd], value: [1]}
                        ],
                    },
                    {
                        paramind: params.length-1,
                        paramlabel: 'Sigma',
                        distribution: 'invwishart',
                        display: 'InvWishart(nu0, S0)',
                        hyperparams: [
                            {name: 'nu0', domain: 1, initvalue: [d+2], dim: [1], value: [d+2]},
                            {name: 'S0',  domain: 1, initvalue: [1], dim: [d, d], value: [1]}
                        ],
                    }
                ];
                model.minupdatesize = d+1;
                model.irf      = false;
                model.forecast = true;
                model.description = "Multivariate normal regression";
            break;
            
            case "sur":
            case "SUR":
                if (vars.length <= 2) {
                    alert = 'Invalid variable list';
                    this.props.addError(alert, 'alert-danger');
                    return;
                }

                responses  = [];
                regressors = [];
                if (vars[0].varnames !== '') {
                    responses = vars[0].varnames.split(' ');
                }
                d = responses.length;

                if (d < 1) {
                    alert = 'Please enter at least one dependent variable';
                    this.props.addError(alert, 'alert-danger');
                    return;
                }
                if (d !== vars.length - 1) {
                    alert = 'Wrong equations';
                    this.props.addError(alert, 'alert-danger');
                    return;
                }

                k = 0;
                reglabels = '';
                params = [];
                model.vars = new Array(d+1);
                model.vars[0] = {
                    groupname: 'dependent', 
                    varnames: responses
                };
                for (i = 0; i < d; i++) {
                    resp = responses[i];
                    regressors = vars[i+1].varnames.split(' ');
                    model.vars[i+1] = {
                        groupname: `eq${i+1} regressors`, 
                        varnames: regressors
                    };
                    reglabels += ` ${resp}_coef`;
                    //regressors.forEach((p) => 
                    //    params.push({name: `${resp}:${p}`,   
                    //    dim: [1], initvalue: 0, value: 0, variance: 1})
                    //);
                    for (l = 0; l < regressors.length; l++) {
                        params.push({name: `${resp}:${regressors[l]}`,   
                            dim: [1], initvalue: 0, value: 0, variance: 1})
                    }
                    params.push({name: `${resp}:_intercept`, 
                        dim: [1], initvalue: 0, value: 0, variance: 1})
                    k += (regressors.length+1);
                }
                regparamind = params.map((p,i) => (i));

                sigmaparams = new Array(d*(d+1)/2);
                sigmainit   = new Array(d*(d+1)/2);
                l = 0;
                for (i = 0; i < d; i++) {
                    for (j = i; j < d; j++) {
                        sigmaparams[l] = 'Sigma_'+(j+1)+'_'+(i+1);
                        if (j === i) {
                            sigmainit[l] = 1;
                        }
                        else {
                            sigmainit[l] = 0;
                        }
                        l++;
                    }
                }
                params.push({name: 'Sigma', dim: [d,d], initvalue: sigmainit, 
                    value: sigmainit, variance: sigmainit});

                model.params = params;
				model.priors = [
                    {
                        paramind: regparamind,
                        paramlabel: reglabels,
                        dim: [d, lags*d+k],
                        distribution: 'mvnormal',
                        display: 'MVN(b0, V0)',
                        hyperparams: [
                            {name: 'b0', domain: 0, initvalue: [0], dim: [1, k], value: [0]},
                            {name: 'V0', domain: 1, initvalue: [1], dim: [k, k], value: [1]}
                        ],
                    },
                    {
                        paramind: params.length-1,
                        paramlabel:   'Sigma',
                        distribution: 'invwishart',
                        display: 'InvWishart(nu0, S0)',
                        hyperparams: [
                            {name: 'nu0', domain: 1, initvalue: [d+2], dim: [1],    value: [d+2]},
                            {name: 'S0',  domain: 1,  initvalue: [1],  dim: [d, d], value: [1]}
                        ],
                    }
                ];
                model.minupdatesize = d+1;
                model.irf      = false;
                model.forecast = true;
                model.description = "Seemingly unrelated regression";
                break;

                case "var":
                case "VAR":
                    if (cparams.length !== 2) {
                        alert = 'Invalid number of cparams';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
                    cparams[0].value = lags;
    
                    if (cparams[1].name === "lambda") {
                        cparams[1].value = lambda.map((l) => parseFloat(l));
                    }

                    if (vars.length !== 2) {
                        alert = 'Invalid variable list';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
    
                    if (vars[0].varnames !== '') {
                        endogvars = vars[0].varnames.split(' ');
                    }
                    if (vars[1].varnames !== '') {
                        exogvars  = vars[1].varnames.split(' ');
                    }
                    if (endogvars.length < 1) {
                        alert = 'Please enter at least one endogenous variable';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
    
                    d = endogvars.length;
                    k = exogvars.length+1;
                    rkd = lags*d+k;
                    
                    regparams = [];
                    params = [];
                    endogvars.forEach((p) => {
                        params.push(p);
                    });
                    for (l = 1; l <= lags; l++) {
                        for (i = 0; i < d; i++) {
                            regparams.push(`L${l}.${params[i]}`);
                        }
                    }
                    exogvars.forEach((p) => regparams.push(p));    
                    regparams.push('_intercept')
    
                    reglabels = '';
                    params = [];
                    endogvars.forEach((e) => {
                        reglabels += ` ${e}_coef`;
                        regparams.forEach((p) => params.push(
                            {name: `${e}:${p}`, dim: 1, initvalue: 0, value: 0, variance: 1}));
                    });
                    regparamind = params.map((p,i) => (i));
    
                    sigmaparams = new Array(d*(d+1)/2);
                    sigmainit   = new Array(d*(d+1)/2);
                    l = 0;
                    for (i = 0; i < d; i++) {
                        for (j = i; j < d; j++) {
                            sigmaparams[l] = 'Sigma_'+(j+1)+'_'+(i+1);
                            if (j === i) {
                                sigmainit[l] = 1;
                            }
                            else {
                                sigmainit[l] = 0;
                            }
                            l++;
                        }
                    }
                    params.push({name: 'Sigma', dim: [d,d], initvalue: sigmainit, 
                        value: sigmainit, variance: sigmainit});
    
                    model.vars = [
                        {groupname: 'endogenous', varnames: endogvars}, 
                        {groupname: 'exogenous',  varnames: exogvars}, 
                    ];
                    model.params = params;
                    model.priors = [
                        {
                            paramind: regparamind,
                            paramlabel: reglabels,
                            dim: [d, lags*d+k],
                            distribution: 'mvnormal',
                            display: 'MVN(b0, Sigma * Phi0)',
                            hyperparams: [
                                {name: 'b0',   domain: 0, initvalue: [0], dim: [1, d*rkd], value: [0]},
                                {name: 'Phi0', domain: 1, initvalue: [1], dim: [rkd, rkd], value: [1]}
                            ],
                        },
                        {
                            paramind: params.length-1,
                            paramlabel: 'Sigma',
                            distribution: 'invwishart',
                            display: 'InvWishart(nu0, S0)',
                            hyperparams: [
                                {name: 'nu0', domain: 1, initvalue: [d+2], dim: [1],    value: [d+2]},
                                {name: 'S0',  domain: 1, initvalue: [1],   dim: [d, d], value: [1]}
                            ],
                        }
                    ];
                    model.minupdatesize = rkd+1;
                    model.irf      = true;
                    model.forecast = true;
                    model.description = "Vector autoregression";
                    break;

                case "vec":
                case "VEC":
                    if (cparams.length !== 2) {
                        alert = 'Invalid number of cparams';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
                    cparams[0].value = lags;
    
                    if (cparams[1].name === "rank") {
                        cparams[1].value = rank;
                    }

                    if (vars.length !== 2) {
                        alert = 'Invalid variable list';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
    
                    if (vars[0].varnames !== '') {
                        endogvars = vars[0].varnames.split(' ');
                    }
                    if (vars[1].varnames !== '') {
                        exogvars  = vars[1].varnames.split(' ');
                    }
                    if (endogvars.length < 1) {
                        alert = 'Please enter at least one endogenous variable';
                        this.props.addError(alert, 'alert-danger');
                        return;
                    }
    
                    d = endogvars.length;
                    k = exogvars.length+1;
                    rkd = rank + (lags-1)*d + k;
                    
                    regparams = [];
                    params = [];
                    endogvars.forEach((p) => {
                        params.push(p);
                    });
                    for (l = 1; l < lags; l++) {
                        for (i = 0; i < d; i++) {
                            regparams.push(`${params[i]}.DL${l}`);
                        }
                    }
                    exogvars.forEach((p) => regparams.push(p));    
                    regparams.push('_intercept')

                    // params: alpha's, beta's, and regparams's
                    // alpha: d*rank
                    reglabels = '';
                    params     = [];
                    endogvars.forEach((e) => {
                        reglabels += ` ${e}_alpha`;
                        for (i = 0; i < rank; i++) {
                            params.push(
                                {name: `${e}:alpha_${i+1}`, dim: 1, initvalue: 0, value: 0, variance: 1});
                        }
                    });

                    // beta: d*rank
                    endogvars.forEach((e) => {
                        for (i = 0; i < rank; i++) {
                            params.push(
                                {name: `${e}:beta_${i+1}`, dim: 1, initvalue: 0, value: 0, variance: 1});
                        }
                    });

                    // regcoef: d*((lags-1)*d+q)
                    endogvars.forEach((e) => {
                        reglabels += ` ${e}_coef`;
                        regparams.forEach((p) => {
                            params.push(
                                {name: `${e}:${p}`, dim: 1, initvalue: 0, value: 0, variance: 1});
                            regparamind.push(l);
                        });
                    });

                    // prior for (alpha, regcoef)
                    // in prior, params are ordered by equation
                    regparamind = [];
                    endogvars.forEach((e, j) => {
                        l = j*rank;
                        for (i = 0; i < rank; i++) {
                            regparamind.push(l);
                            l++;
                        }
                        // skip alpha and beta
                        l = 2*d*rank + j*regparams.length;
                        regparams.forEach((p) => {
                            regparamind.push(l);
                            l++;
                        });
                    });

                    sigmaparams = new Array(d*(d+1)/2);
                    sigmainit   = new Array(d*(d+1)/2);
                    l = 0;
                    for (i = 0; i < d; i++) {
                        for (j = i; j < d; j++) {
                            sigmaparams[l] = 'Sigma_'+(j+1)+'_'+(i+1);
                            if (j === i) {
                                sigmainit[l] = 0.01;
                            }
                            else {
                                sigmainit[l] = 0;
                            }
                            l++;
                        }
                    }
                    params.push({name: 'Sigma', dim: [d,d], initvalue: sigmainit, 
                        value: sigmainit, variance: sigmainit});
    
                    model.vars = [
                        {groupname: 'endogenous', varnames: endogvars}, 
                        {groupname: 'exogenous',  varnames: exogvars}, 
                    ];
                    model.params = params;
                    model.priors = [
                        {
                            paramind: regparamind,
                            paramlabel: reglabels,
                            dim: [d, rkd],
                            distribution: 'mvnormal',
                            display: 'MVN(b0, Sigma * Phi0)',
                            hyperparams: [
                                {name: 'b0',   domain: 0, initvalue: [0],   dim: [1, d*rkd], value: [0]},
                                {name: 'Phi0', domain: 1, initvalue: [100], dim: [rkd, rkd], value: [100]}
                            ],
                        },
                        {
                            paramind: params.length-1,
                            paramlabel: 'Sigma',
                            distribution: 'invwishart',
                            display: 'InvWishart(nu0, S0)',
                            hyperparams: [
                                {name: 'nu0', domain: 1, initvalue: [d+2], dim: [1],    value: [d+2]},
                                {name: 'S0',  domain: 1, initvalue: [1],   dim: [d, d], value: [1]}
                            ],
                        }
                    ];
                    model.minupdatesize = rkd+1;
                    model.irf      = true;
                    model.forecast = true;
                    model.description = "Vector error correction";
                    break;

			default:
		}

        this.props.removeError();

		this.props.addModel(model);

        vars.forEach((g) => {
            g.varnames = ' ';
        });
		this.setState({name:'', classname: '', vars, submitted: true});
	}

	render() {
		const {name, classname, vars, submitted} = this.state;
        const {cparams, lags, rank} = this.state;
        const {errors} = this.props;

        var lagsControl   = '';
        var rankControl   = '';
        var lambdaControl = '';

        if (cparams.length >= 1 && cparams[0].name === "lags") {
            var lagsOptions = [...Array(ModelForm.MAX_LAGS+1).keys()].slice(1);
            lagsOptions = lagsOptions.map((s, i) => <option key={i} value={s}>{s}</option>);
            lagsControl = 
            <InputGroup className="m-4 w-25">
            <InputGroup.Text>Lags</InputGroup.Text>
            <select className="form-select form-select-sm" name="lags"  id="model-select-small" 
                value={lags} onChange={this.handleChange}>
                {lagsOptions}
            </select>
            </InputGroup>
        }

        if (cparams.length >= 2 && cparams[1].name === "lambda") {
            var lambdaNames = cparams[1].value.map((s, i) => `Lambda${i+1}`);
            var lambdas     = cparams[1].value.map((s, i) => s);
            lambdaControl = lambdaNames.map((name, i) => 
                <Row key={i} className="w-25">
                <InputGroup>
                <InputGroup.Text>{name}</InputGroup.Text>
                <FormControl type="text" id="model-name" 
                    name = {`lambda.${i}`} value={lambdas[i]} onChange={this.handleChange} 
                />
                </InputGroup>
                </Row>
            );
            lambdaControl = <Row className="m-2">{lambdaControl}</Row>
        }

        var maxrank = 0;
        if (vars.length > 0) {
            maxrank = vars[0].varnames.split(' ').length - 1;
        }
        if (cparams.length >= 2 && cparams[1].name === "rank" && maxrank > 0) {
            var rankOptions = [...Array(maxrank+1).keys()].slice(1);
            rankOptions = rankOptions.map((s, i) => <option key={i} value={s}>{s}</option>);
            rankControl = 
            <InputGroup className="m-4 w-25">
            <InputGroup.Text>Rank</InputGroup.Text>
            <select className="form-select form-select-sm" name="rank"  id="model-select-small" 
                value={rank} onChange={this.handleChange}>
                {rankOptions}
            </select>
            </InputGroup>
        }

        let vargroups = vars.map((g, i) => (
            <Row key={i} className="m-4 justify-content-center">
                <InputGroup className="m-0 w-75">
                    <InputGroup.Text>{g.groupname}</InputGroup.Text>
                    <FormControl type="text" id='vargroup'
                            name = {'vars.'+g.groupname} value={g.varnames} onChange={this.handleChange}
                    />
                </InputGroup>
            </Row>
        ));

        let classoptions = [<option key={0} value={''}></option>,
                            <option key={1} value={'MVN'}>MVN</option>,
                            <option key={2} value={'SUR'}>SUR</option>,
                            <option key={3} value={'VAR'}>VAR</option>,
                            <option key={4} value={'VEC'}>VEC</option>];
        //<div className="m-2 justify-content-md-center text-center">
        if (submitted) {
            return <Navigate to={"/"} />;
        }

        return(<Container fluid className='bmodel-form text-center'>

            <div className="text-center">
                {errors.message && (<div className={"m-4 alert "+errors.style}> {errors.message} </div>)}
            </div>
            
            <Row className="m-4">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Model</InputGroup.Text>
                        <Form.Select className="form-select form-select-sm" id="model-classname" 
                            name = "classname" value={classname} onChange={this.handleChange}>
                                {classoptions}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Name</InputGroup.Text>
                        <FormControl type="text" id="model-name" 
                            name = "name" value={name} onChange={this.handleChange} 
                        />
                    </InputGroup>
                </Col>
            </Row>
            
            {vargroups}

            <Row className="m-4 justify-content-center">
                {lagsControl}
                {lambdaControl}
                {rankControl}
            </Row>
            
            <Button className="m-4" onClick={this.handleSubmit}>
                Create
            </Button>
            
        </Container>);
	}
}

function mapStateToProps(state) {
	return {errors: state.errors};
}

export default connect(mapStateToProps, {addError, removeError})(ModelForm);
