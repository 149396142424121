import * as apiCalls from '../services/api';
import {timeunits_convert} from './utils';

export const units = [
    'lin',	//Levels (No transformation)
    'chg',	// Change
    'ch1',	// Change from Year Ago
    'pch',	// Percent Change
    'pc1',	// Percent Change from Year Ago
    'pca',	// Compounded Annual Rate of Change
    'cch',	// Continuously Compounded Rate of Change
    'cca',	// Continuously Compounded Annual Rate of Change
    'log'	// Natural Log
];

export async function loadFredSeries(series_id, cache, addError, controller) {
    var seriesInfo  = null;
    let found = cache.series.filter((r) => (r.id === series_id));
    if (found.length > 0) {
        found = found[0];
        seriesInfo = found.info;
    }
    else {
        var info = null;
        try {
            document.body.style.cursor = "wait";
            info = await apiCalls.fredSeries(series_id, {signal:controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }
        if (!info || info.length < 1) {
            addError('Series ' + series_id + ' not found', 'alert-danger');
            return null;
        }
        seriesInfo = info[0];
        await cache.addSeries(series_id, seriesInfo);
    }
    if (!seriesInfo) {
        addError('Series ' + series_id + ' not found', 'alert-danger');
        return null;
    }
    return seriesInfo;
}

export async function importFredSeries(
    series_id, frequency, observation_start, observation_end, import_as, addError, controller) {
    
        var timeformat = '';
    switch (frequency) {
        case 'd':
            timeformat = 'day'
            break;
        case 'w':
            timeformat = 'week'
            break;
        case 'bw':
            timeformat = 'biweek'
            break;
        case 'm':
            timeformat = 'month'
            break;
        case 'q':
            timeformat = 'quarter'
            break;
        case 'sa':
            timeformat = 'halfyear'
            break;
        case 'a':
            timeformat = 'year'
            break;
        default:
    }

    //observation_start: YYYY-MM-DD
    //observation_end:   YYYY-MM-DD
    var match = null;
    let qregexp = /^([1-9][0-9]{3})-(0[0-9]|10|11|12)-(0[1-9]|1[0-9]|2[0-9]|30|31)$/;
    match = qregexp.exec(observation_start);
    if (!match) {
        let alert = observation_start + ' is invalid. Date format should be YYYY-MM-DD.';
        this.props.addError(alert, 'alert-danger');
        return;
    }
    observation_start = match[0];
    match = qregexp.exec(observation_end);
    if (!match) {
        let alert = observation_end + ' is invalid. Date format should be YYYY-MM-DD.';
        this.props.addError(alert, 'alert-danger');
        return;
    }
    observation_end = match[0];

    var series = null;
    try {
        document.body.style.cursor = "wait";
        series = await apiCalls.fredObservations(
            series_id, frequency, observation_start, observation_end, 
            {signal:controller.signal});
        document.body.style.cursor = "";
    }
    catch {
        document.body.style.cursor = "";
    }
    if (!series || !series.data || series.count <= 0) {
        let alert = "Data not available for series " + 
            series_id + ' and frequency ' + timeformat;
        addError(alert, 'alert-warning');
        return null;
    }

    let observations = series.data.map((s) => Number(s.value));

    const startutc = Date.parse(series.data[0].time);
    const endutc   = Date.parse(series.data[observations.length-1].time);

    let res = timeunits_convert(timeformat, startutc, endutc);
    const {start, timestep} = res;

    var timeutc    = series.data.map((s) => (Date.parse(s.time)));
    var timestamps = series.data.map((s) => (s.time));
    var timeindex  = series.data.map((s, id) => (start+id*timestep));

    return({import_as, timeformat, 
        timeindex, timeutc, timestamps, observations});
}
