import {SET_DATASET, SET_LOGLIK, 
	SET_MODEL_STATUS, MODEL_STATUS_NONE, SET_PRIORS, ADD_PRIOR, REMOVE_PRIOR, SET_PARAMS, 
    SET_TIMESERIES, SET_MCMC_SAMPLE, SET_FCAST_SAMPLE, SET_ESTIMATE_SIZE, 
    SET_IRF_SAMPLE, SET_OIRF_SAMPLE, SET_DM_SAMPLE} 
    from '../actionTypes';

const initialState = {
	dataset: null, 
	loglik:  null,
    timeseries:  {units: '', utcstep: 0},
	varlabels: [],
	varfacts:  [],
	varlogs:   [],
	varlags:   [],
    isdefaultprior: true,
    priorvarscale: 100,
	status:    MODEL_STATUS_NONE,
    postmodel:   null,
    mcmcsample:  null,
    fcastsample: null,
    irfsample:   null,
    oirfsample:  null,
    dmsample:    null,
};

// model reducer function (state, action)
const model = (state = initialState, action) => {
	let newState = {};
    let priors = [];
	switch (action.type) {
		case SET_DATASET:
			return {...state, dataset: action.dataset};
		case SET_LOGLIK:
			return {...state, loglik: action.loglik};
        case SET_ESTIMATE_SIZE:
            return {...state, loglik: {...state.loglik, estsize: action.estsize}};
		case SET_MODEL_STATUS:
			return {...state, status: action.status};
        case SET_PRIORS:
            priors = state.loglik.priors;
            newState = {...state};
            newState.loglik.priors  = action.priors;
            newState.isdefaultprior = action.isdefault;
            newState.priorvarscale  = action.varscale;
            return newState;
        case ADD_PRIOR:
            priors = state.loglik.priors;
            newState = {...state};
            newState.loglik.priors = [...priors, action.prior];
            return newState;
		case REMOVE_PRIOR:
            priors = state.loglik.priors;
			//newState = Object.assign({}, state);
			newState = {...state};
            newState.loglik.priors = priors.filter((prior,i) => (i !== action.id));
			return newState;
		case SET_PARAMS:
            newState = {...state};
            newState.loglik.params = action.params;
            return newState;
        case SET_TIMESERIES:
            return {...state, timeseries: action.timeseries};
        case SET_MCMC_SAMPLE:
            return {...state, mcmcsample: action.mcmcsample, postmodel: action.postmodel};
        case SET_FCAST_SAMPLE:
            return {...state, fcastsample: action.fcastsample}; 
        case SET_IRF_SAMPLE:
            return {...state, irfsample: action.irfsample}; 
        case SET_OIRF_SAMPLE:
            return {...state, oirfsample: action.oirfsample}; 
        case SET_DM_SAMPLE:
            return {...state, dmsample: action.dmsample}; 
		default:
			return state;
	}
}

export default model;