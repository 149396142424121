import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Card, Accordion, Form, Col, Row, InputGroup, FormControl} from 'react-bootstrap';
import {setPriors} from '../store/actions/model';
import {parseNumber, parsePositiveNumber} from './utils';

class ModelPrior extends Component {
	constructor(props) {
		super(props);
		// varlabels, varfacts and varlogs are of this.props.variables' length
		this.state = {
			alert: '',
			alertcolor: 'black',
            priors: [],
			isdefault: true,
            scale:  100,
            status: 0,
		};
		this.handleChange  = this.handleChange.bind(this);
        this.handleDefault = this.handleDefault.bind(this);
	}

    async componentDidMount() {
        let {isdefaultprior, priorvarscale} = this.props.model;
        let {priors} = this.props.model.loglik;
        if (isdefaultprior) {
            let newpriors = this.setPriorFromParams(priorvarscale);
            this.setState({priors: newpriors, isdefault: true, scale: priorvarscale});
        }
        else {
            this.setState({priors, isdefault: false, scale: priorvarscale});
        }
	}

    async componentWillUnmount() {
        var d;
        let {params} = this.props.model.loglik;
        var {priors, isdefault, scale, status} = this.state;
        if (!status) {
            // prevent setting priors if no change ocurred
            return;
        }
        priors.forEach((p) => {
            d = 0;
            if ( p.paramind.length === 1) {
                if (params[p.paramind[0]].dim.length === 2) {
                    d = params[p.paramind[0]].dim[0];
                }
            }
            p.hyperparams.forEach((h) => {
                if (h.value.length === d*(d+1)/2 && p.paramind.length === 1) {
                    // matrix
                    h.value = h.value.map((v) => parseFloat(v));
                }
                else if (!h.value.length || p.paramind.length === 1) {
                    // scalar
                    h.value = parseFloat(h.value);
                }
                else {
                    // vector
                    h.value = h.value.map((v) => parseFloat(v));
                }
            });
        });
        this.props.setPriors(priors, isdefault, scale);
	}

    setPriorFromParams(scale) {
        let {estsize, params, priors} = this.props.model.loglik;
        //console.log("params", params);
        //console.log("priors", priors);
        //console.log("scale",  scale);

        var b, V, d, h, i, j, l, k, par, rho, Sigma, newpriors;

        newpriors = priors.map((p) => {

            rho = scale / Math.sqrt(1+estsize);

            if (p.distribution === "mvnormal" && p.paramind.length >= 1 && p.hyperparams.length === 2) {
                h = p.hyperparams[1];
                d = p.paramind.length;
                b = new Array(d);
                for (i = 0; i < d; i++) {
                    if (params[p.paramind[i]].dim[0] !== 1) {
                        continue;
                    }
                    if (params[p.paramind[i]].value.length) {
                        b[i] = params[p.paramind[i]].value[0];
                    }
                    else {
                        b[i] = params[p.paramind[i]].value;
                    }
                    b[i] = Number(b[i].toPrecision(7));
                }
                p.hyperparams[0].value = b;

                if (h.dim.length === 2 && h.dim[0] === d && h.dim[1] === d) {
                    // full-covariance matrix
                    V = new Array(d);
                    for (i = 0; i < d; i++) {
                        if (params[p.paramind[i]].variance.length) {
                            V[i] = rho * params[p.paramind[i]].variance[0];
                        }
                        else {
                            V[i] = rho * params[p.paramind[i]].variance;
                        }
                        V[i] = Number(V[i].toPrecision(7));
                    }
                }
                else if (h.dim.length === 2 && h.dim[0] === h.dim[1]) {
                    // product matrix
                    k = Math.floor(d/h.dim[0]);
                    // obtain Sigma from variance/covariance matrix of the normal model
                    Sigma = new Array(k);
                    for (i = 0; i < k; i++) {
                        Sigma[i] = 1;
                    }
                    j = params.length - 1;
                    if (params[j].dim.length === 2 && params[j].dim[0] === k && params[j].dim[1] === k && 
                        params[j].value.length === k*(k+1)/2) {
                        l = 0;
                        for (i = 0; i < k; i++) {
                            if (params[j].value[l] < 0) {
                                Sigma[i] = -1/params[j].value[l]
                            }
                            else if (params[j].value[l] > 0) {
                                Sigma[i] = 1/params[j].value[l];
                            }
                            l += (k-i);
                        }
                    }
                    V = new Array(h.dim[0]);
                    for (i = 0; i < h.dim[0]; i++) {
                        l = 0;
                        for (j = 0; j < k; j++) {
                            l += params[p.paramind[i + j*h.dim[0]]].variance[0] * Sigma[j];
                        }
                        l = (l*rho)/k;
                        V[i] = Number(l.toPrecision(7));
                    }
                }
                p.hyperparams[1].value = V;
            }

            if (p.distribution === "invwishart" && p.paramind.length === 1) {
                par = params[p.paramind[0]];
                d = par.dim[0];
                if (par.value.length !== d*(d+1)/2) {
                    return (p);
                }
                rho = Math.floor(Math.sqrt(estsize) / scale);
                if (rho < d+2) {
                    rho = d+2;
                }
                // nu
                p.hyperparams[0].value = rho;
                rho = rho - d - 1;

                V = new Array(par.value.length);
                for (i = 0; i < par.value.length; i++) {
                    V[i] = rho*par.value[i];
                    V[i] = Number(V[i].toPrecision(7));
                }
                // S0
                p.hyperparams[1].value = V;
            }

            return (p);
        });
        return (newpriors);
	}

    handleChange(event) {
        var {priors, isdefault} = this.state;
        var alert = '';
        var alertcolor = 'black';
        var value = event.target.value;

        if (event.target.name === "scale") {
            value = parsePositiveNumber(value);
            if (isdefault) {
                let newpriors = this.setPriorFromParams(value);
                this.setState({priors: newpriors, scale: value, alert, alertcolor, status: 1});
            }
            else {
                this.setState({scale: value, alert, alertcolor, status: 1});
            }
            return;    
        }

        value = parseNumber(value);
        if (!priors || priors.length < 1) {
            return;
        }
        var pind = event.target.name.split(":").map((i) => parseInt(i));
        if (pind.length < 2) {
            return;
        }
        if (pind[1] >= priors[pind[0]].hyperparams.length) {
            return;
        }
        if (pind.length === 2) {
            priors[pind[0]].hyperparams[pind[1]].value = value;
        }
        else if (pind.length === 3) {
            if (pind[2] >= priors[pind[0]].hyperparams[pind[1]].value.length) {
                return;
            }
            priors[pind[0]].hyperparams[pind[1]].value[pind[2]] = value;
        }
        this.setState({priors: priors, isdefault: false, alert, alertcolor, status: 1});
	}

    async handleDefault(event) {
        var {isdefault, scale} = this.state;
        if (!isdefault) {
            let newpriors = this.setPriorFromParams(scale);
            this.setState({priors: newpriors, isdefault: true});
        }
        else {
            let {priors} = this.props.model.loglik;
            this.setState({priors, isdefault: false});
        }
	}

	render() {
		const {params} = this.props.model.loglik;
        const {alert, alertcolor, scale, priors, isdefault} = this.state;
        var i, j, l, d;
		var value, paramnames, hparaminputs, matname, priorinputs;

        priorinputs = ''
        if (priors && priors.length > 0) {
            priorinputs = priors.map((p, priorid) => {
                let display = p.display;
                if (!display || display === '') {
                    display = p.distribution;
                }
                d = 0;
                if (p.paramind.length === 1) {
                    if (params[p.paramind[0]].dim.length === 2) {
                        d = params[p.paramind[0]].dim[0];
                    }
                }
                var key = 0;
                let hyperinputs = p.hyperparams.map((h, hid) => {
                    value = h.value;
                    key++;
                    //console.log("value", value, value.length, p.paramind, d, d*(d+1)/2);
                    if (value.length === d*(d+1)/2 && p.paramind.length === 1) {
                        // square matrix
                        matname = params[p.paramind[0]].name;
                        paramnames   = [];
                        hparaminputs = [];
                        l = 0;
                        for (i = 0; i < d; i++) {
                            for (j = i; j < d; j++) {
                                key++;
                                var pname = `${matname}_${i}_${j}`;
                                paramnames.push(pname);
                                hparaminputs.push(
                                    <InputGroup key={key}>
                                    <InputGroup.Text>
                                        {pname}
                                    </InputGroup.Text>
                                    <FormControl type="text" name={`${priorid}:${hid}:${l}`}
                                        id={`${priorid}:${hid}:${l}=`} value={value[l]} onChange={this.handleChange} 
                                    />
                                    </InputGroup>
                                );
                                l++;
                            }
                        }
                        key++;
                        return (
                            <Col className="m-2" key={key}>
                                <Accordion.Item className="m-1" eventKey={priorid}>
                                <Accordion.Header>
                                    {h.name}
                                </Accordion.Header>
                                <Accordion.Body className="p-2">
                                    {hparaminputs}
                                </Accordion.Body>
                                </Accordion.Item>
                            </Col>)
                    }
                    else if (!value.length || p.paramind.length === 1) {
                        // scalar
                        return (
                            <Col className="m-2" key={key}>
                            <InputGroup>
                            <InputGroup.Text>
                                {h.name}
                            </InputGroup.Text>
                            <FormControl type="text" name={`${priorid}:${hid}`}
                                id={`${priorid}:${hid}`} value={value} onChange={this.handleChange} 
                            />
                            </InputGroup>
                            </Col>)
                    }
                    else {
                        // vector
                        //paramnames = value.map((v,k) => params[p.paramind[k]].name);
                        paramnames = p.paramind.map((k) => params[k].name);
                        d = paramnames.length;
                        //console.log("paramnames", paramnames, value, h.dim, d);
                        if (h.dim.length === 2 && 
                        ((h.dim[0] === 1 && h.dim[1] === d)||(h.dim[0] === d && h.dim[1] === d))) {
                            // vector
                            hparaminputs = value.map((v,k) => {
                                key++;
                                return(
                                    <InputGroup key={key}>
                                    <InputGroup.Text>
                                        {paramnames[k]}
                                    </InputGroup.Text>
                                    <FormControl type="text" name={`${priorid}:${hid}:${k}`}
                                        id={`${priorid}:${hid}:${k}`} value={v} onChange={this.handleChange} 
                                    />
                                    </InputGroup>
                                    )
                                }
                            );
                        }
                        else if (h.dim.length === 2 && h.dim[0] === h.dim[1]) {
                            // product matrix
                            l = Math.floor(d/h.dim[0]);
                            hparaminputs = value.map((v,k) => {
                                key++;
                                return(
                                    <InputGroup key={key}>
                                    <InputGroup.Text>
                                        {paramnames[k].split(":")[1]}
                                    </InputGroup.Text>
                                    <FormControl type="text" name={`${priorid}:${hid}:${k}`}
                                        id={`${priorid}:${hid}:${k}`} value={v} onChange={this.handleChange} 
                                    />
                                    </InputGroup>
                                    )
                                }
                            );
                        }
                        key++;
                        return (
                            <Col className="m-2" key={key}>
                                <Accordion.Item className="m-1" eventKey={priorid}>
                                <Accordion.Header>
                                    {h.name}
                                </Accordion.Header>
                                <Accordion.Body className="p-2">
                                    {hparaminputs}
                                </Accordion.Body>
                                </Accordion.Item>
                            </Col>)
                    }
                });
                return (
                    <Accordion.Item className="m-1" eventKey={priorid} key={priorid}>
                    <Accordion.Header>
                        {p.paramlabel} ~ {display}
                    </Accordion.Header>
                    <Accordion.Body className="p-2">
                        <Accordion defaultActiveKey="0"> 
                            <Row className="m-2">
                                {hyperinputs}
                            </Row>
                        </Accordion>
                    </Accordion.Body>
                    </Accordion.Item>
                );
            });
        }
		return (
			<Container className="text-center">
				
                {alert !== '' ? 
                    <Card className="m-4 notification" style={{'color':alertcolor}}>
                        {alert}
                    </Card> : ''
                }

                <Row>

                <Form.Check 
                    className="form-control"
                    type="switch"
                    label="Default priors"
                    checked={isdefault}
                    onChange={this.handleDefault}
                    style={{'width': '40%',  'margin': 'auto'}}
                />

                <InputGroup style={{'width': '40%',  'margin': '8px'}}>
                <InputGroup.Text  className="form-control-label">
                    Variance scale
                </InputGroup.Text>
                {isdefault ? 
                    <FormControl type="text" name="scale" id="scale" 
                        className="form-control form-bordercontrol"
                        value={scale} onChange={this.handleChange} 
                    /> :
                    <FormControl type="text" name="scale" id="scale" disabled
                        className="form-control form-bordercontrol"
                        value={scale} onChange={this.handleChange} 
                    />
                }
                </InputGroup>

                </Row>

                <Accordion defaultActiveKey="0">
                    {priorinputs}
                </Accordion>

			</Container>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
    return {model: state.model, user_id, id};
}

export default connect(mapStateToProps, {setPriors})(ModelPrior);
