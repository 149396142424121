import {combineReducers} from 'redux';

import errors from './errors';
import currentUser from './currentUser';
import datatable from './datatable';
import datagraphs from './datagraphs';
import model from './model';
import fredcache from './fredcache';
import eiacache from './eiacache';

// global redux state has 2 components: variables and model
const rootReducer = combineReducers({
    errors,
    currentUser, 
    datatable,
    datagraphs,
    model,
    fredcache,
    eiacache,
});

export default rootReducer;