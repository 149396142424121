import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col, Button, Card, FormControl} from 'react-bootstrap';
import {addError, removeError} from '../store/actions/errors';
import {addSeries} from '../store/actions/fredcache';
import {loadFredSeries, importFredSeries} from './FredSeries.js';

class FredSeriesForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
            apiinfo: 'FRED economic data API',
            apiurl:  'https://fred.stlouisfed.org/docs/api/fred/',
            series_id:    '',
			series_title: '',
			observation_start: '', 
			observation_end: '',
			frequency: 'month', 
			last_updated: '',
			varnames: [], 
			vardata: [],
            series_changed: false,
            series_imported: false,
            import_as: '',
        };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.timeformat = [
            {name: 'day',        id: 'd'}, 
            {name: 'week',       id: 'w'}, 
            {name: 'biweek',     id: 'bw'}, 
            {name: 'month',      id: 'm'}, 
            {name: 'quarter',    id: 'q'}, 
            {name: 'semiannual', id: 'sa'}, 
            {name: 'annual',     id: 'a'}
        ];

		this.category_children = [];
		this.sources  = [];
		this.releases = [];
		this.series   = [];
        this.series_page_fn = null;

		this.controller = null;
	}

	componentDidMount() {
        this.controller = new window.AbortController();
	}

	componentWillUnmount() {
        this.controller.abort();
  	}

	async loadSeries() {
        let {series_id} = this.state;
        let seriesInfo = await loadFredSeries(
            series_id,
            {series:this.props.fredcache.series, addSeries: this.props.addSeries}, 
            this.props.addError, 
            this.controller
        );
        if (!seriesInfo) {
            return;
        }
		this.setState({
			series_id: series_id, 
			series_title: seriesInfo.title,
			observation_start: seriesInfo.observation_start,
			observation_end: seriesInfo.observation_end,
			frequency: seriesInfo.frequency.toLowerCase(),
            import_as: series_id, 
            series_changed: false,
            series_imported: false
		});
	}

	async handleChange(event) {
        this.props.removeError();
        //console.log(event.target.name, event.target.value);
        // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		//await this.setState({[event.target.name]: event.target.value});
		switch(event.target.name) {
            case 'series_id':
                await this.setState({series_id: event.target.value, series_changed: true});
				break;
			case 'look-up':
				this.loadSeries();
				break;
			default:
                await this.setState({[event.target.name]: event.target.value, series_imported: false});
		}
	}

	async handleSubmit(event) {

        let {series_id, frequency, observation_start, observation_end, import_as} = this.state;

        let series = await importFredSeries(
            series_id, 
            frequency, 
            observation_start, 
            observation_end, 
            import_as,
            this.props.addError,
            this.controller
        );
        if (!series) {
            return;
        }
        this.props.importSeries(series.import_as, series.timeformat, 
			series.timeindex, series.timeutc, series.timestamps, series.observations);
            this.setState({series_imported: true});
	}

	render() {
		const {series_id, series_title, observation_start, observation_end, frequency,
            apiinfo, apiurl, import_as, series_changed, series_imported} = this.state;

        let frequencyOptions = [];
        if (this.timeformat) {
		    frequencyOptions = this.timeformat.map((tf, id) =>
                <option key={id} value={tf.id}>{tf.name}</option>);
        }
    
		return (
			<Container className="mt-2" fluid>

                {apiinfo ? 
                    <Card className="m-1 notification" style={{'backgroundColor': 'whitesmoke'}}>
                        {apiinfo} 
                        <a href={apiurl} target="_blank" rel="noreferrer noopener">{apiurl}</a>
                    </Card>: ''
                }

				<Row className="g-1">
                    
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Series</Card.Header>
                            <Card.Body className="card-body">
                                    <FormControl type="text" id="series_id" name="series_id" value={series_id} 
                                        onChange={this.handleChange}>
                                    </FormControl>
                                    {series_changed ? 
                                    <Button className="m-2" variant="primary" id="look-up" name="look-up" 
                                        onClick={this.handleChange}>
                                        LookUp
                                    </Button> :
                                    <Button className="m-2" variant="primary" id="look-up" name="look-up"  
                                        onClick={this.handleChange} disabled>
                                        LookUp
                                    </Button>
                                    }
                            </Card.Body>
                        </Card>
                    </Col>
				</Row>

                <Row className="g-1">
                    <label className="series-title" area-label="Series title">
                        {series_title}
                    </label>
                </Row>

		        <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Start
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control" 
                                    name = "observation_start" 
                                    value={observation_start} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
                    
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                End
                            </Card.Header>
                            <Card.Body className="card-body">
		        		        <input type="text" className="form-control"
                                    name = "observation_end" 
		        			        value={observation_end} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
					</Col>

					<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Frequency
                            </Card.Header>
                            <Card.Body className="card-body">
                                <select className="form-control" name="frequency" 
                                    onChange={this.handleChange} value={frequency}>
                                    {frequencyOptions}
                                </select>
                            </Card.Body>
                        </Card>
		        	
                        {series_id !== '' ? 
                            <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Import as
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control"
                                    name = "import_as" 
		        			        value={import_as} onChange={this.handleChange} />
                            </Card.Body>
                            </Card>
                            : '' 
                        }
                    </Col>
                </Row>
                {import_as !== '' & !series_imported ? 
                    <Button className="mt-2" variant="primary" id="categories-up" 
                        onClick={this.handleSubmit}>
                        Submit
                    </Button>
                    : '' 
                }
			</Container>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {fredcache: state.fredcache};
}

export default connect(mapStateToProps, {addSeries, addError, removeError})(FredSeriesForm);