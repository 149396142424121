import {Container,Navbar, Nav} from 'react-bootstrap';

function Footer() {
    return (
        <Navbar className="footer">
            <Container>
                <Nav variant="pills" className="flex-row nav navbar-nav">
                </Nav>
                <Nav variant="pills" className="flex-row nav navbar-nav navbar-right">
                    <Nav.Item className="footer-item">
                        <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/about">About</Nav.Link>
                    </Nav.Item>
                </Nav>
        </Container>
    </Navbar>
    );
}

export default Footer;