import React, {Component, createRef} from 'react';
import { connect } from "react-redux";
import {Container} from 'react-bootstrap';
import TsChart from './TsChart';

class DataGraphs extends Component {

    constructor(props) {
		super(props);
        this.state = {
            alert: '',
			alertstyle: '',
            charts_imported: false,
        };
		this.tsplotsRef = createRef();
	}

    componentDidMount() {
        var alert      = '';
		var alertstyle = '';
        let {tscharts} = this.props;
        if (tscharts.length < 1) {
            alert      = 'No variables available - use Data tab to import some.';
            alertstyle = 'alert-warning';
        }
        else {
            TsChart.deleteCharts(this.tsplotsRef.current);
            tscharts.forEach((ts) => ts.setupCanvas(this.tsplotsRef.current));
        }
        this.setState({alert, alertstyle});
        document.body.style.cursor = "";
    }
    
	render() {
        var {alert, alertstyle} = this.state;
		return (
            <Container className="text-center">
                <div className="text-center">
                    {alert !== '' && (<div className={"m-4 alert "+alertstyle}> {alert} </div>)}
                </div>
                <div className="tsplot-container card text-center m-0" ref={this.tsplotsRef}>
                </div>
            </Container>
		);
	}
}

function mapStateToProps(state) {
    return {
        tscharts: state.datagraphs.tscharts,
	};
}

export default connect(mapStateToProps)(DataGraphs);