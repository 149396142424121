import * as apiCalls from '../../services/api';
import {SET_DATASET, SET_LOGLIK, SET_MODEL_STATUS, 
    SET_PRIORS, ADD_PRIOR, REMOVE_PRIOR, 
    SET_PARAMS, SET_TIMESERIES, SET_MCMC_SAMPLE, SET_FCAST_SAMPLE, SET_ESTIMATE_SIZE, 
    SET_IRF_SAMPLE, SET_OIRF_SAMPLE, SET_DM_SAMPLE} 
    from '../actionTypes';
import {addError} from './errors';
// action creators

export function setDataset(dataset) {
	return {
		type: SET_DATASET,
		dataset
	}
}

export function setLoglik(loglik) {
	return {
		type: SET_LOGLIK,
		loglik
	}
}

export function loadLoglik(modelid, user_id) {
	return dispatch => {
        return apiCalls.showModel(modelid, user_id, localStorage.getItem('jwtToken'))
		.then(res => dispatch(setLoglik(res)))
		.catch(err => dispatch(addError(err.message)))
	}
}

export function updateLoglik(model, user_id) {
	return dispatch => {
		return apiCalls.updateModel(model, user_id, localStorage.getItem('jwtToken'))
		.then(res => dispatch(setLoglik(res)))
		.catch(err => dispatch(addError(err.message)))
	}
}

export function setModelStatus(status) {
	return {
		type: SET_MODEL_STATUS,
		status
	}
}

export function setPriors(priors, isdefault, varscale) {
	return {
		type: SET_PRIORS,
		priors,
        isdefault,
        varscale
	}
}

export function addPrior(prior) {
	return {
		type: ADD_PRIOR,
		prior
	}
}

export function removePrior(id) {
	return {
		type: REMOVE_PRIOR,
		id
	}
}

export function setParams(params) {
	return {
		type: SET_PARAMS,
		params
	}
}

export function setTimeseries(timeseries) {
	return {
		type: SET_TIMESERIES,
		timeseries
	}
}

export function updateModel(model, user_id) {
    return dispatch => {
		return apiCalls.updateModel(model, user_id, localStorage.getItem('jwtToken'))
		.then(res => {
			return dispatch(setParams(res.params));
		});
	}
}

export function setMcmcSample(mcmcsample, postmodel) {
	return {
		type: SET_MCMC_SAMPLE,
		mcmcsample,
        postmodel
	}
}

export function setFcastSample(fcastsample) {
	return {
		type: SET_FCAST_SAMPLE,
		fcastsample
	}
}

export function setIrfSample(irfsample) {
	return {
		type: SET_IRF_SAMPLE,
		irfsample
	}
}

export function setOirfSample(oirfsample) {
	return {
		type: SET_OIRF_SAMPLE,
		oirfsample
	}
}

export function setDmSample(dmsample) {
	return {
		type: SET_DM_SAMPLE,
		dmsample
	}
}

export function setEstimateSize(estsize) {
	return {
		type: SET_ESTIMATE_SIZE,
		estsize
	}
}
