import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../store';

import jwtDecode from 'jwt-decode';

import AppNavbar from './AppNavbar';
import Main from './Main';
import Footer from '../components/Footer';

import {setCurrentUser} from '../store/actions/auth';

// global redux state
const store = configureStore();

class App extends Component {

    //async componentDidMount() {
    constructor(props) {
        super(props);
        // rehydration: repopulate currentUser in Redux storage from a JWT token, if there is one
        let token = localStorage.getItem("jwtToken");
        if (token) {
            try {
                store.dispatch(setCurrentUser(jwtDecode(token)));
            }
            catch {
                store.dispatch(setCurrentUser({}));
            }
        }
    }

    render() {
        return(
        <Provider store={store}>
            <AppNavbar />
            <div className="main-wrapper">
                <div className="main-container">
                    <Main />
                </div>
            </div>
            <Footer />
        </Provider>);
    }
}

export default App;
