import {FRED_ADD_SOURCES, FRED_ADD_RELEASES, FRED_ADD_CATEGORY, 
    FRED_ADD_SERIES, FRED_ADD_CATEGORY_SERIES, FRED_ADD_RELEASE_SERIES} from '../actionTypes';

// action creators

export function addSources(sources) {
	return {
		type: FRED_ADD_SOURCES,
        sources
    }
}

export function addReleases(source, values) {
	return {
		type: FRED_ADD_RELEASES,
        source, 
        values
    }
}

export function addCategory(id, name, parent_id, children) {
	return {
		type: FRED_ADD_CATEGORY,
		id, 
        name, 
        parent_id, 
        children,
	}
}

export function addSeries(id, info) {
	return {
		type: FRED_ADD_SERIES,
        id, 
        info
	}
}

export function addCategorySeries(category, limit, page, maxpage, values) {
	return {
		type: FRED_ADD_CATEGORY_SERIES,
        category,
        limit, 
        page,
        maxpage,
        values
	}
}

export function addReleaseSeries(release, limit, page, maxpage, values) {
	return {
		type: FRED_ADD_RELEASE_SERIES,
        release,
        limit, 
        page,
        maxpage,
        values
	}
}