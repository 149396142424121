import React, {Component} from 'react';
import {Container, Row, Col, Button, Card} from 'react-bootstrap';
import * as apiCalls from '../services/api';
import {timeunits_convert} from './utils';

class PolygonForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
            apiinfo: 'polygon.io stock API',
            apiurl:  'https://polygon.io/stocks',
			tickers:[], 
			ticker: 'AAPL',
			multiplier: '1',
			timespan: 'week',
			from: '2020-10-29',
			to:   '2022-10-29',
            alert: '',
			alertstyle: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.timeformat = ['minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'];

		this.controller = null;
	}

	componentDidMount() {
        this.controller = new window.AbortController();
		//this.loadTickers();
	}

	componentWillUnmount() {
		this.controller.abort();
  	}

	async loadTickers() {
        var tickers = null;
        try {
            document.body.style.cursor = "wait";
		    tickers = await apiCalls.polygonTickers({signal:this.controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }
		if (!tickers) {
			return;
		}
		this.setState({tickers});
	}

	async handleChange(event) {
        var alert = '';
        var alertstyle = '';
		this.setState({[event.target.name]: event.target.value, alert, alertstyle});
	}

	async handleSubmit(event) {
		let timeformat = this.state.timespan;

        var series = null;
        try {
            document.body.style.cursor = "wait";
		    series = await apiCalls.polygonTickerAggregates(
			    this.state.ticker, this.state.multiplier, timeformat, 
			    this.state.from, this.state.to, {signal:this.controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }

        if (!series || !series.data) {
			return;
		}

        let observations = series.data.map((s) => Number(s.value));

        let startutc = series.data[0].time;
        let endutc   = series.data[observations.length-1].time;

        let res = timeunits_convert(timeformat, startutc, endutc);
        const {start, timestep, fconvert} = res;

        var timeutc    = series.data.map((s) => (s.time));
		var timestamps = series.data.map((s) => (fconvert(s.time)));
		var timeindex  = series.data.map((s, id) => (start+id*timestep));

		this.props.importSeries(this.state.ticker, series.frequency, 
			timeindex, timeutc, timestamps, observations);
	}

	render() {
		const {ticker, multiplier, timespan, from, to, apiinfo, apiurl, alert, alertstyle} = this.state;
		let timespanItems = this.timeformat.map((name, id) =>
        	      <option key={id}>{name}</option>);
		return (
			<Container className="mt-2" fluid>

                {apiinfo ? 
                    <Card className="m-1 notification" style={{'backgroundColor': 'whitesmoke'}}>
                        {apiinfo} 
                        <a href={apiurl} target="_blank" rel="noreferrer noopener">{apiurl}</a>
                    </Card>: ''
                }

                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Ticker</Card.Header>
                            <Card.Body className="p-1">
                            <input type="text" className="form-control" name = "ticker" 
					            onChange={this.handleChange} aria-label="ticker" value={ticker} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Multiplier</Card.Header>
                            <Card.Body className="p-1">
                            <input type="text" className="form-control" name="multiplier" 
					            onChange={this.handleChange} aria-label="multiplier" value={multiplier} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Timespan</Card.Header>
                            <Card.Body className="p-1">
                            <select className="form-control" name="timespan" 
                                onChange={this.handleChange} aria-label="timespan" value={timespan} >
                                {timespanItems}
                            </select>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">From</Card.Header>
                            <Card.Body className="p-1">
                            <input type="text" className="form-control" name = "from" 
					            onChange={this.handleChange} aria-label="from" value={from} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">To</Card.Header>
                            <Card.Body className="p-1">
                            <input type="text" className="form-control" name = "to" 
					            onChange={this.handleChange} aria-label="to" value={to} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Button className="mt-2" variant="primary" id="categories-up" onClick={this.handleSubmit}>
                            Import
                        </Button>
                    </Col>
                </Row>

                <div className="text-center">
                    {alert !== '' && (<div className={"m-4 alert "+alertstyle}> {alert} </div>)}
                </div>
			</Container>
		);
	}
}

export default PolygonForm;