import {ADD_ERROR, REMOVE_ERROR} from '../actionTypes';

// error reducer
const ErrorFunc = (state = {message: null, style: ''}, action) => {
    switch (action.type) {
		case ADD_ERROR:
            return {...state, message: action.error, style: action.style ? action.style : ''};
		case REMOVE_ERROR:
			return {...state, message: null, style: ''};
		default:
			return state;
	}
}

export default ErrorFunc;