import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Tab, Nav, Row, Col} from 'react-bootstrap';

import ModelPrior  from './ModelPrior';
import ModelSample from './ModelSample';
import McmcPlots from './McmcPlots';
import {MCMC_PLOTS_HISTOGRAM, MCMC_PLOTS_TRACE} from './McmcPlots';
import {MODEL_STATUS_SAMPLED} from '../store/actionTypes';

class ModelUpdate extends Component {
	constructor(props) {
		super(props);
		// varlabels, varfacts and varlogs are of this.props.variables' length
		this.state = {
			keytab: '2',
		};
        this.handleSelectTab = this.handleSelectTab.bind(this);
	}

    handleSelectTab(keytab) {
		this.setState({keytab});
	}

	render() {
        const {status, user_id, id} = this.props;
		const {keytab} = this.state;
        return (
            <Tab.Container activeKey={keytab} onSelect={this.handleSelectTab}>
                <Row className="flex-row">
                    <Col sm="2" className="flex-column">
                        <Nav variant="pills" className="flex-column p-1">
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={1}>Priors</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={2}>Sample</Nav.Link>
                            </Nav.Item>
                            {status === MODEL_STATUS_SAMPLED ? 
                                <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={3}>Histograms</Nav.Link>
                                </Nav.Item> : null
                            }
                            {status === MODEL_STATUS_SAMPLED ? 
                                <Nav.Item className="main-tab-item">
                                <Nav.Link eventKey={4}>Traces</Nav.Link>
                                </Nav.Item> : null
                            }
                        </Nav>
                    </Col>
                    <Col sm="10" className="flex-column">
                        <Container fluid className="text-center">

                        <Tab.Content className="source-tabs"> 	
                            <Tab.Pane eventKey={1}>
                                {keytab === '1' ? 
                                    <ModelPrior user_id={user_id} id={id} /> : ''
                                }
                            </Tab.Pane>

                            <Tab.Pane eventKey={2}>
                                {keytab === '2' ? 
                                    <ModelSample user_id={user_id} id={id} /> : ''
                                }
                            </Tab.Pane>

                            {status === MODEL_STATUS_SAMPLED ? 
                                <Tab.Pane eventKey={3}>
                                {keytab === '3' ? 
                                    <McmcPlots type={MCMC_PLOTS_HISTOGRAM}/> : ''
                                }
                                </Tab.Pane> : null
                            }
                            {status === MODEL_STATUS_SAMPLED ? 
                                <Tab.Pane eventKey={4}>
                                    {keytab === '4' ? 
                                        <McmcPlots type={MCMC_PLOTS_TRACE}/> : ''
                                    }
                                </Tab.Pane> : null
                            }

                        </Tab.Content>

                        </Container>
                    </Col>
                </Row>
            </Tab.Container>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    return {
        user_id, id,
        status: state.model.status, 
    };
}

export default connect(mapStateToProps)(ModelUpdate);
