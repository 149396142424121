import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col, Button, Card} from 'react-bootstrap';
import * as apiCalls from '../services/api';
import {addError, removeError} from '../store/actions/errors';
import {addRoute, addFacet, setLastRoute} from '../store/actions/eiacache';
import {timeunits_convert} from './utils';

class EiaForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
            apiinfo: 'U.S. Energy Information Administration API',
            apiurl:  'https://www.eia.gov/opendata/v1/qb.php',
            route_id: '',
            route_name: '',
            route_children: [],
            data_keys:  [],
            data_units: [],
            data_id:   '',
            data_unit: '',
            facet_ids:       '',
            facet_vals:      [],
            facet_valopts:   [],
            facet_curid:     '',
            facet_curval:    '',
            data_start: '',
            data_end:   '',
            frequency: '', 
            frequency_available: [],
            import_as: '',
        };
        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleRouteUp     = this.handleRouteUp.bind(this);
        this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

        this.months   = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        this.quarters = {'Q1': 'Mar', 'Q2': 'Jun', 'Q3': 'Sep', 'Q4': 'Dec'}

        this.controller = null;
	}

    componentDidMount() {
        this.controller = new window.AbortController();
        var {last_route_id, last_route_name} = this.props.eiacache;
        this.setRoute(last_route_id, last_route_name);
	}

    async setRoute(newroute_id, newroute_name) {
        var {route_id, route_name, route_children} = this.state;
        var {routes} = this.props.eiacache;
        var resp  = null;
        let found = routes.filter((r) => (r.path === newroute_id));
        if (found.length > 0) {
            found = found[0];
            if (typeof found.data === 'undefined') {
                resp = {
                    routes:           found.children
                };
            }
            else {
                resp = {
                    data:             found.data, 
                    facets:           found.facets, 
                    defaultFrequency: found.defaultFrequency, 
                    frequency:        found.frequency};
            }
        }
        else {
            try {
                /*document.body.style.cursor = "wait";
                resp = await apiCalls.noaaData('GHCND', '""', 'ZIP:28801', '""', 
                    '2010-05-01', '2010-05-01', {signal:this.controller.signal});
                document.body.style.cursor = "";*/
                document.body.style.cursor = "wait";
                resp = await apiCalls.eiaRoutes(newroute_id, {signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
        }

        if (typeof resp.data === 'undefined') {

            route_id       = newroute_id;
            route_name     = newroute_name;
            route_children = resp.routes;

            this.props.addRoute(route_id, route_children);

            this.setState({route_id, route_name, route_children, 
                data_keys: [], data_units: [], data_id: '', data_unit: '', 
                facets: [], frequency: '', frequency_available: []});
		}
        else {
            let {data, facets, defaultFrequency, frequency} = resp;

            route_id       = newroute_id;
            route_name     = newroute_name;
            route_children = [];

            let facet_ids     = facets.map((f) => f.id);
            let facet_vals    = facets.map((f) => '');
            let facet_valopts = facets.map((f) => []);

            if (found.length === 0) {
                frequency = frequency.map((f) => f.id);
                this.props.addRoute(route_id, [], data, facets, defaultFrequency, frequency);
            }

            if (facet_ids.length > 0) {
                facet_valopts[0] = await this.loadFacetValues(route_id, facet_ids[0]);
            }

            let data_keys  = Object.keys(data);
            let data_units = data_keys.map((d) => (d.units));
            let data_id    = data_keys[0];
            let data_unit  = data_units[0];
            let import_as  = data_id;

            this.setState({route_id, route_name, route_children, 
                data_keys, data_units, data_id, data_unit, 
                facet_ids, facet_valopts, facet_vals, facet_curid: facet_ids[0], facet_curval: '',
                frequency: defaultFrequency, frequency_available: frequency, import_as});
        }

        this.props.setLastRoute(route_id, route_name);
	}

    async loadFacetValues(route_id, facet_id) {
        var {facets} = this.props.eiacache;
        let found = facets.filter((f) => (f.route_id === route_id && f.facet_id === facet_id));
        var resp = null;
        if (found.length > 0) {
            return(found[0].values);
        }
        try {
            document.body.style.cursor = "wait";
            resp = await apiCalls.eiaFacets(route_id, facet_id, {signal:this.controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }
        if (!resp.values) {
            return [];
        }
        resp.values = resp.values.filter((v) => (v !== ''));
        this.props.addFacet(route_id, facet_id, resp.values);
        return(resp.values);
	}

    async handleRouteChange(event) {
        var {route_id, route_name, route_children} = this.state;
        let rfound = null;
        route_children.forEach((r) => {
            if (r.id === event.target.value) {
                rfound = r;
            }
        });
        if (rfound) {
            if (route_id !== '') {
                await this.setRoute(route_id+'/'+rfound.id, route_name+'/'+rfound.name);
            }
            else {
                await this.setRoute(rfound.id, rfound.name);
            }
        }
	}

    async handleRouteUp(event) {
        if (this.state.route_parent === "") {
            return;
        }
        let srouteids   = this.state.route_id.split('/');
        let sroutenames = this.state.route_name.split('/');
        let route_id    = srouteids.splice(0, srouteids.length-1);
        let route_name  = sroutenames.splice(0, sroutenames.length-1);

        route_id   = route_id.join('/');
        route_name = route_name.join('/');

        await this.setRoute(route_id, route_name);
	}

    async handleChange(event) {

        this.props.removeError();

        if (event.target.name === "data") {
            let {data_keys, data_unit, data_units} = this.state;
            let data_id = event.target.value;
            let k = data_keys.indexOf(data_id);
            if (k >= 0) {
                data_unit = data_units[k];
                let import_as = data_id;
                // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		        await this.setState({data_id, data_unit, import_as});
                return;
            }
        }
        else if (event.target.name === "facet_curid") {
            let {route_id, facet_ids, facet_vals, facet_valopts} = this.state;
            let facet_curid = event.target.value;
            let i = facet_ids.indexOf(facet_curid);
            if (facet_valopts[i].length < 1) {
                facet_valopts[i] = await this.loadFacetValues(route_id, facet_curid);
            }
            let facet_curval = facet_vals[i];
            // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		    await this.setState({facet_curid, facet_curval, facet_valopts});
            return;
        }
        else if (event.target.name === "facet_curval") {
            let {facet_ids, facet_curid, facet_vals} = this.state;
            let facet_curval = event.target.value;
            let i = facet_ids.indexOf(facet_curid);
            if (i >= 0) {
                facet_vals[i] = facet_curval;
            }
            // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		    await this.setState({facet_curval, facet_vals});
            return;
        }
        // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		await this.setState({[event.target.name]: event.target.value});
	}

    async handleSubmit(event) {
        let {route_id, data_id, facet_ids, facet_vals, frequency, data_start, data_end, import_as} = this.state;

        var timeformat = '';
        var eiaformat  = 'YYYY-MM-DD';
        var qregexp    = /^([1-9][0-9]{3})-(0[0-9]|10|11|12)-(0[1-9]|1[0-9]|2[0-9]|30|31)$/;
        var vartrans   = (d) => (d);
        switch (frequency) {
            case 'hourly':
				timeformat = 'hour';
                eiaformat  = 'YYYY-MM-DDTHH';
                qregexp    = /^([1-9][0-9]{3})-(0[0-9]|10|11|12)-(0[1-9]|1[0-9]|2[0-9]|30|31)T-([0-2][0-9])$/;
                vartrans = (d) => {
                    let w = d.split('-');
                    let h = w[2].split('T');
                    return (w[0] + '-' + w[1] + '-' + h[0] + 'T' + h[1] + ':00:00');
                };
				break;
			case 'daily':
				timeformat = 'day';
				break;
			case 'weekly':
				timeformat = 'week';
				break;
			case 'biweekly':
				timeformat = 'biweek';
				break;
			case 'monthly':
				timeformat = 'month';
                eiaformat = 'YYYY-MM';
                qregexp = /^([1-9][0-9]{3})-(0[0-9]|10|11|12)$/;
                vartrans = (d) => {
                    let w = d.split('-');
                    let mon = this.months[Number(w[1])];
                    return (mon + ' ' + w[0]);
                };
				break;
			case 'quarterly':
				timeformat = 'quarter';
                eiaformat = 'YYYY-Q#';
                qregexp = /^([1-9][0-9]{3})-(Q[1-4])$/;
                vartrans = (d) => {
                    let w = d.split('-');
                    return (this.quarters[w[1]] + ' ' + w[0]);
                };
				break;
			case 'annual':
				timeformat = 'year'
                eiaformat = 'YYYY';
                qregexp = /^([1-9][0-9]{3})$/;
                vartrans = (d) => ('Dec 31 '+ d);
				break;
			default:
		}

        if (data_start !== '') {
            let match = qregexp.exec(data_start);
            if (!match) {
                let alert = data_start + ' is invalid. Date format should be ' + eiaformat + '.';
                this.props.addError(alert, 'alert-danger');
                return;
            }
            data_start = match[0];
        }
        if (data_end !== '') {
            let match = qregexp.exec(data_end);
            if (!match) {
                let alert = data_end + ' is invalid. Date format should be ' + eiaformat + '.';
                this.props.addError(alert, 'alert-danger');
                return;
            }
            data_end = match[0];
        }

        var series = null;
        try {
            document.body.style.cursor = "wait";
		    series = await apiCalls.eiaData(
			    route_id, data_id,  facet_ids, facet_vals, frequency, 
                data_start, data_end, {signal:this.controller.signal});
            document.body.style.cursor = "";
        }
            catch {
            document.body.style.cursor = "";
        }
		if (!series || !series.data || series.count <= 0) {
            let alert = "Data not available for " + 
			    data_id + ' and frequency ' + timeformat + '.';
            this.props.addError(alert, 'alert-warning');
			return;
		}

        // new Date(Date.parse('2007')) = 'Sun Dec 31 2006 18:00:00 GMT-0600'

        const startutc = Date.parse(vartrans(series.data[0].time));
        const endutc   = Date.parse(vartrans(series.data[series.count-1].time));

        var obsValues = series.data.reduce(function(obj, s) {
            if (isNaN(Number(s.value))) {
                return obj;
            }
            if (!obj[s.time]) {
                obj[s.time] = s.value;
            } else {
                obj[s.time] += s.value;
            }
            return obj;
        }, {});

        var obsCounts = series.data.reduce(function(obj, s) {
            if (isNaN(Number(s.value))) {
                return obj;
            }
            if (!obj[s.time]) {
                obj[s.time] = 1;
            } else {
                obj[s.time]++;
            }
            return obj;
        }, {});

        var obsTimes = Object.keys(obsValues);
        obsTimes.sort((a,b) => (Date.parse(vartrans(a))-Date.parse(vartrans(b))));

        let observations = obsTimes.map((d,i) => Number(obsValues[d]/obsCounts[d]));

        let res = timeunits_convert(timeformat, startutc, endutc);
        const {start, timestep} = res;

        var timeutc    = obsTimes.map((time) => (Date.parse(vartrans(time))));
        var timestamps = obsTimes; //obsTimes((time) => (time));
        // EIA times may be irregular
        // obsTimes.map((s, id) => (start+id*timestep));
		var timeindex  = timeutc;

        if (Math.abs(start+(obsTimes.length-1)*timestep - timeutc[timeutc.length-1]) >= timestep) {
            let alert = "Observations of " + data_id + ' are at irregular time points. ';
            this.props.addError(alert, 'alert-warning');
            if(!window.confirm(alert + 'Do you still want to import the series?')) {
                return;
            }
        }

        this.props.importSeries(import_as, timeformat, 
			timeindex, timeutc, timestamps, observations);
	}

    render() {
		const {apiinfo, apiurl, route_name, route_children, 
            data_id, data_keys, data_start, data_end, 
            facet_ids, facet_valopts, facet_curid, facet_curval, 
            frequency, frequency_available, import_as} = this.state;

        var routeOptions = [];
        if (route_children && route_children.length > 0) {

            routeOptions = route_children.map((r) =>
                    <option key={r.id} value={r.id}>{r.name}</option>);
            routeOptions.unshift(<option key={0} value={''}>{'<select>'}</option>);
        }

        var facetOptions = [];
        var facetValueOptions = [];
        if (facet_ids && facet_ids.length > 0) {
            facetOptions = facet_ids.map((f, i) => <option key={i} value={f}>{f}</option>);
            let i = facet_ids.indexOf(facet_curid);
            if (i >= 0 && facet_valopts[i].length > 0) {
                facetValueOptions = facet_valopts[i].map((f, i) => 
                    (f.name ? <option key={i} value={f.id}>{f.name}</option> : 
                    <option key={i} value={f.id}>{f.id}</option>)
                );
                facetValueOptions.unshift(<option key={-1} value={''}>{'<select>'}</option>);
            }
        }

        let dataOptions      = data_keys.map((d, i) => <option key={i} value={d}>{d}</option>);
        let frequencyOptions = frequency_available.map((f, i) => <option key={i} value={f}>{f}</option>);

		return (
            <Container className="mt-2" fluid>
                {apiinfo ? 
                    <Card className="m-1 notification" style={{'backgroundColor': 'whitesmoke'}}>
                        {apiinfo} 
                        <a href={apiurl} target="_blank" rel="noreferrer noopener">{apiurl}</a>
                    </Card>: ''
                }

                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                        <Card.Header className="form-control-label">Route</Card.Header>
                        <Card.Body className="p-1">
                            {routeOptions.length > 0 ? 
                                <Row className="m-1 g-1">
                                    <Col>
                                        <label className="series-title">
                                            {route_name}
                                        </label>
                                    </Col>
                                    <Col>
                                    <Button variant="secondary" className="secondary-button" size="sm" onClick={this.handleRouteUp}>
                                        Up
                                    </Button>
                                    </Col>
                                    <Col>
                                    <select className="form-control form-bordercontrol" id="category" name="category" 
                                        onChange={this.handleRouteChange} >
                                        {routeOptions}
                                    </select>
                                    </Col> 
                                </Row> 
                                : 
                                <Row className="m-1 g-1">
                                    <Col>
                                        <label className="series-title">
                                            {route_name}
                                        </label>
                                    </Col>
                                    <Col>
                                    <Button variant="secondary" className="secondary-button" size="sm" onClick={this.handleRouteUp}>
                                        Up
                                    </Button>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>

            {data_id !== '' ? 

                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Facets</Card.Header>
                            <Card.Body className="p-1">
                                <Row className="g-1">
                                    <Col>
                                <select className="form-control" id="facet_curid" value={facet_curid} name="facet_curid" 
                                    onChange={this.handleChange}>
                                    {facetOptions}
                                </select>
                                    </Col>
                                    <Col>
                                <select className="form-control" id="facet_curval" value={facet_curval} name="facet_curval" 
                                    onChange={this.handleChange}>
                                    {facetValueOptions}
                                </select>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Data</Card.Header>
                            <Card.Body className="p-1">
                                <select className="form-control" id="data" name="data" 
                                    onChange={this.handleChange}>
                                    {dataOptions}
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row> : ''
            }
            
            {data_id !== '' ? 
                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Start
                            </Card.Header>
                            <Card.Body className="p-0">
                                <input type="text" className="form-control" 
                                    name = "data_start" 
                                    value={data_start} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
                    </Col>
		        	<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                End
                            </Card.Header>
                            <Card.Body className="p-0">
		        		        <input type="text" className="form-control"
                                    name = "data_end" 
		        			        value={data_end} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
					</Col>
					<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Frequency
                            </Card.Header>
                            <Card.Body className="p-0">
                                <select className="form-control" name="frequency" 
                                    onChange={this.handleChange} value={frequency}>
                                    {frequencyOptions}
                                </select>
                            </Card.Body>
                        </Card>
		        	</Col>
                    <Col>
                        {data_id !== '' ? 
                            <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Import as
                            </Card.Header>
                            <Card.Body className="p-0">
                                <input type="text" className="form-control"
                                    name = "import_as" 
		        			        value={import_as} onChange={this.handleChange} />
                            </Card.Body>
                            </Card>
                            : '' 
                        }
                    </Col>
                </Row>
                : ''
            }

            {import_as !== '' ? 
                <Button className="mt-2" variant="primary" id="categories-up" 
                    onClick={this.handleSubmit}>
                    Submit
                </Button>
                : '' 
            }
            
            </Container>
        )
    }
}

function mapStateToProps(state, ownProps) {
	return {eiacache: state.eiacache};
}

export default connect(mapStateToProps, {addError, removeError, addRoute, addFacet, setLastRoute})(EiaForm);