export function parseNumber(value) {
    var neg = false;
    var vals = value.split('-');
    if (vals.length >= 2 && vals[0] === '') {
        value = vals[1];
        neg   = true;
    }
    vals = value.split('.');
    vals[0] = vals[0].replace(/\D/g,'');
    if (vals.length === 2) {
        vals[1] = vals[1].replace(/\D/g,'');
        vals = vals[0]+'.'+vals[1];
    }
    else {
        vals = vals[0];
    }
    if (neg) {
        vals = '-'+vals;
    }
    // returns String
    return(vals);
}

export function parsePositiveNumber(value) {
    var vals = value.split('.');
    vals[0] = vals[0].replace(/\D/g,'');
    if (vals.length === 2) {
        vals[1] = vals[1].replace(/\D/g,'');
        vals = vals[0]+'.'+vals[1];
    }
    else {
        vals = vals[0];
    }
    // returns String
    return(vals);
}

export function timedata_combine(vardata, vartimeunits, vartimeindex, vartimestamps) {
    
    var p, i, j, nall, t, newtimeunit, starttime, endtime;

    p = vardata.length;

    starttime = -1;
    endtime   = -1;
    // union
    /*for (i = 0; i < p; i++) {
        if (!vardata[i]) {
            continue;
        }
        if (starttime === -1 || starttime > vartimeindex[i][0]) {
            starttime = vartimeindex[i][0];
        }
        if (endtime === -1 || endtime < vartimeindex[i][vartimeindex[i].length-1]) {
            endtime = vartimeindex[i][vartimeindex[i].length-1];
        }
    }*/
    //console.log("vartimeindex", vartimeindex);
    // intersection
    for (i = 0; i < p; i++) {
        if (!vardata[i]) {
            continue;
        }
        if (starttime === -1 || starttime < vartimeindex[i][0]) {
            starttime = vartimeindex[i][0];
        }
        if (endtime === -1 || endtime > vartimeindex[i][vartimeindex[i].length-1]) {
            endtime = vartimeindex[i][vartimeindex[i].length-1];
        }
    }

    //console.log("starttimes", starttime, endtime);

    newtimeunit = timeunits_downsample(vartimeunits);
    //newtimeunit = timeunits_upsample(vartimeunits);

    if (starttime >= endtime) {
        return ({timeunits: newtimeunit, timestep: 0, data: [], timeindex: [], timestamps: []});
    }

    //console.log("timeunits_downsample", vartimeunits, newtimeunit);

    let res = timeunits_convert(newtimeunit, starttime, endtime);
    const  {timestep, fconvert} = res;

    nall = 0;
    var indmap = new Array(p);
    for (i = 0; i < p; i++) {
        if (!vartimeindex[i]) {
            continue;
        }
        indmap[i] = timeindex_convert(vartimeindex[i], starttime, endtime, timestep);
        //console.log("timeindex_convert", vartimeindex[i], starttime, endtime, timestep, indmap[i]);
        if (nall <= 0) {
            nall = indmap[i].length;
        }
        else if (nall !== indmap[i].length) {
            throw new Error("variables of unequal length");
        }
    }
    //console.log("indmap", indmap);

    var newindex  = new Array(nall);
    var newstamps = new Array(nall);
    
    var newdata   = new Array(p);
    for (i = 0; i < p; i++) {
        if (!vardata[i]) {
            newdata[i] = null;
            continue;
        }
        newdata[i] = new Array(nall);
    }

    let misstimes = [];
    for (j = 0; j < nall; j++) {    
        newindex[j]  = (starttime + j*timestep);
        newstamps[j] = fconvert(newindex[j]);
        var imiss = 1;
        for (i = 0; i < p; i++) {
            if (!vardata[i]) {
                continue;
            };
            t = indmap[i][j];
            if (t >= 0) {

                if (isNaN(vardata[i][t])) {
                    // when downsampling allow valid lower value when the current is missing
                    if (j > 0) {
                        while (t > indmap[i][j-1] && isNaN(vardata[i][t])) {
                            t = t - 1;
                        }
                    }
                    else {
                        while (t > 0 && isNaN(vardata[i][t])) {
                            t = t - 1;
                        }
                    }
                }
                newdata[i][j] = vardata[i][t];
                imiss = 0;
            }
            else {
                newdata[i][j] = NaN;
            }
        }
        if (imiss) {
            misstimes.push(newindex[j]);
        }
    }

    //console.log("newdata", newdata);

    return ({timeunits: newtimeunit, timestep, data: newdata, timeindex: newindex, timestamps: newstamps, misstimes});
}

export function timeindex_convert(timeindex, startutc, endutc, timestep) {
    
    var i, j, t, n, newindex;

    n = Math.floor((endutc - startutc)/timestep);
    newindex = new Array(n);

    j = 0;
    // skip the time before startutc
    while (j+1 < timeindex.length & startutc-timeindex[j] >= timestep) {
        j++;
    }
    for (i = 0; i < n; i++) {
        newindex[i] = -1;
        if (j >= timeindex.length) {
            continue;
        }
        t = (startutc + i*timestep);
        //if (timeindex[j] <= t) {
        if (Math.abs(t-timeindex[j]) < timestep) {
            
            while (j+1 < timeindex.length && timeindex[j+1] <= t) {
                j++;
            }
            newindex[i] = j;
            j++;
        }
    }

    return (newindex);
}

export function timeunits_convert(timeformat, start, end) {
    // 1 minute precision
    //var timestep = 1;
    //const utcfactor = 60000;
    // 1 millisecond precision
    var timestep = 60000;
    const utcfactor = 1;
    // solar year: 525948.76666
    // leap year calculation (365.25 days): 525960;
    const yearmins = 525949.2;
    start = Math.floor(start/utcfactor);
    end   = Math.floor(end/utcfactor);
    var fconvert = (t) => (t);
    switch (timeformat) {
        case 'minute':
            timestep = 1*timestep;
            fconvert = (t) => {
                var d = new Date(t); 
                return(d.getHours()+':'+d.getMinutes()+' '+d.getSeconds())
            };
            break;
        case 'hour':
            timestep = 60*timestep;
            fconvert = (t) => {
                var d = new Date(t); 
                return(d.getHours()+':'+d.getMinutes()+' '+d.getSeconds())
            };
            break;
        case 'day':
            timestep = 24*60*timestep;
            fconvert = (t) => {
                var d = new Date(t); 
                return(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate())
            };
            break;
        case 'week':
            timestep = 7*24*60*timestep;
            fconvert = (t) => {
                var d = new Date(t); 
                return(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate())
            };
            break;
        case 'month':
            timestep = yearmins*timestep/12;
            fconvert = (t) => {
                var d = new Date(t);
                var m = '';
                switch (d.getMonth()) {
                    case 0:  m = 'Jan'; break;
                    case 1:  m = 'Feb'; break;
                    case 2:  m = 'Mar'; break;
                    case 3:  m = 'Apr'; break;
                    case 4:  m = 'May'; break;
                    case 5:  m = 'Jun'; break;
                    case 6:  m = 'Jul'; break;
                    case 7:  m = 'Aug'; break;
                    case 8:  m = 'Sep'; break;
                    case 9:  m = 'Oct'; break;
                    case 10: m = 'Nov'; break;
                    case 11: default: m = 'Dec'; break;
                }
                return(m + ' ' + d.getFullYear())
            };
            break;
        case 'quarter':
            timestep = yearmins*timestep/4;
            fconvert = (t) => {
                var d = new Date(t);
                switch (Math.floor(d.getMonth()/4)) {
                    case 3:
                    return('Q4 ' + d.getFullYear());
                    case 2:
                    return('Q3 ' + d.getFullYear());
                    case 1:
                    return('Q2 ' + d.getFullYear());
                    case 0:
                    default:
                    return('Q1 ' + d.getFullYear());
                }
            };
            break;
        case 'halfyear':
        case 'semiannual':
            timestep = yearmins*timestep/2;
            fconvert = (t) => {
                var d = new Date(t);
                switch (Math.floor(d.getMonth()/6)) {
                    case 1:
                    return('2nd ' + d.getFullYear());
                    default:
                    return('1st ' + d.getFullYear());
                }
            };
            break;
        case 'year':
        case 'annual':
            // minutes in 1 year
            timestep = yearmins*timestep;
            fconvert = (t) => {
                var d = new Date(t); 
                return(d.getFullYear());
            };
            break;
        case 'index':
        default:
            timestep = 1;
    }
    return({utcfactor, start, end, timestep, fconvert});
}

export function timeunits_upsample(units) {
    var i;
    var cunit = undefined;
    if (!units) {
        return (cunit);
    }
    for (i = 0; i < units.length; i++) {
        if (!cunit) {
            cunit = units[i];
            continue;
        }
        switch (units[i]) {
            case 'hour':
                if (cunit !== 'minute') {
                    cunit = units[i];
                }
                break;
            case 'day':
                if (cunit !== 'minute' && cunit !== 'hour') {
                    cunit = units[i];
                }
                break;
            case 'week':
                if (cunit !== 'minute' && cunit !== 'hour' && cunit !== 'day') {
                    cunit = units[i];
                }
                break;
            case 'month':
                if (cunit === 'year' || cunit === 'quarter') {
                    cunit = units[i];
                }
                break;
            case 'quarter':
                if (cunit === 'year') {
                    cunit = units[i];
                }
                break;
            case 'year':
                break;
            case 'index':
                // absorbing unit
                cunit = units[i];
                break;
            default:
        }
    }
    return (cunit);
}

export function timeunits_downsample(units) {
    var i;
    var cunit = undefined;
    if (!units) {
        return (cunit);
    }
    for (i = 0; i < units.length; i++) {
        if (!cunit) {
            cunit = units[i];
            continue;
        }
        switch (units[i]) {
            case 'hour':
                break;
            case 'day':
                if (cunit === 'hour') {
                    cunit = units[i];
                }
                break;
            case 'week':
                if (cunit === 'hour' || cunit === 'day') {
                    cunit = units[i];
                }
                break;
            case 'month':
                if (cunit !== 'year' && cunit !== 'quarter' && cunit !== 'month') {
                    cunit = units[i];
                }
                break;
            case 'quarter':
                if (cunit !== 'year' && cunit !== 'quarter') {
                    cunit = units[i];
                }
                break;
            case 'year':
                if (cunit !== 'year') {
                    cunit = units[i];
                }
                break;
            case 'index':
                // absorbing unit
                cunit = units[i];
                break;
            default:
        }
    }
    return (cunit);
}

export function timeunits_steps(units, targetunit) {
    var i, r, steps;
    if (!units || !targetunit) {
        return;
    }
    steps = new Array(units.length);
    switch (targetunit) {
        case 'minute':
            // 525960 minutes in 1 year
            // years in 1 minute
            r = 1/525960;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = 1;
                        break;
                    case 'hour':
                        steps[i] = 1/60;
                        break;
                    case 'day':
                        steps[i] = 1/(24*60);
                        break;
                    case 'week':
                        steps[i] = 52*r;
                        break;
                    case 'month':
                        steps[i] = 12*r;
                        break;
                    case 'quarter':
                        steps[i] = 4*r;
                        break;
                    case 'year':
                        steps[i] = r;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'hour':
            // years in 1 hour
            r = 60/525960;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = 60;
                        break;
                    case 'hour':
                        steps[i] = 1;
                        break;
                    case 'day':
                        steps[i] = 1/24;
                        break;
                    case 'week':
                        steps[i] = 52*r;
                        break;
                    case 'month':
                        steps[i] = 12*r;
                        break;
                    case 'quarter':
                        steps[i] = 4*r;
                        break;
                    case 'year':
                        steps[i] = r;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'day':
            // years in 1 day
            r = 24*60/525960;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = 24*60;
                        break;
                    case 'hour':
                        steps[i] = 24;
                        break;
                    case 'day':
                        steps[i] = 1;
                        break;
                    case 'week':
                        steps[i] = 52*r;
                        break;
                    case 'month':
                        steps[i] = 12*r;
                        break;
                    case 'quarter':
                        steps[i] = 4*r;
                        break;
                    case 'year':
                        steps[i] = r;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'week':
            // years in 1 week
            r = 7*24*60/525960;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = 7*24*60;
                        break;
                    case 'hour':
                        steps[i] = 7*24;
                        break;
                    case 'day':
                        steps[i] = 7;
                        break;
                    case 'week':
                        steps[i] = 1;
                        break;
                    case 'month':
                        steps[i] = 12*r;
                        break;
                    case 'quarter':
                        steps[i] = 4*r;
                        break;
                    case 'year':
                        steps[i] = r;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'month':
            // minutes in 1 month
            r = 525960/12;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = r;
                        break;
                    case 'hour':
                        steps[i] = r/60;
                        break;
                    case 'day':
                        steps[i] = r/(24*60);
                        break;
                    case 'week':
                        steps[i] = r/(7*24*60);
                        break;
                    case 'month':
                        steps[i] = 1;
                        break;
                    case 'quarter':
                        steps[i] = 1/3;
                        break;
                    case 'year':
                        steps[i] = 1/12;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'quarter':
            // minutes in 3 months
            r = 525960/4;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = r;
                        break;
                    case 'hour':
                        steps[i] = r/60;
                        break;
                    case 'day':
                        steps[i] = r/(24*60);
                        break;
                    case 'week':
                        steps[i] = r/(7*24*60);
                        break;
                    case 'month':
                        steps[i] = 3;
                        break;
                    case 'quarter':
                        steps[i] = 1;
                        break;
                    case 'year':
                        steps[i] = 1/4;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'year':
            // minutes in 1 year
            r = 525960;
            for (i = 0; i < units.length; i++) {
                switch (units[i]) {
                    case 'minute':
                        steps[i] = r;
                        break;
                    case 'hour':
                        steps[i] = r/60;
                        break;
                    case 'day':
                        steps[i] = r/(24*60);
                        break;
                    case 'week':
                        steps[i] = r/(7*24*60);
                        break;
                    case 'month':
                        steps[i] = 12;
                        break;
                    case 'quarter':
                        steps[i] = 4;
                        break;
                    case 'year':
                        steps[i] = 1;
                        break;
                    case 'index':
                    default:
                }
            }
            return (steps);
        case 'index':
        default:
            for (i = 0; i < units.length; i++) {
                steps[i] = 1;
            }
            return (steps);
    }
}

export function timeunits_select() {
    return [
        {name: 'index',   value: 'index'}, 
        {name: 'minute',  value: 'minute'}, 
        {name: 'hour',    value: 'hour'},
        {name: 'day',     value: 'day'},
        {name: 'week',    value: 'week'},
        {name: 'month',   value: 'month'},
        {name: 'quarter', value: 'quarter'},
        {name: 'year',    value: 'year'},
    ];
}