import {SET_VARIABLES, SET_VARLABELS, SET_VARFACTS, SET_VARLOGS, SET_VARLAGS, 
    ADD_VARIABLE, REMOVE_VARIABLE, SET_CSVFILE, SET_TIMEVAR} from '../actionTypes';

/*
    variables: {
        name: , 
        data: , 
        timeunits: , 
        timeindex: , 
        timestamps: 
    }
*/

const initialState = {
	variables: [],
	varlabels: [],
	varfacts:  [],
	varlogs:   [],
	varlags:   [],
    csvfile:   '',
    timevar:   'index',
};

// variables reducer function (state, action)
const datatable = (state = initialState, action) => {
    let newState = {};
	switch (action.type) {
        case SET_VARIABLES:
			return {...state, variables: action.variables};
        case SET_VARLABELS:
            return {...state, varlabels: action.varlabels};
		case SET_VARFACTS:
			return {...state, varfacts: action.varfacts};
		case SET_VARLOGS:
			return {...state, varlogs: action.varlogs};
		case SET_VARLAGS:
			return {...state, varlags: action.varlags};
        case ADD_VARIABLE:
            newState = {...state};
            newState.variables = [...state.variables, action.variable];
            newState.varlabels = [...state.varlabels, ''];
            newState.varfacts  = [...state.varfacts, 0];
            newState.varlogs   = [...state.varlogs, 0];
            newState.varlags   = [...state.varlags, 0];
            return newState;
		case REMOVE_VARIABLE:
            //newState = Object.assign({}, state);
			newState = {...state};
            var dropind = -1;
            state.variables.forEach((v,i) => {
                if (v.name === action.varname) {
                    dropind = i;
                }
            });
            if (dropind < 0) {
                return state;
            }
			newState.variables = state.variables.filter((v,i) => i !== dropind);
            newState.varlabels = state.varlabels.filter((v,i) => i !== dropind);
            newState.varfacts  = state.varfacts.filter((v,i) => i !== dropind);
            newState.varlogs   = state.varlogs.filter((v,i) => i !== dropind);
            newState.varlags   = state.varlags.filter((v,i) => i !== dropind);
			return newState;
        case SET_CSVFILE:
            return {...state, csvfile: action.csvfile};
        case SET_TIMEVAR:
            return {...state, timevar: action.timevar};
		default:
			return state;
	}
}

export default datatable;