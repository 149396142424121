import {ADD_ERROR, REMOVE_ERROR} from '../actionTypes';

// action creators

export function addError(error, style) {
	return {
		type: ADD_ERROR,
		error,
        style,
	}
}

export function removeError() {
	return {
		type: REMOVE_ERROR
	}
}