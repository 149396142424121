import React, {Component} from 'react';
import { connect } from "react-redux";
import {Container, Tab, Nav, Row, Col, InputGroup} from 'react-bootstrap';

import {addError, removeError} from '../store/actions/errors';

import {addVariable, setCsvfile, setTimevar} from '../store/actions/datatable';
import {addTschart} from '../store/actions/datagraphs';

import EiaForm from './EiaForm';
import FredSeriesForm from './FredSeriesForm';
import FredForm from './FredForm';
import FredTagsForm from './FredTagsForm';
import PolygonForm from './PolygonForm';
import TsChart from './TsChart';

import {timedata_combine, timeunits_select, timeunits_convert} from './utils.js';

class DataSources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keytab: '1',
            delimiter: ',',
            header: 'true',
            quoted: 'true', 
            timevar: 'index',
            csvfile: '', 
			downloadCsvFile: 'data.csv'
		};
        this.delimiters = [{name: 'comma', value: ','}, {name: 'tab', value: '\t'}];
        this.header     = [{name: 'yes',   value: true}, {name: 'no', value: false}];
        this.quoted     = [{name: 'yes',   value: true}, {name: 'no', value: false}];
		this.handleSelectTab = this.handleSelectTab.bind(this);
		this.handleChange    = this.handleChange.bind(this);
		this.importSeries    = this.importSeries.bind(this);
		this.importCsvFile   = this.importCsvFile.bind(this);
		this.downloadCsvFile = this.downloadCsvFile.bind(this);
	}

    componentDidMount() {
        let {csvfile, timevar} = this.props;
        if (csvfile) {
            this.props.addError(`Last imported file: ${csvfile.name}`, 'alert-success');
        }
        else {
            this.props.addError('Import data from a local csv file, FRED, EIA, or Polygon APIs', 'alert-success');
        }
        this.setState({csvfile, timevar});
    }

	handleChange(event) {
        this.props.removeError();
        document.getElementById("importCsvFile").value = "";
		this.setState({[event.target.name]: event.target.value});
	}

	async importCsvFile(event) {
		var i, j, nvars, val, timevarfound;
        const {delimiter, header, quoted} = this.state;
        let {timevar} = this.state;
        var alert = null;
        var err = 0;

        timevarfound = false;
        nvars    = 0;
		let file = event.target.files[0];
        try {
        	/* eslint-disable no-undef */
			var csv = new CsvParser();
			await csv.readTextFile(file, 
				(csvobject) => {
					let ds = csvobject.dsref();
                    let k  = csvobject.numvars();
                    let n  = ds.length();
                    let varnames = ds.varnames();
                    let vardata  = ds.vardata();
                    let vartypes = ds.vartypes();

            		var timeindex  = new Array(n);
                    var timestamps = new Array(n);
					for (i = 0; i < n; i++) {
						timeindex[i] = i;
                        timestamps[i] = timeindex[i].toString();
					}
                    for (j = 0; j < k; j++) {
                        if (varnames[j].toLowerCase() === timevar) {
                            timevarfound = true;
                            vartypes[j] = 'time';
                            let {fconvert} = timeunits_convert(timevar, vardata[j][0], vardata[j][n-1]);
                            for (i = 0; i < n; i++) {
                                timeindex[i] = vardata[j][i];
                                val = Number(vardata[j][i]);
                                if (isNaN(val)) {
                                    timestamps[i] = vardata[j][i].toString();
                                }
                                else {
                                    timestamps[i] = fconvert(val);
                                }
                            }
                        }
                    }
                    for (j = 0; j < k; j++) {
						if (this.props.varnames.includes(varnames[j])) {
                            alert = 'Variable ' + varnames[j] + ' is already imported.';
                            this.props.addError(alert, 'alert-warning');
                            continue;
						}
                        if (vartypes[j] !== 'scalar') {
                            continue;
                        }
                        if (varnames[j].toLowerCase() !== timevar) {
                            this.props.addVariable({name: varnames[j], data: vardata[j], 
                                timeunits: timevar, timeindex, timestamps});
                            // timeindex is assumed time in UTC
                            this.props.addTschart(new TsChart(
                                timevar, timeindex, timeindex, varnames[j], vardata[j]));
                        }
              		}
				}, delimiter, header === 'true', quoted === 'true');
            nvars = csv.dsref().varnames().length;
            if (nvars > 0) {
                nvars = csv.dsref().vartypes().filter((t) => (t === 'scalar')).length;
            }
			/* eslint-enable no-undef */
		}
		catch(err) {
            this.props.addError('class CsvParser not found', 'alert-danger');
	    }
        if (err) {
            return;
        }
        if (!timevarfound) {
            timevar = 'index';
        }
        if (nvars < 1) {
            alert = 'No scalar variables found in ' + file.name + '. ';
            alert += 'You may try different delimiter.';
            this.props.addError(alert, 'alert-danger');
        }
        else {
            alert = nvars + ' variables imported from ' + file.name + '. ';
            this.props.addError(alert, 'alert-success');
            this.props.setCsvfile(file);
            this.props.setTimevar(timevar);
            this.setState({csvfile: file, timevar});
        }
	}

	importSeries(name, timeunits, timeindex, timeutc, timestamps, observations) {
		
        var alert = null;

		if (this.props.varnames.includes(name)) {
			alert = 'Variable ' + name + ' has already been imported.';
            this.props.addError(alert, 'alert-warning');
			return;
		}
        else {
            alert = 'Variable ' + name + ' of length ' + observations.length + ' imported. You can see the time-series by clicking on the Graphs tab.';
            this.props.addError(alert, 'alert-success');
        }

		this.props.addVariable({name: name, data: observations, 
			timeunits, timeindex, timestamps});

		this.props.addTschart(new TsChart(timeunits, timeutc, timeindex, name, observations));

		this.setState({csvfile: ''});
	}

	downloadCsvFile(event) {
		const {downloadCsvFile} = this.state;
        const {varnames, vardata, vartimeunits, vartimeindex, vartimestamps} = this.props;

        var p, n, i, j, textbuff, line, val;

		if (varnames.length < 1) {
			return;
		}

        let res = timedata_combine(vardata, vartimeunits, vartimeindex, vartimestamps);
        const {data, timeindex, timestamps} = res;

        p = varnames.length;
        n = timeindex.length;
        if (data.length !== p || timestamps.length !== n) {
            return;
        }

        textbuff = [];
		line = '';
		line += 'time\t';
        for (j = 0; j < p-1; j++) {
			line += (varnames[j] + '\t');
		}
		line += (varnames[p-1] + '\n');
		textbuff.push(line);
		for (i = 0; i < n; i++) {
			line = '';
			val = timestamps[i];
            if (!val) {
                break;
            }
			line += (val.toString() + '\t');
            for (j = 0; j < p-1; j++) {
                val = data[j][i];
                if (isNaN(val)) {
                    line += '.\t';
                }
                else {
				    line += (val.toString() + '\t');
                }
			}
            val = data[p-1][i];
            if (isNaN(val)) {
                line += '.\n';
            }
            else {
                line += (val.toString() + '\n');
            }
			textbuff.push(line);
		}

        let blob = new Blob(textbuff, {type:'text/plain'});
        let link = document.createElement("a");
        link.download = downloadCsvFile;
        //link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);

	}

	handleSelectTab(keytab) {
        this.props.removeError();
		this.setState({keytab});
	}

	render() {
		const {keytab, delimiter, header, quoted, timevar} = this.state;
        let delimiterOptions = this.delimiters.map((s, i) =>
                <option key={i} value={s.value}>{s.name}</option>);
        let headerOptions = this.header.map((s, i) =>
            <option key={i} value={s.value}>{s.name}</option>);
        let quotedOptions = this.quoted.map((s, i) =>
            <option key={i} value={s.value}>{s.name}</option>);
        let timevarOptions = timeunits_select().map((s, i) =>
            <option key={i} value={s.value}>{s.name}</option>);
		// transition={true} throws 'Warning: findDOMNode is deprecated in StrictMode...'
        // bootstrap sm="2": 2 units out of 12 for the entire line
		return (
            <Tab.Container activeKey={keytab} onSelect={this.handleSelectTab}>
                <Row className="flex-row">
                    <Col sm="2" className="flex-column">
                        <Nav variant="pills" className="flex-column p-1">
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={1}>CSV</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={2}>FRED Series</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={3}>FRED Sources</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={4}>FRED Tags</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={5}>EIA</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="main-tab-item">
                            <Nav.Link eventKey={6}>Polygon</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm="10" className="flex-column">

                        <Container className="text-center">

                        <Tab.Content className="source-tabs"> 	
                            <Tab.Pane eventKey={1}>
                                {keytab === '1' ? 
                                        <div>
                                            <Row sm="3" className="mt-4 g-1">
                                            <div>
                                                <InputGroup className="m-1">
                                                <InputGroup.Text className="form-control form-control-label">Delimiter</InputGroup.Text>
                                                <select className="form-control" 
                                                    name = "delimiter" value={delimiter} onChange={this.handleChange}>
                                                        {delimiterOptions}
                                                </select>
                                                </InputGroup>
                                            </div>
                                            <div>
                                                <InputGroup className="m-1">
                                                <InputGroup.Text className="form-control form-control-label">Header</InputGroup.Text>
                                                <select className="form-control" 
                                                    name = "header" value={header} onChange={this.handleChange}>
                                                        {headerOptions}
                                                </select>
                                                </InputGroup>
                                            </div>
                                            <div>
                                                <InputGroup className="m-1">
                                                <InputGroup.Text className="form-control form-control-label">Quoted</InputGroup.Text>
                                                <select className="form-control" 
                                                    name = "quoted" value={quoted} onChange={this.handleChange}>
                                                        {quotedOptions}
                                                </select>
                                                </InputGroup>
                                            </div>
                                            </Row>

                                            <Row sm="2" className="mt-4">
                                            <div>
                                                <input type="file" className="m-1 form-control" 
                                                    id="importCsvFile" accept='.csv' 
                                                    onChange={this.importCsvFile} />
                                            </div>
                                            <div>
                                                <InputGroup  className="m-1">
                                                <InputGroup.Text className="form-control form-control-label">Time variable</InputGroup.Text>
                                                <select className="form-control" 
                                                    name = "timevar" value={timevar} onChange={this.handleChange}>
                                                        {timevarOptions}
                                                </select>
                                                </InputGroup>
                                            </div>
                                            </Row>
                                        </div> : ''
						        }
                            </Tab.Pane>

                            <Tab.Pane eventKey={2}>
                                {keytab === '2' ? 
                                    <FredSeriesForm importSeries={this.importSeries} /> : ''
                                }
                            </Tab.Pane>

                            <Tab.Pane eventKey={3}>
                                {keytab === '3' ? 
                                    <FredForm importSeries={this.importSeries} /> : ''
                                }
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey={4}>
                                {keytab === '4' ? 
                                    <FredTagsForm importSeries={this.importSeries} /> : ''
                                }
                            </Tab.Pane>

                            <Tab.Pane eventKey={5}>
                                {keytab === '5' ? 
                                    <EiaForm importSeries={this.importSeries} /> : ''
                                }
                            </Tab.Pane>

                            <Tab.Pane eventKey={6}>
                                {keytab === '6' ? 
				    	    	    <PolygonForm importSeries={this.importSeries} /> : ''
				    	        }
                            </Tab.Pane>

                        </Tab.Content>

                        </Container>
                    </Col>
                </Row>
            </Tab.Container>
		);
                        /*
                        <Button className="m-4" variant="secondary" id="btn-download" onClick={this.downloadCsvFile}>
                            Download CSV
                        </Button>
                        */
	}
}

function mapStateToProps(state) {
    let {variables, csvfile, timevar} = state.datatable;
	const varnames      = variables.map((v) => (v.name));
	const vardata       = variables.map((v) => (v.data));
    const vartimeunits  = variables.map((v) => (v.timeunits));
    const vartimeindex  = variables.map((v) => (v.timeindex));
    const vartimestamps = variables.map((v) => (v.timestamps));
    return {
		varnames, 
		vardata,
        vartimeunits, 
        vartimeindex,
        vartimestamps,
        csvfile,
        timevar
	};
}

export default connect(mapStateToProps, {addError, removeError, addVariable, addTschart, setCsvfile, setTimevar})(DataSources);