import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import {Container} from 'react-bootstrap';
import McmcChart from './McmcChart';

export const MCMC_PLOTS_HISTOGRAM = "MCMC_PLOTS_HISTOGRAM";
export const MCMC_PLOTS_TRACE     = "MCMC_PLOTS_TRACE";
export const MCMC_PLOTS_PIE       = "MCMC_PLOTS_PIE";
export const MCMC_PLOTS_FCAST     = "MCMC_PLOTS_FCAST";
export const MCMC_PLOTS_IRF       = "MCMC_PLOTS_IRF";
export const MCMC_PLOTS_OIRF      = "MCMC_PLOTS_OIRF";
export const MCMC_PLOTS_DM        = "MCMC_PLOTS_DM";

class McmcPlots extends Component {
	constructor(props) {
		super(props);
		// varlabels, varfacts and varlogs are of this.props.variables' length
		this.state = {
			alert: '',
			alertcolor: 'black',
			charts: [],
		};
        this.plotsRef = createRef();

        this.resizeWindow = this.resizeWindow.bind(this);
        window.addEventListener('resize', this.resizeWindow);
	}

    componentDidMount() {
        var charts, newchart;
        const {mcmcsample}  = this.props.model;
        const {fcastsample} = this.props.model;
        const {irfsample, oirfsample, dmsample} = this.props.model;

        charts = this.state.charts.slice();
        switch (this.props.type) {
            case MCMC_PLOTS_TRACE:
            case MCMC_PLOTS_PIE:
            case MCMC_PLOTS_HISTOGRAM:        
                newchart = new McmcChart(this.plotsRef.current, mcmcsample);        
                break;
            case MCMC_PLOTS_FCAST:
                newchart = new McmcChart(this.plotsRef.current, fcastsample);
                break;
            case MCMC_PLOTS_IRF:
                newchart = new McmcChart(this.plotsRef.current, irfsample);
                break;
            case MCMC_PLOTS_OIRF:
                newchart = new McmcChart(this.plotsRef.current, oirfsample);
                break;
            case MCMC_PLOTS_DM:
                newchart = new McmcChart(this.plotsRef.current, dmsample);
                break;
            default:
        }
        charts.push(newchart);
        this.setState({charts});

        this.drawPlots(newchart);
 	}

    resizeWindow(event) {
        this.drawPlots();
 	}

    deletePlots() {
		var parentRef = this.plotsRef.current;
        if (!parentRef || !parentRef.childNodes) {
            return;
        }
  		var n = parentRef.childNodes.length;
		if (n < 1) {
			return;
		}
		for (var j = n-1; j >= 0; j--) {
      		parentRef.removeChild(parentRef.childNodes[j]);
    	}
	}

    drawPlots(chart) {
        this.deletePlots();
        switch (this.props.type) {
            case MCMC_PLOTS_TRACE:
                if (chart) {
                    chart.drawTracePlots();
                }
                else {
                    const {mcmccharts} = this.state;
                    if (mcmccharts) {
                        mcmccharts.forEach((chart) => chart.drawTracePlots());
                    }
                }
                break;
            case MCMC_PLOTS_PIE:
                if (chart) {
                    chart.drawPiePlots();
                }
                else {
                    const {mcmccharts} = this.state;
                    if (mcmccharts) {
                        mcmccharts.forEach((chart) => chart.drawPiePlots());
                    }
                }
                break;
            case MCMC_PLOTS_HISTOGRAM:
                if (chart) {
                    chart.drawHistogramPlots();
                }
                else {
                    const {mcmccharts} = this.state;
                    if (mcmccharts) {
                        mcmccharts.forEach((chart) => chart.drawHistogramPlots());
                    }
                }
                break;
            case MCMC_PLOTS_FCAST:
                if (chart) {
                    chart.drawFcastPlots();
                }
                else {
                    const {mcmccharts} = this.state;
                    if (mcmccharts) {
                        mcmccharts.forEach((chart) => chart.drawFcastPlots());
                    }
                }
                break;
            case MCMC_PLOTS_IRF:
            case MCMC_PLOTS_OIRF:
            case MCMC_PLOTS_DM:
                if (chart) {
                    chart.drawIrfPlots();
                }
                else {
                    const {mcmccharts} = this.state;
                    if (mcmccharts) {
                        mcmccharts.forEach((chart) => chart.drawIrfPlots());
                    }
                }
                break;
            default:
        }
 	}

	render() {
        return (
			<Container className="text-center">
				<div id='mcmcplots' ref={this.plotsRef} >
				</div>
			</Container>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
	return {model: state.model, user_id, id};
}

export default connect(mapStateToProps)(McmcPlots);
