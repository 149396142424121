import {SET_VARIABLES, SET_VARLABELS, SET_VARFACTS, SET_VARLOGS, SET_VARLAGS, 
    ADD_VARIABLE, REMOVE_VARIABLE, SET_CSVFILE, SET_TIMEVAR} from '../actionTypes';

// action creators

export function setVariables(variables) {
	return {
		type: SET_VARIABLES,
		variables
	}
}

export function setVarlabels(varlabels) {
	return {
		type: SET_VARLABELS,
		varlabels
	}
}

export function setVarfacts(varfacts) {
	return {
		type: SET_VARFACTS,
		varfacts
	}
}

export function setVarlogs(varlogs) {
	return {
		type: SET_VARLOGS,
		varlogs
	}
}

export function setVarlags(varlags) {
	return {
		type: SET_VARLAGS,
		varlags
	}
}

export function addVariable(variable) {
	return {
		type: ADD_VARIABLE,
		variable
	}
}

export function removeVariable(varname) {
	return {
		type: REMOVE_VARIABLE,
		varname
	}
}

export function setCsvfile(csvfile) {
	return {
		type: SET_CSVFILE,
		csvfile
	}
}

export function setTimevar(timevar) {
	return {
		type: SET_TIMEVAR,
		timevar
	}
}