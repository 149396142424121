import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col, Button, Card} from 'react-bootstrap';
import * as apiCalls from '../services/api';
import {addError, removeError} from '../store/actions/errors';
import {addSources, addReleases, addCategory, addSeries, addCategorySeries, addReleaseSeries} from '../store/actions/fredcache';
import {loadFredSeries, importFredSeries} from './FredSeries.js';

class FredForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
            apiinfo: 'FRED economic data API',
            apiurl:  'https://fred.stlouisfed.org/docs/api/fred/',
			category_id:     0,
			category_name:  '',
			category_parent: 0,
			source:  '', 
			release: '',
			series_id:    '',
			series_title: '',
			observation_start: '', 
			observation_end: '',
			frequency: 'month', 
			last_updated: '',
			varnames: [], 
			vardata: [],
            limit:  100,
            page:     1,
            maxpage:  1,
            import_as: '',
            series_changed: false,
            series_imported: false
        };
		this.handleChange = this.handleChange.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleCategoryUp = this.handleCategoryUp.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.timeformat = [
            {name: 'day',        id: 'd'}, 
            {name: 'week',       id: 'w'}, 
            {name: 'biweek',     id: 'bw'}, 
            {name: 'month',      id: 'm'}, 
            {name: 'quarter',    id: 'q'}, 
            {name: 'semiannual', id: 'sa'}, 
            {name: 'annual',     id: 'a'}
        ];

		this.category_children = [];
		this.sources  = [];
		this.releases = [];
		this.series   = [];
        this.series_page_fn = null;

		this.controller = null;
	}

	componentDidMount() {
        this.controller = new window.AbortController();
		this.loadSources();
		// choose to load either release series or category series
        this.setCategory(0, false);
	}

	componentWillUnmount() {
        this.controller.abort();
  	}

	async setCategory(category_id, load_series) {
        var {categories} = this.props.fredcache;
        var resp  = null;
        // category_id is integer
        category_id = parseInt(category_id);
        let found = categories.filter((r) => (r.id === category_id));
        if (found.length > 0) {
            resp = found[0];
            this.category_children = resp.children;
        }
        else {
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredCategory(category_id, {signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
            if (!resp) {
                return;
            }
            resp = resp[0];

		    await this.loadChildrenCategories(category_id);

            this.props.addCategory(category_id, resp.name, resp.parent_id, this.category_children);
        }

		this.setState({category_id, category_name: resp.name, category_parent: resp.parent_id});

        if (load_series) {
            await this.loadCategorySeries(category_id);
        }
	}

	async loadChildrenCategories(category_id) {
        var resp = null;
        try {
            document.body.style.cursor = "wait";
            resp = await apiCalls.fredCategoryChildren(category_id, {signal:this.controller.signal});
            document.body.style.cursor = "";
        }
        catch {
            document.body.style.cursor = "";
        }
		if (!resp || !resp.categories || resp.categories.length < 1) {
            return;
		}
        this.category_children = resp.categories;
	}

	async loadCategorySeries(category_id) {
        var {category_series} = this.props.fredcache;
        let {page, limit, maxpage} = this.state;
        
        if (typeof category_id == 'undefined') {
            category_id = this.state.category_id;
        }
        page = parseInt(page);

        var resp  = null;
        let found = category_series.filter((r) => (r.category === category_id && r.page === page));
        if (found.length > 0) {
            found = found[0];
            resp = {series: found.values};

            limit   = found.limit;
            page    = found.page;
            maxpage = found.maxpage;
        }
        else {
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredCategorySeries(category_id, limit, page, {signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
            if (!resp) {
                return;
            }
            if (typeof resp.limit !== 'undefined') {
                limit    = resp.limit;
                //page     = 1 + Math.floor((resp.series.length-1) / resp.limit);
                maxpage  = 1 + Math.floor(resp.count / resp.limit);
            }

            await this.props.addCategorySeries(category_id, limit, page, maxpage, resp.series);
        }

		if (!resp || !resp.series || resp.series.length < 1) {
            // if there is no category series, try release series
            await this.loadReleaseSeries();
		}
        else {
            this.series = resp.series;
            this.series_page_fn = this.loadCategorySeries;    

            await this.loadSeries(this.series[0].id);

            this.setState({limit, page, maxpage});
        }
	}

	async loadSources() {
        var {sources} = this.props.fredcache;
        var resp  = null;
        if (sources.length > 0) {
            resp = {sources};
        }
        else{
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredSources({signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
            if (!resp) {
                return;
            }
            if (!resp.sources || resp.sources.length < 1) {
                this.props.addError('Failed to load FRED sources.', 'alert-danger');
                return;
            }
            await this.props.addSources(resp.sources);
        }
        this.sources = resp.sources;
        this.setState({source: resp.sources[0].id});
        await this.loadSourceReleases(resp.sources[0].id);
	}

	async loadReleases() {
        var {releases} = this.props.fredcache;
        var resp  = null;
        if (releases.length > 0) {
            resp = {releases};
        }
        else {
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredReleases({signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
            if (!resp) {
                return;
            }
            if (!resp.releases || resp.releases.length < 1) {
                this.props.addError('Failed to load FRED releases.', 'alert-danger');
                return;
            }
            await this.props.addReleases('', resp.releases);
        }
        this.releases = resp.releases;
        this.setState({release: resp.releases[0].id});
	}

	async loadSourceReleases(source_id) {
        var resp  = null;
        var {releases} = this.props.fredcache;
        if (typeof source_id == 'undefined') {
            source_id = this.state.source;
        }
        let found = releases.filter((r) => (r.source === source_id));
        if (found.length > 0) {
            found = found[0];
            resp = {releases: found.values};
        }
        else {
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredSourceReleases(source_id, {signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
		    if (!resp) {
			    return;
		    }
            if (!resp.releases || resp.releases.length < 1) {
                this.props.addError(`Failed to load FRED releases for ${source_id}`, 'alert-danger');
                return;
            }
            await this.props.addReleases(source_id, resp.releases);
        }
        this.releases = resp.releases;
		this.setState({release: resp.releases[0].id});
        await this.loadReleaseSeries(resp.releases[0].id);
	}

	async loadReleaseSeries(release_id) {
        var {release_series} = this.props.fredcache;
        let {page, limit, maxpage} = this.state;
        if (typeof release_id == 'undefined') {
            release_id = this.state.release;
        }
        if (release_id === '') {
            return;
        }
        // release_id is integer
        release_id = parseInt(release_id);
        page = parseInt(page);
        var resp  = null;
        let found = release_series.filter((r) => (r.release === release_id && r.page === page));
        if (found.length > 0) {
            found = found[0];
            resp = {series: found.values};

            limit   = found.limit;
            page    = found.page;
            maxpage = found.maxpage;
        }
        else {
            try {
                document.body.style.cursor = "wait";
                resp = await apiCalls.fredReleaseSeries(release_id, limit, page, {signal:this.controller.signal});
                document.body.style.cursor = "";
            }
            catch {
                document.body.style.cursor = "";
            }
            if (!resp) {
                return;
            }
            if (!resp.series || resp.series.length < 1) {
                this.props.addError(`No FRED series available for release ${release_id}`, 'alert-danger');
                this.series = [];
                return;
            }
            if (typeof resp.limit !== 'undefined') {
                limit    = resp.limit;
                //page     = 1 + Math.floor((resp.series.length-1) / resp.limit);
                maxpage  = 1 + Math.floor(resp.count / resp.limit);
            }
            await this.props.addReleaseSeries(release_id, limit, page, maxpage, resp.series);
        }

        this.series = resp.series;
        this.series_page_fn = this.loadReleaseSeries;
        await this.setState({limit, page, maxpage});

		await this.loadSeries();
	}

	async loadSeries() {

        let {series_id} = this.state;
        if (series_id === '') {
            if (this.series.length > 0) {
                series_id = this.series[0].id;
            }
        }
        let seriesInfo = await loadFredSeries(
            series_id,
            {series:this.props.fredcache.series, addSeries: this.props.addSeries}, 
            this.props.addError, 
            this.controller
        );
        if (!seriesInfo) {
            return;
        }
		this.setState({
			series_id: series_id, 
			series_title: seriesInfo.title,
			observation_start: seriesInfo.observation_start,
			observation_end: seriesInfo.observation_end,
			frequency: seriesInfo.frequency.toLowerCase(),
            import_as: series_id, 
            series_changed: false,
            series_imported: false
		});
	}

	async handleChange(event) {
        this.props.removeError();
        // await is important to set id before calling loadSourceReleases/loadReleaseSeries/loadSeries
		await this.setState({[event.target.name]: event.target.value, series_imported: false});
		switch(event.target.name) {
			case 'source':
				this.loadSourceReleases();
				break;
			case 'release':
				this.loadReleaseSeries();
				break;
			case 'series_id':
                this.loadSeries();
				break;
            case 'page':
                if (this.series_page_fn) {
                    this.series_page_fn();
                }
                break;
			default:
		}
	}

	async handleCategoryChange(event) {
		await this.setCategory(event.target.value, true);
	}

	async handleCategoryUp(event) {
		await this.setCategory(this.state.category_parent, true);
	}

	async handleSubmit(event) {
		let {series_id, frequency, observation_start, observation_end, import_as} = this.state;
        let series = await importFredSeries(
            series_id, 
            frequency, 
            observation_start, 
            observation_end, 
            import_as,
            this.props.addError,
            this.controller
        );
        if (!series) {
            return;
        }
        this.props.importSeries(series.import_as, series.timeformat, 
            series.timeindex, series.timeutc, series.timestamps, series.observations);
            this.setState({series_imported: true});
	}

	render() {
		const {series_title, series_id, observation_start, observation_end, frequency,
            apiinfo, apiurl, maxpage, import_as, series_imported} = this.state;

        let frequencyOptions = [];
        if (this.timeformat) {
		    frequencyOptions = this.timeformat.map((tf, id) =>
                <option key={id} value={tf.id}>{tf.name}</option>);
        }

        let categoryOptions = [];
        if (this.category_children) {
            categoryOptions = this.category_children.map((s) =>
                <option key={s.id} value={s.id}>{s.name}</option>);
        }
        categoryOptions.unshift(<option key={0} value={0}>{'<select>'}</option>);

        let releaseOptions = [];
        if (this.releases) {
		    releaseOptions = this.releases.map((s) =>
                <option key={s.id} value={s.id}>{s.name}</option>);
        }

        let sourceOptions = [];
        if (this.sources) {
            sourceOptions = this.sources.map((s) =>
                <option key={s.id} value={s.id}>{s.name}</option>);
        }

        let seriesOptions = [];
        if (this.series) {
            seriesOptions = this.series.map((s) => <option key={s.id}>{s.id}</option>);
        }

        var {category_name} = this.state;
        if (!category_name || category_name === 'Categories') {
            category_name = '';
        }

        let pageOptions = [];
        if (maxpage > 1) {
            pageOptions = new Array(maxpage);
            for (var i = 1; i <=maxpage; i++) {
                pageOptions[i-1] = <option key={i} value={i}>{'page '+i}</option>;
            }
        }

        // className="g-1": in bootstrap, g stands for gutter

		return (
			<Container className="mt-2"  fluid>

                {apiinfo ? 
                    <Card className="m-1 notification" style={{'backgroundColor': 'whitesmoke'}}>
                        {apiinfo} 
                        <a href={apiurl} target="_blank" rel="noreferrer noopener">{apiurl}</a>
                    </Card>: ''
                }

                <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Source</Card.Header>
                            <Card.Body className="card-body">
                                <select className="form-control" id="source" name="source" 
                                    onChange={this.handleChange}>
                                    {sourceOptions}
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Category: {category_name}</Card.Header>
                            <Card.Body className="card-body">
                                <Row>
                                    <Col>
                                    <select className="form-control" id="category" name="category" 
                                        onChange={this.handleCategoryChange} >
                                        {categoryOptions}
                                    </select>
                                    </Col>
                                    <Col>
                                    <Button variant="secondary" className="secondary-button" size="sm" id="categories-up" onClick={this.handleCategoryUp}>
                                        Up
                                    </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

				<Row className="g-1">
                    <Col>   
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Release</Card.Header>
                            <Card.Body className="card-body">
                                <select className="form-control" id="release" name="release" 
                                    onChange={this.handleChange}>
                                    {releaseOptions}
                                </select>
                            </Card.Body>
                        </Card>
					</Col>

                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">Series</Card.Header>
                            <Card.Body className="card-body">
                                <Row className="g-1">
                                    <Col xs="8">
                                    <select className="form-control" name="series_id" onChange={this.handleChange}>
                                        {seriesOptions}
                                    </select>
                                    </Col>
                                    <Col xs="4">
                                    <select className="form-control" name="page" onChange={this.handleChange}>
                                        {pageOptions}
                                    </select>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
				</Row>

                <Row className="g-1">
                    <label className="series-title" area-label="Series title">
                        {series_title}
                    </label>
                </Row>

		        <Row className="g-1">
                    <Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Start
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control" 
                                    name = "observation_start" 
                                    value={observation_start} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
                    </Col>
		        	<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                End
                            </Card.Header>
                            <Card.Body className="card-body">
		        		        <input type="text" className="form-control"
                                    name = "observation_end" 
		        			        value={observation_end} onChange={this.handleChange} />
                            </Card.Body>
                        </Card>
					</Col>
					<Col>
                        <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Frequency
                            </Card.Header>
                            <Card.Body className="card-body">
                                <select className="form-control" name="frequency" 
                                    onChange={this.handleChange} value={frequency}>
                                    {frequencyOptions}
                                </select>
                            </Card.Body>
                        </Card>
		        	</Col>
                    <Col>
                        {series_id !== '' ? 
                            <Card className="m-1">
                            <Card.Header className="form-control-label">
                                Import as
                            </Card.Header>
                            <Card.Body className="card-body">
                                <input type="text" className="form-control"
                                    name = "import_as" 
		        			        value={import_as} onChange={this.handleChange} />
                            </Card.Body>
                            </Card>
                            : '' 
                        }
                    </Col>
                </Row>
                {import_as !== '' & !series_imported ? 
                    <Button className="mt-2" variant="primary" id="categories-up" 
                        onClick={this.handleSubmit}>
                        Submit
                    </Button>
                    : '' 
                }
			</Container>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {fredcache: state.fredcache};
}

export default connect(mapStateToProps, {addError, removeError, addSources, addReleases, addCategory, 
    addSeries, addCategorySeries, addReleaseSeries})(FredForm);