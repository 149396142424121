import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Accordion, Table, Card, Button, Col, Row, InputGroup, FormControl} from 'react-bootstrap';

import {addError, removeError} from '../store/actions/errors';
import {MODEL_STATUS_COMPILED, MODEL_STATUS_SAMPLED, MODEL_STATUS_UPDATED} from '../store/actionTypes';
import {setModelStatus, updateModel, setMcmcSample, setFcastSample, setIrfSample, setEstimateSize} 
    from '../store/actions/model';

const COEFSUMMARY_ID  = 'coefsummary';

class ModelSample extends Component {
	constructor(props) {
		super(props);
		// varlabels, varfacts and varlogs are of this.props.variables' length
		this.state = {
			start:         0,
            end:           0,
            burnin:      500,
			mcmcsize:   1000,
            estsize:       0,
			params:     null,
            committed: false,
		};
		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
	}

    componentDidMount() {
        let {estsize, params, minupdatesize} = this.props.model.loglik;
        let {dataset, postmodel} = this.props.model;

        /* eslint-disable no-undef */
        describe_deleteRows(COEFSUMMARY_ID);
        /* eslint-enable no-undef */

        this.props.removeError();

        if (!dataset) {
            let alert = `No dataset is available.`;
            this.props.addError(alert, 'alert-danger');
            return;
        }
        let n = dataset.length();
        if (minupdatesize > n || n < 1) {
            let alert = `At least ${minupdatesize} data points are required. Compiled dataset is only of length ${n}.`;
            this.props.addError(alert, 'alert-danger');
            return;
        }
        var start = dataset.start();
        var end   = dataset.end();
        if (postmodel) {
            start = postmodel.updateStart();
            end   = postmodel.updateEnd()-1;
        }
        this.setState({start, end, estsize, params});
	}

    handleChange(event) {
        var value = event.target.value;
        var alert = null;

        this.props.removeError();

        if (event.target.name === 'start' || event.target.name === 'end') {
            let {minupdatesize} = this.props.model.loglik;
            let {start, end} = this.state;
            let {dataset} = this.props.model;
            value = value.replace(/\D/g,'');
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value < 0) {
                alert = `${event.target.name} cannot be negative`;
                this.props.addError(alert, 'alert-danger');
            }
            let tmax = dataset.length() - 1;
            if (value > tmax) {
                alert = `${event.target.name} cannot be greater than ${tmax}`;
                this.props.addError(alert, 'alert-danger');
            }
            if (event.target.name === 'start') {
                if (value > end-minupdatesize+1) {
                    alert = `${event.target.name} must be less than ${end-minupdatesize+2}`;
                    this.props.addError(alert, 'alert-danger');
                }
            }
            if (event.target.name === 'end') {
                if (value < start+minupdatesize-1) {
                    alert = `${event.target.name} must be greater than ${start+minupdatesize-2}`;
                    this.props.addError(alert, 'alert-danger');
                }
            }
        }
        if (event.target.name === 'burnin' || event.target.name === 'mcmcsize') {
            value = value.replace(/\D/g,'');
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value <= 0) {
                alert = `${event.target.name} must be greater than 0`;
                this.props.addError(alert, 'alert-danger');
            }
            if (value > 20000) {
                alert = `${event.target.name} is limited to 20000`;
                this.props.addError(alert, 'alert-danger');
            }
        }
        this.setState({[event.target.name]: value});
	}

	async posteriorSample() {
        let {classname, cparams, params, priors, estsize} = this.props.model.loglik;
        let {dataset} = this.props.model;
        let {start, end, burnin, mcmcsize} = this.state;
        var alert = null;
        
        var endogenous, exogenous, dependent, independent, responses, eqvars, timevar;
        var lags, lambdas, rank, mineff, acc;

        var postmodel  = null;
		var mcmcsample = null;

        var newestsize = estsize + end - start + 1;

        if (burnin <= 0) {
            alert = 'Burnin must be positive.';
            this.props.addError(alert, 'alert-danger');
            return;
        }
        if (mcmcsize <= 0) {
            alert = 'MCMC size must be positive.';
            this.props.addError(alert, 'alert-danger');
            return;
        }
        
		/* eslint-disable no-undef */

		switch (classname) {
            case "MVN":
			case "BMVN":
                if (!priors || priors.length < 2) {
                    alert = `Invalid priors of ${classname} model.`;
                    this.props.addError(alert, 'alert-danger');
                    break;
                }

                dependent   = dataset.varnames_bylabel('dependent');
                independent = dataset.varnames_bylabel('independent');
                timevar     = dataset.varnames_bylabel('time');

                postmodel = new BMVN();
                try {
                    postmodel.setup_dataset(dataset, [dependent, independent, timevar], 
                        cparams, params, start, end);
                }
                catch(err) {
                    this.props.addError(err.message, 'alert-danger');
                    return;
                }
                break;

            case "SUR":
			case "BSUR":
                if (!priors || priors.length < 2) {
                    alert = `Invalid priors of ${classname} model.`;
                    this.props.addError(alert, 'alert-danger');
                    break;
                }

                responses = dataset.varnames_bylabel('dependent');

                eqvars = responses.map((depvar,i) => 
                    dataset.varnames_bylabel(`eq${i+1} regressors`)
                );
                // attach responses at the beginning of each equation
                responses.forEach((depvar,i) => {
                    eqvars[i].unshift(depvar);
                });

                timevar = dataset.varnames_bylabel('time');
                eqvars.push(timevar);

                postmodel = new BSUR();
                try {
                    postmodel.setup_dataset(dataset, eqvars, cparams, params, start, end);
                }
                catch(err) {
                    this.props.addError(err.message, 'alert-danger');
                    return;
                }
                break;

            case "VAR":
			case "BVAR":
                if (!cparams || cparams.length < 2) {
                    alert = 'Invalid parameters of VAR model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }
                lags = cparams[0].value[0];
                if (lags < 1 || lags > 100) {
                    alert = 'Invalid lags in VAR model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }
                lambdas = cparams[1].value;
                if (lambdas.length !== 4) {
                    alert = 'Invalid lambdas in VAR model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }
                if (!priors || priors.length < 2) {
                    alert = `Invalid priors of ${classname} model.`;
                    this.props.addError(alert, 'alert-danger');
                    break;
                }

                endogenous = dataset.varnames_bylabel('endogenous');
                exogenous  = dataset.varnames_bylabel('exogenous');
                timevar    = dataset.varnames_bylabel('time');

                postmodel = new BVAR();
                try {
                    postmodel.setup_dataset(dataset, [endogenous, exogenous, timevar], 
                        cparams, params, start, end);
                }
                catch(err) {
                    this.props.addError(err.message, 'alert-danger');
                    return;
                }
                break;

			case "VEC":
			case "BVEC":
                if (!cparams || cparams.length < 2) {
                    alert = 'Invalid parameters of VEC model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }
                lags = cparams[0].value[0];
                if (lags < 1 || lags > 100) {
                    alert = 'Invalid lags in VEC model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }
                if (!priors || priors.length < 2) {
                    alert = `Invalid priors of ${classname} model.`;
                    this.props.addError(alert, 'alert-danger');
                    break;
                }

                endogenous = dataset.varnames_bylabel('endogenous');
                exogenous  = dataset.varnames_bylabel('exogenous');
                timevar    = dataset.varnames_bylabel('time');

                rank = cparams[1].value;
                if (rank < 1 || rank >= endogenous) {
                    alert = 'Invalid rank in VEC model.';
                    this.props.addError(alert, 'alert-danger');
                    break;
                }

                postmodel = new BVEC();
                try {
                    postmodel.setup_dataset(dataset, [endogenous, exogenous, timevar], 
                        cparams, params, start, end);
                }
                catch(err) {
                    this.props.addError(err.message, 'alert-danger');
                    return;
                }
                break;

			default:
                alert = `Invalid model ${classname}.`;
                this.props.addError(alert, 'alert-danger');
		}
		if (!postmodel) {
			return null;
		}

        // check poster against vars
        // check poster against params

        try {
            postmodel.setup_posterior(cparams, priors);

            mcmcsample = postmodel.sample(burnin, mcmcsize);
        }
        catch(err) {
            alert = 'Runtime error: ' + err.message;
            this.props.addError(alert, 'alert-danger');
            return;
        }
        
        acc = postmodel.acceptance();

        mcmcsample.mean_var()
        mcmcsample.mcse_ess();

        mineff = mcmcsample.min_eff();

        // params vector is from this.props.model.loglik - do not update it directly
        var newparams = params.map(p => {return {...p}});
        mcmcsample.mean_var_update(newparams);

		if (mineff > 0.5) {
			alert = 'Excellent efficiency. You can submit your updated model by clicking "Update model".';
			this.props.addError(alert, 'alert-primary');
		}
		else if (mineff > 0.25) {
			alert = 'Good efficiency. You can submit your updated model by clicking "Update model".';
			this.props.addError(alert, 'alert-success');
		}
		else if (mineff > 0.05) {
			alert = 'Weak efficiency';
			this.props.addError(alert, 'alert-warning');
		}
		else {
            newestsize = 0;
			alert = 'Poor efficiency';
            this.props.addError(alert, 'alert-danger');
		}

        if (newestsize > 0 && acc > 0.5) {
            this.props.setModelStatus(MODEL_STATUS_SAMPLED);
        }
        else {
            this.props.setModelStatus(MODEL_STATUS_COMPILED);
        }

        this.props.setMcmcSample(mcmcsample, postmodel);
        this.props.setFcastSample(null);
        this.props.setIrfSample(null);

        this.setState({estsize: newestsize, params: newparams, committed: false});

		/* eslint-enable no-undef */
	}

	async handleSubmit(event) {
        const {status} = this.props.model;

        this.props.removeError();
        var alert = null;
        if (status < MODEL_STATUS_COMPILED) {
            alert = 'You must compile a dataset first.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        this.posteriorSample();
	}

    async handleUpdate(event) {
        const {user_id, id} = this.props;
        const {status} = this.props.model;
        let {estsize, params} = this.state;

        this.props.removeError();
        var alert = null;
        if (status !== MODEL_STATUS_SAMPLED) {
            alert = 'You must update the model first.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        this.props.updateModel({_id:id, estsize, params}, user_id);

        this.props.setModelStatus(MODEL_STATUS_UPDATED);
        this.props.setMcmcSample(null);
        this.props.setEstimateSize(estsize);

        this.setState({committed: true});
	}

	render() {
		const {start, end, burnin, mcmcsize, estsize} = this.state;
        const {status, mcmcsample} = this.props.model;

        var compiled = status >= MODEL_STATUS_COMPILED;
        var updated  = status !== MODEL_STATUS_SAMPLED;

		var coeftable = status ? 
            <Table striped bordered hover responsive>
				<thead>
				<tr>
					<th scope="col"></th>
					<th scope="col">Mean</th>
					<th scope="col">Std. dev.</th>
					<th scope="col">MCSE</th>
					<th scope="col">ESS</th>
				</tr>
				</thead>
                <tbody id={COEFSUMMARY_ID}>
                </tbody>
			</Table> : '';

		if (mcmcsample) {
			/* eslint-disable no-undef */
            describe_deleteRows(COEFSUMMARY_ID);
            mcmcsample.summary_table(describe_addRow, COEFSUMMARY_ID);
			/* eslint-enable no-undef */
		}

		return (
			<Container className="text-center">

                <Row className="m-2 flex-row">
                    <Col key={1}>
                        <Card className="m-1">
                            <Card.Header>Dataset range</Card.Header>
                            <Card.Body className="p-2">
                                <Row>
                                <Col>
                                    <InputGroup>
                                    <InputGroup.Text>Start</InputGroup.Text>
                                    <FormControl type="text" className="form-bordercontrol" name='start'
                                        value={start} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                    <InputGroup.Text>End</InputGroup.Text>
                                    <FormControl type="text" className="form-bordercontrol" name='end'
                                        value={end} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col key={2}>
                        <Card className="m-1">
                            <Card.Header>MCMC</Card.Header>
                            <Card.Body className="p-2">
                                <Row>
                                <Col>
                                    <InputGroup>
                                    <InputGroup.Text>Burnin</InputGroup.Text>
                                    <FormControl type="text" className="form-bordercontrol" name='burnin'
                                        value={burnin} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                    <InputGroup.Text>Size</InputGroup.Text>
                                    <FormControl type="text" className="form-bordercontrol" name='mcmcsize'
                                        value={mcmcsize} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                <Row className="m-4">
                    <Col key={3}>
                    {compiled ? 
                        <Button variant="primary"   onClick={this.handleSubmit}>Fit model</Button> : 
                        <Button variant="secondary" onClick={this.handleSubmit}>Fit model</Button>
                    }
                    </Col>
                    <Col key={4}>
                    {updated || estsize <= 0 ? 
                        <Button variant="secondary" disabled>Update model</Button> :
                        <Button variant="primary" onClick={this.handleUpdate}>Update model</Button>
                    }
                    </Col>
                </Row>

                <Accordion defaultActiveKey="0">
                    <Accordion.Item className="m-1" key="0">
                        <Accordion.Header>
                            {status === MODEL_STATUS_SAMPLED ? 
                                <div style={{'fontSize':'20px', 'width':'40%', 'margin':'auto', 'textAlign':'right'}}>
                                    Parameter estimates
                                </div> : ''}
                        </Accordion.Header>
                        <Accordion.Body className="p-2">
                            {coeftable}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

			</Container>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
	return {user_id, id, errors: state.errors, model: state.model};
}

export default connect(mapStateToProps, 
    {addError, removeError, setModelStatus, updateModel, setMcmcSample, 
    setFcastSample, setIrfSample, setEstimateSize})(ModelSample);
