import React, { Component } from 'react';
import {connect} from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, useParams, Navigate} from "react-router-dom";

import {FaTimes} from 'react-icons/fa';

import * as apiCalls from '../services/api';

import ModelDescription from '../components/ModelDescription';
import {logout} from '../store/actions/auth';
import {addError} from '../store/actions/errors';
import ModelForm from './ModelForm';
import ModelShow from './ModelShow';
import AuthForm  from './AuthForm';

class Main extends Component {

    static loadedscripts = [];

    constructor(props) {
        super(props);
        this.state = {
            scripts: [],
            bmodels: [],
            modelnames: []
        }
        this.loadModels = this.loadModels.bind(this);
        this.addModel   = this.addModel.bind(this);
        this.modelNames = this.modelNames.bind(this);
    }

    checkDeclaration(funcname) {
        return ((new Function('return (typeof ' + funcname + ')'))() === 'function');
    }

    async componentDidMount() {
        const {addError} = this.props;
        try {
            document.body.style.cursor = "wait";
            let scripts = await apiCalls.loadBaseScripts();
            document.body.style.cursor = "";
            scripts.forEach((model) => {
                const { scriptname, classname, scriptjs } = model;
                if (Main.loadedscripts.indexOf(scriptname) < 0) {
                    var scripttag = document.createElement('script');
                    scripttag.text = scriptjs;
                    scripttag.type = 'text/javascript';
                    scripttag.async = true;
                    // add classname javascript to dom.body
                    if (!this.checkDeclaration(classname)) {
                        document.body.appendChild(scripttag);
                    }
                    //console.log("base script", classname, scriptname, scripttag);
                    Main.loadedscripts.push(scriptname);
                }
            });
            /*eslint no-new-func: 0*/
            this.setState({scripts});

            this.loadModels();
        }
        catch (err) {
            //let msg = err.message;
            let msg = 'Server not responding!';
            addError(msg, 'alert-danger');
        }
    }

    addModelScript(model) {
        const {scriptname, classname, scriptjs} = model;
        if (Main.loadedscripts.indexOf(scriptname) < 0) {
            var scripttag = document.createElement('script');
            scripttag.text = scriptjs;
            scripttag.type = 'text/javascript';
            scripttag.async = true;
            //console.log("classname", classname, scriptname, scripttag);
            // add classname javascript to dom.body
            if (!this.checkDeclaration(classname)) {
                document.body.appendChild(scripttag);
            }
            Main.loadedscripts.push(scriptname);
        }
    }

    async loadModels() {

        const {currentUser, addError} = this.props;
        if (!currentUser.isAuthenticated) {
            return;
        }
        try {
            document.body.style.cursor = "wait";
            let bmodels  = await apiCalls.loadModels(
                currentUser.user.id, localStorage.getItem("jwtToken"));
            document.body.style.cursor = "";
            if (!bmodels || !bmodels.length) {
                return;
            }
            bmodels.forEach((model) => this.addModelScript(model));
            /*eslint no-new-func: 0*/
            let modelnames = bmodels.map(model => model.name);
            this.setState({bmodels, modelnames});
        }
        catch (err) {
            var msg = '';
            if (err.status && err.status === 401) {
                this.props.logout();
                msg = 'Unauthorized access! ';
            }
            addError(msg + err.message, 'alert-danger');
        }
        //if (this.state.bmodels.length > 0) {
        //    addError('This is the list of your existing models, click on one to continue or create a new model.', 'alert-success');
        //}
        //else {
        //    addError('Create a new model.', 'alert-success');
        //}
    }

    async addModel(model) {
        const {currentUser, addError} = this.props;
        if (!currentUser.isAuthenticated) {
            addError('Please sign in first.', 'alert-warning');
            return;
        }
		// in order to have model argument you need to bind it
		// this.addModel = this.addModel.bind(this)
        try {
            document.body.style.cursor = "wait";
		    let newModel = await apiCalls.addModel(model, 
                currentUser.user.id, localStorage.getItem("jwtToken"));
            document.body.style.cursor = "";
            this.addModelScript(newModel);
		    this.setState({bmodels: [...this.state.bmodels, newModel]});
        }
        catch (err) {
            var msg = '';
            if (err.status && err.status === 401) {
                this.props.logout();
                msg = 'Unauthorized access! ';
            }
            addError(msg + err.message, 'alert-danger');
        }
	}

    async deleteModel(id, name) {
        const {currentUser, addError} = this.props;
        if (!currentUser.isAuthenticated) {
            addError('Please sign in first.', 'alert-warning');
            return;
        }
        if(!window.confirm(`Are you sure you want to delete ${name}?`)) {
            return;
        }
        try {
            document.body.style.cursor = "wait";
		    await apiCalls.deleteModel(id, 
                currentUser.user.id, localStorage.getItem("jwtToken"));
            document.body.style.cursor = "";
		    const newModels = this.state.bmodels.filter(model => model._id!==id);
		    this.setState({bmodels: newModels});
        }
        catch (err) {
            var msg = '';
            if (err.status && err.status === 401) {
                this.props.logout();
                msg = 'Unauthorized access! ';
            }
            addError(msg + err.message, 'alert-danger');
        }
	}

    modelNames() {
        return(this.state.modelnames);
    }

    render() {
        let {errors, currentUser} = this.props;
        var bmodels = [];
        let ml600 = window.matchMedia('(max-width: 600px)').matches;
        if (currentUser.isAuthenticated) {
            bmodels = this.state.bmodels.map((model, i) => 
                (ml600 ? 
                <div className='bmodel-item' key={i}>
                    <Link to={`/${currentUser.user.id}/models/${model._id}`} className="bmodel-link">
                        {model.name}
                    </Link>
                    <button onClick={this.deleteModel.bind(this, model._id, model.name)} className="bmodel-delete">
			            <FaTimes size={16} color='#8b0020'/>
		            </button>
                </div> :
                <div className='bmodel-item' key={i}>
                    <Link to={`/${currentUser.user.id}/models/${model._id}`} className="bmodel-link">
                        <h4>{model.name}</h4>
                    </Link>
                    <Link to={`/${currentUser.user.id}/models/${model._id}`} className="bmodel-link">
                        <ModelDescription loglik={model} short={true} hasname={false} />
                    </Link>
                    <button onClick={this.deleteModel.bind(this, model._id, model.name)} className="bmodel-delete">
			            <FaTimes size={28} color='#8b0020'/>
		            </button>
                </div>)
            );
        }
        bmodels = 
            <div className="bmodel-list">
                <div className="text-center">
                    {errors.message && (<div className={"m-4 alert "+errors.style}> {errors.message} </div>)}
                </div>
                {bmodels}
            </div>;
        return(
            <Router>
                <Routes>
                    <Route exact path='/'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/models`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/models'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/models`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/new'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/models/new`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/signup'
                        element = {
                            currentUser.isAuthenticated ? '' : 
                            <AuthForm 
                                signUp
                                buttonText="Sign me up" 
                                heading="Join today"
                                callback={null}
                            />
                        } 
                    />
                    <Route exact path='/signin' 
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to='/:user_id/models' /> : 
                            <AuthForm 
                                buttonText="Log in" 
                                heading="Welcome back"
                                callback={this.loadModels}
                            />
                        }
                    />
                    <Route exact path='/account'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/account`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/contact'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/contact`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/about'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/about`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                    <Route exact path='/:user_id/models'
                        element = {
                            currentUser.isAuthenticated ? bmodels : 
                            <Navigate to='../../signin' />
                        }
                    />
                    <Route exact path='/:user_id/models/new' 
                        element = {
                            currentUser.isAuthenticated ? 
                            <NewModel addModel={this.addModel} modelNames={this.modelNames} /> : 
                            <Navigate to='../../signin' />
                        }
                    />
                    <Route exact path='/:user_id/models/:id' 
                        element = {
                            currentUser.isAuthenticated ? 
                            <ViewModel /> : <Navigate to='../../../signin' />
                        }
                    />
                    <Route exact path='/:user_id/account'
                        element = {
                            currentUser.isAuthenticated ? 
                            <AuthForm 
                                accountReset
                                buttonText="Submit changes"
                                heading="Your account"
                                callback={logout}
                            /> : ''
                        }
                    />
                    <Route exact path='/:user_id/contact' 
                        element = {
                        <div style={{'fontSize':'20px', 'margin':'18px', 'textAlign': 'center'}}>
                            {'Please write to '}
                            <a href = "mailto: techsupport@bayeswork.com">techsupport@bayeswork.com</a> 
                            {' with questions and suggestions.'}
                        </div>}
                    />
                    <Route exact path='/:user_id/about' 
                        element = {
                        <div className='about' style={{'fontSize':'20px', 'margin':'18px', 'textAlign': 'center'}}>
                            <h2>This application provides Bayesian models for multiple timeseries.</h2>
                            <br></br>
                            <h3>It follows a model-based approach where a Bayesian model is constantly updated using data.</h3>
                            <br></br>
                            <h3>The workflow involves the following steps:</h3>
                            <div style={{'padding': '24px', 'textAlign': 'left'}}>
                                <div>1. Open an existing model or create a new one from a list of available statistical models.</div>
                                <div>2. Import some timeseries and link them to the variable names of the model.</div>
                                <div>3. Fit the model to obtain posterior estimates for model parameters.</div>
                                <div>4. Update model priors using the last posterior estimates and save it in the database.</div>
                                <div>5. Perform predictions based on the last fitted model.</div>
                            </div>
                        </div>}
                    />
                    <Route exact path='*'
                        element = {
                            currentUser.isAuthenticated ? 
                            <Navigate to={`/${currentUser.user.id}/models`} /> : 
                            <Navigate to='/signin' />
                        }
                    />
                </Routes>
            </Router>
        );
    }
}

function ViewModel() {
    // hook for extracting id from path '/:id'
    let { user_id, id } = useParams();
    return <ModelShow user_id={user_id} id={id} />;
}

function NewModel(props) {
    // hook for extracting id from path '/:id'
    let { user_id } = useParams();
    return <ModelForm user_id={user_id} {...props} />;
}

function mapStateToProps(state) {
	return {errors: state.errors, currentUser: state.currentUser};
}

// in order to have this.props.history export withRouter 
export default connect(mapStateToProps, {addError, logout})(Main);
