import {SET_CURRENT_USER} from '../actionTypes';

const SESSION_INTERVAL = 60*60; // 1hr

const DEFAULT_STATE = {
	isAuthenticated: false,
	user: {}
};

// current user reducer
const currentUserFunc = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
		case SET_CURRENT_USER:
            if (Object.keys(state.user).length) {
                let lastLogin = localStorage.getItem("lastLogin");
                let session = (Date.now()-Date.parse(lastLogin))/1000;
                if (session > SESSION_INTERVAL) {
                    if(!window.confirm('Session expired. Dow you want to stay?')) {
                        // force logout
                        action.user = {};  
                    }
                    localStorage.setItem("lastLogin", new Date().toJSON());
                }
            }
            return {
				isAuthenticated: !!Object.keys(action.user).length,
				user: action.user
			};
        default:
			return state;
	}
}

export default currentUserFunc;