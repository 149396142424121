import {EIA_ADD_ROUTE, EIA_ADD_FACET_VALUES, EIA_LAST_ROUTE} from '../actionTypes';

// action creators

export function addRoute(path, children, data, facets, defaultFrequency, frequency) {
	return {
		type: EIA_ADD_ROUTE,
		path,
        children,
        data, 
        facets, 
        defaultFrequency, 
        frequency
	}
}

export function addFacet(route_id, facet_id, values) {
	return {
		type: EIA_ADD_FACET_VALUES,
        route_id,
		facet_id,
        values
	}
}

export function setLastRoute(route_id, route_name) {
	return {
		type: EIA_LAST_ROUTE,
        route_id,
        route_name
	}
}