import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Col, Row, Tab, Tabs, InputGroup, FormControl} from 'react-bootstrap';

import McmcPlots from './McmcPlots';
import {addError, removeError} from '../store/actions/errors';
import {MCMC_PLOTS_IRF, MCMC_PLOTS_OIRF, MCMC_PLOTS_DM} from './McmcPlots';
import {MODEL_STATUS_SAMPLED} from '../store/actionTypes';
import {setIrfSample, setOirfSample, setDmSample} from '../store/actions/model';
import {parseNumber, parsePositiveNumber} from './utils';

class IrfSample extends Component {
    
    static KEY_IRF  = 1;
    static KEY_OIRF = 2;
    static KEY_DM   = 3;

	constructor(props) {
		super(props);
		this.state = {
            keytab:     IrfSample.KEY_IRF,
			timesteps:  8,
            crilevel:   0.1,
            updated:    false,
		};
		this.handleChange    = this.handleChange.bind(this);
        this.handleUpdate    = this.handleUpdate.bind(this);
        this.handleSelectTab = this.handleSelectTab.bind(this);
	}

    componentDidMount() {
        const {irfsample} = this.props.model;
        const {postmodel} = this.props.model;
        if (irfsample) {
            this.setState({
                timesteps: irfsample.timesteps(), 
                crilevel:  irfsample.crilevel(), 
            });
        }
        else if (postmodel) {
            this.setState({timesteps: 8});
        }
        setTimeout(() => this.handleUpdate(), 50);
	}

    handleChange(event) {
        var value = event.target.value;
        var alert = '';

        this.props.removeError();

        if (event.target.name === 'timesteps') {
            value = parseNumber(value);
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value > 100) {
                alert = `${event.target.name} is limited to 100.`;
            }
            else if (value <= 0) {
                alert = `${event.target.name} must be greater than 0.`;
            }
        }
        if (event.target.name === 'crilevel') {
            value = parsePositiveNumber(value);
            if (value >= 0.5) {
                alert = `Credible interval level must be less than 0.5.`;
            }
            else if (value <= 0) {
                alert = `Credible interval level must be greater than 0.`;
            }
            else {
                value = parseFloat(value);
                if (isNaN(value)) {
                    alert = `Credible interval level must be a number.`;
                }
            }
        }

        if (alert !== '') {
            this.props.addError(alert, 'alert-danger');
        }

        this.setState({[event.target.name]: value, updated: false});

        if (alert === '') {
            setTimeout(() => this.handleUpdate(), 100);
       }
	}

    handleUpdate() {
        const {status, postmodel, mcmcsample, irfsample} = this.props.model;
        let {timesteps, crilevel} = this.state;

        var alert = '';

        this.props.removeError();

        if (status < MODEL_STATUS_SAMPLED) {
            alert = 'You must fit the model first.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        if (irfsample) {
            if (irfsample.timesteps() === timesteps && 
                irfsample.crilevel()  === crilevel) {
                this.setState({updated: true});
                return;
            }
        }

        if (crilevel <= 0 || crilevel >= 0.5) {
            alert = `Invalid crilevel of ${crilevel}.`;
            this.props.addError(alert, 'alert-danger');
            return;
        }

        var fsample = postmodel.irfs(mcmcsample, timesteps, crilevel);

        this.props.setIrfSample(fsample[0]);
        this.props.setOirfSample(fsample[1]);
        this.props.setDmSample(fsample[2]);

        this.setState({updated: true});
	}

    handleSelectTab(keytab) {
        if (Number(keytab) >= IrfSample.KEY_IRF && Number(keytab) <= IrfSample.KEY_DM) {
            this.props.removeError();
		    this.setState({keytab});
        }
	}

	render() {
        const {keytab, timesteps, crilevel, updated} = this.state;
        return (
            <Container className="text-center" onSelect={this.handleSelectTab}>

                <Row className="form-postestimation">
                    <Col key={1}>
                        <InputGroup>
                        <InputGroup.Text className="form-control-label">Steps</InputGroup.Text>
                        <FormControl type="text" className="form-bordercontrol" name='timesteps'
                            value={timesteps} onChange={this.handleChange} />
                        </InputGroup>
                    </Col>
                    <Col key={2}>
                        <InputGroup>
                        <InputGroup.Text className="form-control-label">Level</InputGroup.Text>
                        <FormControl type="text" className="form-bordercontrol" name='crilevel'
                            value={crilevel} onChange={this.handleChange} />
                        </InputGroup>
                    </Col>
                </Row>
                
                <Row style={{'width': '75%', 'margin': 'auto', 'paddingTop': '2em', 
                    'fontSize': '1.25em'}}>
                    <Tabs activekey={keytab} onSelect={this.handleSelectTab} className="mb-4" fill
                    >
                    <Tab eventKey={IrfSample.KEY_IRF}  title="IRFs">
                    </Tab>
                    <Tab eventKey={IrfSample.KEY_OIRF} title="OIRFs">
                    </Tab>
                    <Tab eventKey={IrfSample.KEY_DM}   title="DMs">
                    </Tab>
                    </Tabs>
                </Row>

                {updated & Number(keytab) === IrfSample.KEY_IRF ? 
                    <McmcPlots type={MCMC_PLOTS_IRF}/> : ''
                }
                {updated & Number(keytab) === IrfSample.KEY_OIRF ? 
                    <McmcPlots type={MCMC_PLOTS_OIRF}/> : ''
                }
                {updated & Number(keytab) === IrfSample.KEY_DM ? 
                    <McmcPlots type={MCMC_PLOTS_DM}/> : ''
                }

			</Container>
        );
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
	return {model: state.model, user_id, id};
}

export default connect(mapStateToProps, {addError, removeError, setIrfSample, setOirfSample, setDmSample})(IrfSample);