import React, { Component } from "react";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import {authUser} from "../store/actions/auth";
import {addError} from '../store/actions/errors';
import {load as recaptcha_load} from 'recaptcha-v3';

const RECAPTCHA_KEY = '6Le2Yc0oAAAAAP9YOFqHI9bV-mcQiGC2f2g9EPBd';

class AuthForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:           "",
            username:        "",
            password:        "",
            password2:       "",
            profileImageUrl: "",
            displaynone:     "",
            loginFailed:     false,
            accountChanged:  false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.recaptcha    = null;
    }

    async componentDidMount() {
        const {currentUser, accountReset} = this.props;
		if (accountReset && currentUser.isAuthenticated) {
            this.setState({
                email:           currentUser.user.email,
                username:        currentUser.user.username,
                profileImageUrl: currentUser.user.profileImageUrl,
            });
        }
        // Google enterprise reCAPTCHA loader
        this.recaptcha = await recaptcha_load(
            RECAPTCHA_KEY, 
            {
                useEnterprise: true,
            }
        );
	}

    handleChange(event) {
        const {signUp, addError} = this.props;
        // computed property name [e.target.name]
        var value = event.target.value;
        if (event.target.name === "email") {
            value = value.toLowerCase();
        }
        if (event.target.name === "email" || event.target.name === "password") {
            if (signUp && event.target.name === "email") {
                let msg = `Confirmation link will be sent to ${value}`;
                addError(msg, 'alert-warning');
            }
            this.setState({loginFailed: false});
        }
        if (event.target.name === "password") {
            this.setState({password2: ""});
        }
        this.setState({[event.target.name]: value, accountChanged: true});
    }

    async handleSubmit(event) {
        const {authUser, callback, signUp, accountReset, addError} = this.props;
        event.preventDefault();

        if (this.state.displaynone !== "") {
            console.log("not expected; probably a bot");
            return;
        }

        if (signUp || accountReset) {
            if (this.state.password !== this.state.password2) {
                let msg = 'Passwords do not match';
                addError(msg, 'alert-danger');
                return;
            }
        }

        let authType = signUp ? "signup" : "signin";
        if (accountReset) {
            authType = "reset";
        }

        var token = '';
        if (this.recaptcha) {
            token = await this.recaptcha.execute(authType);
        }

        // Add your logic to submit to your backend server here.
        authUser(authType, {...this.state, recaptchaToken: token})
        .then(() => {
            if (authType === "reset") {
                let msg = 'Reset-confirmation link sent to your email';
                addError(msg, 'alert-warning');
            }
            if (callback) {
                setTimeout(() => callback(), 100);
            }
        })
        .catch(() => {
            console.log("login failed");
            this.setState({loginFailed: true});
        });
    }

    render() {
        const {email, username, password, password2, profileImageUrl, accountChanged} = this.state;
        const {errors, signUp, buttonText, heading, currentUser, accountReset} = this.props;
        return (
            <div className="row justify-content-md-center text-center">
                
                {!signUp && !accountReset && currentUser.isAuthenticated ? 
                    <Navigate to={"/"+currentUser.user.id+"/models"} /> : ''
                }
                {signUp && currentUser.isAuthenticated ? 
                    <Navigate to={"/"+currentUser.user.id+"/models/new"} /> : ''
                }

                <div className="text-center">
                    {errors.message && (<div className={"m-4 alert "+errors.style}> {errors.message} </div>)}
                </div>
                
                <div className="col-md-4">
                
                    <form onSubmit={this.handleSubmit}>
                    <h2>{heading}</h2> 

                    <label className="m-2" htmlFor="email">E-mail</label>
                    <input
                        autoComplete="off"
                        className="form-control form-bordercontrol"
                        id="email"
                        name="email"
                        onChange={this.handleChange}
                        type="text"
                        value={email}
                    />

                    <label className="m-2" htmlFor="password">{accountReset?"New password":"Password"}</label>
                    <input
                        autoComplete="off"
                        className="form-control form-bordercontrol"
                        id="password"
                        name="password"
                        onChange={this.handleChange}
                        type="password"
                        value={password}
                    />

                    <input className="displaynone" name="displaynone" type="text"/>

                    {(signUp || accountReset) && (
                    <div>
                        <label className="m-2" htmlFor="Retype password">Retype password</label>
                        <input
                            autoComplete="off"
                            className="form-control form-bordercontrol"
                            id="password2"
                            name="password2"
                            onChange={this.handleChange}
                            type="password"
                            value={password2}
                        />
                        
                        <label className="m-2" htmlFor="username">Username</label>
                        <input
                            autoComplete="off"
                            className="form-control form-bordercontrol"
                            id="username"
                            name="username"
                            onChange={this.handleChange}
                            type="text"
                            value={username}
                        />
                        <label className="m-2" htmlFor="image-url">Image URL</label>
                        <input
                            autoComplete="off"
                            className="form-control form-bordercontrol"
                            id="image-url"
                            name="profileImageUrl"
                            onChange={this.handleChange}
                            type="text"
                            value={profileImageUrl}
                        />
                    </div>
                    )}
                    {accountChanged ? 
                        <button type="submit" className="g-recaptcha btn btn-primary btn-block btn-lg m-4">
                            {buttonText}
                        </button> : 
                        <button type="submit" className="g-recaptcha btn btn-primary btn-block btn-lg m-4" disabled>
                        {buttonText}
                    </button>
                    }
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {errors: state.errors, currentUser: state.currentUser};
}

export default connect(mapStateToProps, {addError, authUser})(AuthForm);