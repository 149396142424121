import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Table, Button, Row, Col} from 'react-bootstrap';

import {addError, removeError} from '../store/actions/errors';
import {setVarlabels, setVarfacts, setVarlogs, setVarlags, setTimevar, removeVariable} 
	from '../store/actions/datatable';
import {FaTimes, FaDownload} from 'react-icons/fa';
import {setDataset, setModelStatus, setTimeseries} from '../store/actions/model';
import {MODEL_STATUS_COMPILED} from '../store/actionTypes';
import {removeTschart} from '../store/actions/datagraphs';
import {timedata_combine} from './utils.js';

class DataTable extends Component {
	constructor(props) {
		super(props);
		// varlabels, varfacts and varlogs are of this.props.variables' length
		this.state = {
			varlabels: [], 
			varfacts:  [], 
			varlogs:   [], 
			varlags:   [],
            factlevels:[], 
            vargroups: [],
            allowfactors: false,
            allowlogs:    false,
            allowlags:    false,
			compiled: false, 
			dataset: null, 
            timeunits: '',
            exportCsvFileName: ''
		};
        this.handleChange    = this.handleChange.bind(this);
		this.handleSubmit    = this.handleSubmit.bind(this);
        this.exportCsvFile   = this.exportCsvFile.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
	}

	componentDidMount() {
		var {variables, varlabels, varfacts, varlogs, varlags} = this.props.datatable;
        const {model} = this.props;
        const {dataset, status, timeseries} = model;

        var compiled = status >= MODEL_STATUS_COMPILED;

		var k = 0;
        var vargroups  = [];
		var factlevels = [];
        var allowfactors = false;
        var allowlogs    = false;
        var allowlags    = false;

        var alert = '';

        if (!model) {
			alert = 'No current model.';
            this.props.addError(alert, 'alert-warning');
            return;
		}

		if (variables && variables.length > 0) {
			k = variables.length;
            if (!varlabels || varlabels.length !== k) {
			    varlabels = variables.map((v) => '');
            }
            if (!varfacts || varfacts.length !== k) {
			    varfacts = variables.map((v) => 0);
            }
            if (!varlogs || varlogs.length !== k) {
			    varlogs = variables.map((v) => 0);
            }
            if (!varlags || varlags.length !== k) {
			    varlags = variables.map((v) => 0);
            }
			factlevels = variables.map((v) => this.get_factors(v.data));
		}

		if (model.loglik) {
            vargroups = model.loglik.vars.map((g) => {
                    let groupname = g.groupname;
                    let labels    = g.varnames;
                    var vars = [];
                    if (varlabels) {
                        labels.forEach((v,id) => {
                            if (varlabels.includes(v)) {
                                vars.push(varlabels.indexOf(v));
                            }
                        });
                    }
                    if (vars.length !== labels.length) {
                        compiled = false;
                    }
                    return({groupname, vars, labels});
                }
            );
            allowfactors = model.loglik.varfactors;
            allowlogs    = model.loglik.varlogs;
            allowlags    = model.loglik.varlags;
		}

        if (compiled) {
            alert = 'Current dataset with timeunits of ' + timeseries.units + 
                '. You can click the Update tab to fit the model with current dataset.';
            this.props.addError(alert, 'alert-success');
        }
        else {
            alert = 'Assign each model label a variable from the dataset.';
            this.props.addError(alert, 'alert-warning');
        }

        let exportCsvFileName = model.loglik.name + '_dataset.csv';

        this.setState({
			varlabels, varfacts, varlogs, varlags, 
			vargroups, factlevels, allowfactors, allowlogs, allowlags, 
            dataset, status, compiled, exportCsvFileName});
	}

	get_factors(arr) {
        var i, val, fvals;
        fvals = [];
        for (i = 0; i < arr.length; i++) {
            val = arr[i];
            if (fvals.indexOf(val) < 0) {
                fvals.push(val);
            }
        }
		if (fvals.length > 8 && fvals.length > arr.length/4) {
			return [];
		}
        fvals.sort();
        return fvals;
    }

	async handleDeleteVar(id, name) {
		// action reducers are async functions
        // wait for them to update global state 
        // if you want mapStateToProps to update your this.props
        await this.props.removeVariable(name);
        await this.props.removeTschart(name);

        if (this.props.datatable.variables.length < 1) {
			return;
		}

		// rebuild columns
		this.componentDidMount();
	}

	handleChange(event) {
        var {varlabels, varfacts, varlogs, varlags, vargroups, factlevels} = this.state;
		const {variables} = this.props.datatable;
		const status = 0;
		var alert = '';
        const compiled = false;

        var i, ind;
		let k = variables.findIndex((v) => (v.name === event.target.id));
		varlabels = varlabels.slice();
		varfacts  = varfacts.slice();
		varlogs   = varlogs.slice();
		varlags   = varlags.slice();

        this.props.removeError();

		switch(event.target.name) {
			case 'label':		
				varlabels[k] = event.target.value;
				this.setState({varlabels, status, compiled});
				break;
			case 'factor':
				if (!factlevels[k]) {
					let fvals = this.get_factors(variables[k].data);
					factlevels[k] = fvals;
				}
				if (factlevels[k].length < 2) {
					event.target.disabled = true;
                    alert = variables[k].name + ' is not a categorical variable.'
                    this.props.addError(alert, 'alert-danger');
                    this.setState({compiled});
					break;
				}
				varfacts[k] = event.target.checked ? 1 : 0;
				this.setState({varfacts, factlevels, status, compiled});
				break;
			case 'log-transform':
				// check positivity
				if (!variables[k].data.reduce((c,p) => (p && (c>0)), variables[k].data[0]>0)) {
					event.target.disabled = true;
                    alert = 'Variable ' + variables[k].name + ' must be positive to apply log-transformation.';
                    this.props.addError(alert, 'alert-danger');
					this.setState({compiled});
					return;
				}
				varlogs[k] = event.target.checked ? 1 : 0;
				this.setState({varlogs, status, compiled});
				break;
			case 'check-lags':
				varlags[k] = event.target.checked ? 1 : 0;
				this.setState({varlags, status, compiled});
				break;
			case 'num-lags':
				varlags[k] = parseInt(event.target.value, 10);
				this.setState({varlags, status, compiled});
				break;
			default:
                var target = event.target.name.split('.');
                if (target.length < 2 || target[0] !== 'vars') {
                    break;
                }
                target = target.slice(1).join('.');
                // In SUR may have "eq1 regressors", "eq2 regressors", ...
                // target_common is the common prefix "regressors"
                var target_prefix = target.split(' ');
                if (target_prefix.length > 0) {
                    target_prefix = target_prefix[target_prefix.length-1];
                }
                else {
                    target_prefix = target;
                }
                var included = false;
                var setlabel = '';
                vargroups = vargroups.map((g) => {
                    if (g.groupname !== target) {
                        return (g);
                    }
                    varlabels[k] = '';
                    if (event.target.checked) {
                        if (!g.vars.includes(k)) {
                            if (g.labels.length < 2) {
                                g.vars = [k];
                                setlabel = g.labels[0];
                                varlabels[k] = setlabel;
                            }
                            else if (g.labels.includes(variables[k].name)) {
                                g.vars.push(k);
                                setlabel = variables[k].name;
                                varlabels[k] = setlabel;
                            }
                            else {
                                g.vars.push(k);
                            }
                            included = true;
                        }
                    }
                    else {
                        if (g.vars.includes(k)) {
                            g.vars = g.vars.filter((i) => (i !== k));
                        }
                    }
                    return (g); 
                });
                if (included) {
                    // exclude k from all not-targets unless they have the same label
                    vargroups = vargroups.map((g) => {
                        var grname = g.groupname.split(' ');
                        if (grname.length > 0) {
                            grname = grname[grname.length-1];
                        }
                        else {
                            grname = g.groupname;
                        }
                        if (grname !== target_prefix) {
                            g.vars = g.vars.filter((i) => (i !== k || g.labels.includes(setlabel)));
                        }
                        return (g);
                    });
                    // handle shared labels between different vargroups
                    // do not allow different variables to be assigned the same label
                    ind = [];
                    if (setlabel !== '') {
                        for (i = 0; i < varlabels.length; i++) {
                            if(varlabels[i] === setlabel && i !== k) {
                                ind.push(i);
                            }
                        }
                    }
                    if (ind.length > 0) {
                        ind.forEach(i => {
                            varlabels[i] = '';
                        });
                        vargroups = vargroups.map((g) => {
                            var grname = g.groupname.split(' ');
                            if (grname.length > 0) {
                                grname = grname[grname.length-1];
                            }
                            else {
                                grname = g.groupname;
                            }
                            if (grname !== target_prefix) {
                                // filter out ind from all non-target vargroups
                                g.vars = g.vars.filter((i) => !ind.includes(i));
                            }
                            return (g);
                        });
    
                    }
                }
                this.setState({vargroups, varlabels, status, compiled});
                break;
		}
	}

	async handleSubmit(event) {

		event.preventDefault();

		const {varlabels, varfacts, varlogs, varlags, factlevels, vargroups} = this.state;
		const {variables} = this.props.datatable;
		var alert = '';

        this.props.removeError();
        
        vargroups.forEach((g) => {
            var notmapped = [];
            g.labels.forEach((lab) => {
                if (varlabels.indexOf(lab) < 0) {
                    notmapped.push(lab);
                }
            });
            if (g.vars.length < g.labels.length) {
                alert = `Assign ${g.groupname} label ${g.labels[g.vars.length]} to a variable`;
            }
            else if (notmapped.length > 0) {
                if (g.labels.length === 1) {
                    alert = `Assign ${g.groupname} label ${g.labels[0]} to a variable`;
                }
                else {
                    alert = `All ${g.groupname} labels must be assigned to unique variables`;
                }
            }
        });
		varfacts.forEach((v,i) => {
			//if (v && yvars.includes(i)) {
			//	alert = 'Factor variables cannot be dependent';
			//}
			if (v && varlogs[i]) {
				alert = 'Log-transformation not allowed for factor variables.';
			}
			if (v && varlags[i]) {
				alert = 'Lag-transformation not allowed for factor variables.';
			}
		})
		if (alert !== '') {
            this.props.addError(alert, 'alert-danger');
			return;
		}

        var haslabel = true;
        vargroups.forEach((g) => {
            g.vars.forEach((id) => {
                if (varlabels[id] === '') {
                    haslabel = false;
                }
            });
            if (g.vars.length > g.labels.length) {
                if (g.labels.length === 1) {
                    alert = `${g.labels[0]} is assigned to multiple variables.`;
                }
                else {
                    alert = `Some ${g.groupname} labels are assigned multiple variables.`;
                }
            }
        });
        if (alert !== '') {
            this.props.addError(alert, 'alert-danger');
			return;
		}
		if (!haslabel) {
            alert = 'All selected variables must be assigned labels.';
            this.props.addError(alert, 'alert-danger');
			return;
		}

        let vardata       = new Array(varlabels.length);
        let vartimeunits  = new Array(varlabels.length);
        let vartimeindex  = new Array(varlabels.length);
        let vartimestamps = new Array(varlabels.length);

        varlabels.forEach((lab,i) => {
            if( lab !== '') {
                vardata[i]       = variables[i].data;
                vartimeunits[i]  = variables[i].timeunits;
                vartimeindex[i]  = variables[i].timeindex;
                vartimestamps[i] = variables[i].timestamps;
            }
            else {
                vardata[i]       = null;
                vartimeunits[i]  = null;
                vartimeindex[i]  = null;
                vartimestamps[i] = null;
            }
        });
    
        //console.log("vartimeunits", vartimeunits);
        //console.log("vartimeindex", vartimeindex);
        //console.log("vartimestamps", vartimestamps);
        //console.log("vardata", vardata);

        let res = timedata_combine(vardata, vartimeunits, vartimeindex, vartimestamps);
        const {data, timeunits, timestep, timeindex, misstimes} = res;
    
        if (data.length < 1) {
            if (vardata.length > 1) {
                alert = 'Selected variables have no time overlap.';
            }
            else {
                alert = 'Selected variable has no observations.';
            }
            this.props.addError(alert, 'alert-danger');
            return;
        }

        // update redux datatable state
        this.props.setVarlabels(varlabels);
		this.props.setVarfacts(varfacts);
		this.props.setVarlogs(varlogs);
		this.props.setVarlags(varlags);

		var dataset = null;
		/* eslint-disable no-undef */
		dataset = new Dataset();

        try {
            vargroups.forEach((g) => {
                g.labels.forEach((lab, i) => {
                    let ii = varlabels.indexOf(lab);
                    dataset.addVar(variables[ii].name, data[ii], g.groupname, 
                        (varfacts[ii]?factlevels[ii]:null), varlogs[ii], varlags[ii]);
                });
            });
            // (name, data, label)
            dataset.addVar(timeunits, timeindex, 'time');
        }
        catch(err) {
            alert = 'Build error: ' + err.message;
            this.props.addError(alert, 'alert-danger');
            return;
        }
    
        alert = 'Dataset of length ' + dataset.length() + ' with timeunits of ' + timeunits + 
            '. You can click the Update tab to fit the model with current dataset.';
        if (misstimes.length > 0) {
            let utctime = new Date(misstimes[0]);
            alert += ` Missing values for ${utctime.toUTCString()}`;
            this.props.addError(alert, 'alert-warning');
        }
        else {
            this.props.addError(alert, 'alert-success');
        }

        this.props.setTimevar(timeunits);

		this.props.setDataset(dataset);
		/* eslint-enable no-undef */

		// update global state
		this.props.setModelStatus(MODEL_STATUS_COMPILED);
        this.props.setTimeseries({units: timeunits, utcstep: timestep});

		this.setState({dataset, status: MODEL_STATUS_COMPILED, compiled:true, timeunits});
	}

    exportCsvFile(event) {
        this.setState({exportCsvFileName: event.target.files[0].name});
    }

    downloadCsvFile(event) {
        const {dataset, exportCsvFileName} = this.state;
        const {timevar} = this.props.datatable;

        if (!dataset) {
            this.props.addError('No dataset available.', 'alert-danger');
			return;
        }

        var textbuff = '';
        /* eslint-disable no-undef */
        textbuff = dataset.exportCsvTimeseries(timevar);
        /* eslint-enable no-undef */

        let blob = new Blob(textbuff, {type:'text/plain'});
        let link = document.createElement("a");
        link.download = exportCsvFileName;
        //link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
    }

	render() {
		const {varlabels, vargroups, varfacts, varlogs, varlags, factlevels} = this.state;
        var {allowfactors, allowlogs, allowlags} = this.state;
        var {compiled, exportCsvFileName}    = this.state;
		const {variables} = this.props.datatable;

		var factvar, logvar, lagvar, label, factname;

        var trunc5 = false;
        let maxwidth = window.matchMedia('(max-width: 800px)').matches;
        if (maxwidth) {
            allowfactors = false;
            allowlogs    = false;
            allowlags    = false;
            trunc5       = true;
        }

        var varOptions = vargroups.map((g) => 
            g.labels.map((s, id) => <option key={id} value={s}>{s}</option>)
        );
        var varTypes = vargroups.map((g) => '');

		var lagsOptions = [...Array(9).keys()].slice(1); // = [1,2,3,4,5,6,7,8];
		lagsOptions = lagsOptions.map((s, i) => <option key={i} value={s}>{s}</option>);

		const buttontext  = 'Compile dataset';
        const novarserror = 'No variables available - use Data tab to import some.';

        var vartable = '';
		if (!variables || variables.length < 1) {
            this.props.addError(novarserror, 'alert-warning');
            return <div></div>;
        }

        var tbody = [];
        var kid = 0;
        variables.forEach((v, id) => {

            varTypes = vargroups.map((g) => 
                <input className="form-check-input" type="checkbox" 
                    name={'vars.'+g.groupname} id={v.name} 
                    onChange={this.handleChange}  checked={g.vars.includes(id)} />
            );

            //<option key={id} value={varlabels[id]}>{varlabels[id]}</option>
            label = '';
            vargroups.forEach((g, gid) => {
                if (g.vars.includes(id)) {
                    label = 
                        <select className="form-select form-select-sm" name="label" id={v.name} 
                            value={varlabels[id]} onChange={this.handleChange}>
                            <option key={id} value={varlabels[id]}>{varlabels[id]}</option>
                            {varOptions[gid]}
                        </select>;
                }
            });

            var start_index = v.timestamps[0];
            var end_index   = v.timestamps[v.timestamps.length-1];
            if (varlags[id]) {
                start_index += varlags[id];
            }

            if (allowlogs) {
                logvar = <input className="form-check-input" type="checkbox" name="log-transform" id={v.name} 
                    checked={varlogs[id]===1} onChange={this.handleChange} />;
            }

            if (allowlags) {
                if (varlags[id] > 0) {
                    lagvar = 
                        <div className="d-md-flex">
                        <input className="form-check-input m-2" type="checkbox" name="check-lags" id={v.name} 
                            checked={varlags[id]>=1} onChange={this.handleChange} />
                        <select className="form-select form-select-sm w-50" name="num-lags" id={v.name} 
                            value={varlags[id]} onChange={this.handleChange}>
                            <option key={id} value={varlabels[id]}>{varlabels[id]}</option>
                            {lagsOptions}
                        </select>
                        </div>;
                }
                else {
                    lagvar = 
                        <input className="form-check-input" type="checkbox" name="check-lags" id={v.name} 
                        checked={varlags[id]>=1} onChange={this.handleChange} />;
                }
            }

            if (allowfactors) {

                factvar = <input className="form-check-input" type="checkbox" name="factor" id={v.name} 
                    checked={varfacts[id]===1} onChange={this.handleChange} />;

                if (varfacts[id] && factlevels[id] && factlevels[id].length > 1) {
                    tbody.push(
                        <tr key={kid++}>
                            <th>{v.name}</th>
                            <th>{v.timeunits}</th>
                            <th>{start_index}</th>
                            <th>{end_index}</th>
                            {allowfactors?<th>{factvar}</th>:''}
                            {allowlogs?<th>{logvar}</th>:''}
                            {allowlags?<th>{lagvar}</th>:''}
                            {varTypes.map((vt, gi)=> <th key={gi}>{vt}</th>)}
                            <th>{label}</th>
                            <th>
                                <button onClick={this.handleDeleteVar.bind(this, id, v.name)} className="bmodel-delete">
                                    <FaTimes color='#8b0020'/>
                                </button>
                            </th>
                        </tr>
                    );
                    for (var i = 1; i < factlevels[id].length; i++) {
                        factname = v.name+':'+factlevels[id][i];
                        tbody.push(
                            <tr key={kid++}>
                                <th style={{"textAlign":"right"}}>{factname}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                {allowfactors?<th></th>:null}
                                {allowlogs?<th></th>:null}
                                {allowlags?<th></th>:null}
                                {varTypes.map((vt, gi)=> <th key={gi}></th>)}
                                <th></th>
                                <th></th>
                            </tr>
                        )
                    }
                }
            } // allowfactors
            else {
                tbody.push(
                    <tr key={kid++}>
                        <th>{v.name}</th>
                        <th>{v.timeunits}</th>
                        <th>{start_index}</th>
                        <th>{end_index}</th>
                        {allowfactors?<th>{factvar}</th>:null}
                        {allowlogs?<th>{logvar}</th>:null}
                        {allowlags?<th>{lagvar}</th>:null}
                        {varTypes.map((vt, gi)=> <th key={gi}>{vt}</th>)}
                        <th>{label}</th>
                        <th>
                            <button onClick={this.handleDeleteVar.bind(this, id, v.name)} className="bmodel-delete">
                                <FaTimes color='#8b0020'/>
                            </button>
                        </th>
                    </tr>
                );
            }

        });
        
        if (variables.length > 0) {
            vartable = <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th scope="column">Variable</th>
                    <th scope="column">Time</th>
                    <th scope="column">Start</th>
                    <th scope="column">End</th>
                    {allowfactors?<th scope="column">Factor</th>:null}
                    {allowlogs?<th scope="column">Log</th>:null}
                    {allowlags?<th scope="column">Lags</th>:null}
                    {vargroups.map((g, gi)=> 
                    <th key={gi}>
                        {trunc5 ? g.groupname.split(' ')[0].substring(0,5) : g.groupname.split(' ')[0]}
                    </th>)
                    }
                    <th scope="column">Label</th>
                    <th scope="column"></th>
                </tr>
                </thead>
                <tbody>
                {tbody}
                </tbody>
            </Table>;
        }
        else {
            this.props.addError(novarserror, 'alert-warning');
        }

		return (
            <Container className="text-center">

                {vartable}

                {compiled ? 
                    <div>
                        <Button variant="primary" id="categories-up" disabled onClick={this.handleSubmit}>
                            {buttontext}
                        </Button> 
                        <Row className="m-4 p-2 bmodel-form">
                            <Col>
                                <label className="m-2" htmlFor="username">{`Download as ${exportCsvFileName}`}</label>
                            </Col>
                            <Col>
                                <input type="file" className="form-control" accept=".csv" 
                                    id="exportCsvFile" onChange={this.exportCsvFile} />
                            </Col>
                            <Col>
                                <Button onClick={this.downloadCsvFile} className="bmodel-download">
                                    <FaDownload size={18} color='#208b20'/>
                                </Button>
                            </Col>
                        </Row>        
                        
                    </div> :
                    <Button variant="primary" id="categories-up" onClick={this.handleSubmit}>
                        {buttontext}
                    </Button>
                }
			</Container>
		);
	}
}

function mapStateToProps(state, ownProps) {
	//const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
	return {datatable: state.datatable, model: state.model};
}

export default connect(mapStateToProps, {addError, removeError, 
	setDataset, setVarlabels, setVarfacts, setVarlogs, setVarlags, setModelStatus, 
	removeVariable, setTimeseries, removeTschart, setTimevar
})(DataTable);
