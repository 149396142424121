import React, {Component} from 'react';
import {Container,Navbar, Nav} from 'react-bootstrap';

import {connect} from 'react-redux';
import {logout} from '../store/actions/auth';

class AppNavbar extends Component {

    render() {
        const {currentUser, logout} = this.props;
         return (
        <Navbar>
            <Container>
                <Nav variant="pills" className="navbar-nav">
                {currentUser.isAuthenticated ? 
                    <Nav.Item>
                        <Nav.Link href="/models">Models</Nav.Link>
                    </Nav.Item> : ''
                }
                {currentUser.isAuthenticated ? 
                    <Nav.Item>
                        <Nav.Link href="/new">New</Nav.Link>
                    </Nav.Item> : ''
                }
                </Nav>
                {currentUser.isAuthenticated ? 
                    <Nav variant="pills" className="flex-row nav navbar-nav navbar-right">
                        {currentUser.user.profileImageUrl ? 
                            <Nav.Item>
                                <a href={currentUser.user.profileImageUrl} target="_blank" rel="noreferrer">
                                <img src={currentUser.user.profileImageUrl} alt={currentUser.user.username} className="profile-image"/>
                                </a>
                            </Nav.Item> : ''
                        }
                        <Nav.Item>
                            <Nav.Link href="/signin" onClick={logout}>Sign off</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/account">Account</Nav.Link>
                        </Nav.Item>
                    </Nav> :
                    <Nav variant="pills" className="flex-row nav navbar-nav navbar-right">
                        <Nav.Item>
                            <Nav.Link href="/signup">Sign up</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/signin">Sign in</Nav.Link>
                        </Nav.Item>
                    </Nav>
                }
            </Container>
        </Navbar>
        );
    }
}

// redux state to react properties
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  }; 
}

export default connect(mapStateToProps, {logout})(AppNavbar);