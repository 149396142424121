import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Col, Row, InputGroup, FormControl} from 'react-bootstrap';

import McmcPlots from './McmcPlots';
import {addError, removeError} from '../store/actions/errors';
import {MCMC_PLOTS_FCAST} from './McmcPlots';
import {MODEL_STATUS_SAMPLED} from '../store/actionTypes';
import {setFcastSample} from '../store/actions/model';
import {parseNumber, parsePositiveNumber} from './utils';

class FcastSample extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timestart:  0,
			timesteps:  0,
            crilevel:   0.1,
            updated: false,
		};
		this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
	}

    componentDidMount() {
        var {timestart, timesteps, crilevel} = this.state;
        const {fcastsample, postmodel} = this.props.model;
        if (fcastsample) {
            timestart = fcastsample.timestart();
            timesteps = fcastsample.timesteps();
            crilevel  = fcastsample.crilevel();
        }
        else if (postmodel) {
            timestart = postmodel.fcast_min(8);
            timesteps = 8;
            if (timestart > postmodel.fcast_max(timesteps)) {
                timesteps = timestart - postmodel.fcast_max(timesteps);
            }
        }
        this.setState({timestart, timesteps, crilevel});
        setTimeout(() => this.handleUpdate(), 50);
	}

    handleChange(event) {
        var value = event.target.value;
        var alert = '';

        this.props.removeError();

        if (event.target.name === 'timestart') {
            const {postmodel} = this.props.model;
            value = parseNumber(value);
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            let minstart = postmodel.fcast_min();
            if (value < minstart) {
                alert = `${event.target.name} must be at least ${minstart}`;
            }
            let maxstart = postmodel.fcast_max();
            if (value > maxstart) {
                alert = `${event.target.name} must be less than ${maxstart+1}`;
            }
        }
        if (event.target.name === 'timesteps') {
            const {postmodel} = this.props.model;
            let {timestart} = this.state;
            value = parseNumber(value);
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value < 1) {
                alert = `${event.target.name} mus be at least 1`;
            }
            if (value > 100) {
                value = 100;
                alert = `${event.target.name} is limited to 100.`;
            }
            let maxsteps = postmodel.fcast_max() - timestart;
            if (value > maxsteps) {
                alert = `${event.target.name} must be less than ${maxsteps+1}.`;
            }
        }
        if (event.target.name === 'crilevel') {
            value = parsePositiveNumber(value);
            if (value >= 0.5) {
                alert = `Credible interval level must be less than 0.5.`;
            }
            else if (value <= 0) {
                alert = `Credible interval level must be greater than 0.`;
            }
            else {
                value = parseFloat(value);
                if (isNaN(value)) {
                    alert = `Credible interval level must be a number.`;
                }
            }
        }

        if (alert !== '') {
            this.props.addError(alert, 'alert-danger');
        }

        this.setState({[event.target.name]: value, updated: false});

        if (alert === '') {
            setTimeout(() => this.handleUpdate(), 100);
        }
	}

    async handleUpdate() {
        const {timestart, timesteps, crilevel} = this.state;
        const {status, postmodel, mcmcsample, fcastsample} = this.props.model;

        var alert = '';

        this.props.removeError();

        if (status < MODEL_STATUS_SAMPLED) {
            alert = 'You must fit the model first.';
            this.props.addError(alert, 'alert-danger');
            return;
        }

        if (fcastsample) {
            if (fcastsample.timestart() === timestart && 
                fcastsample.timesteps() === timesteps && 
                fcastsample.crilevel()  === crilevel) {
                this.setState({updated: true});
                return;
            }
        }

        if (crilevel <= 0 || crilevel >= 0.5) {
            alert = `Invalid crilevel of ${crilevel}.`;
            this.props.addError(alert, 'alert-danger');
            return;
        }

        var fsample = await postmodel.fcast(mcmcsample, timestart, timesteps, crilevel);

        this.props.setFcastSample(fsample);

        this.setState({updated: true});
	}

	render() {
        const {timestart, timesteps, crilevel, updated} = this.state;
        return (
            <Container fluid>
				
				<Row className="form-postestimation">
                    <Col>
                        <InputGroup>
                        <InputGroup.Text className="form-control-label">Start</InputGroup.Text>
                        <FormControl type="text" className="form-bordercontrol" name='timestart'
                            value={timestart} onChange={this.handleChange} />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                        <InputGroup.Text className="form-control-label">Steps</InputGroup.Text>
                        <FormControl type="text" className="form-bordercontrol" name='timesteps'
                            value={timesteps} onChange={this.handleChange} />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                        <InputGroup.Text className="form-control-label">Level</InputGroup.Text>
                        <FormControl type="text" className="form-bordercontrol" name='crilevel'
                            value={crilevel} onChange={this.handleChange} />
                        </InputGroup>
                    </Col>
                </Row>

                {updated ? 
                    <McmcPlots type={MCMC_PLOTS_FCAST} /> : ''
                }

			</Container>
        );
	}
}

function mapStateToProps(state, ownProps) {
	const {user_id, id} = ownProps;
    //assert(id == state.model.loglik._id);
	return {model: state.model, user_id, id};
}

export default connect(mapStateToProps, {addError, removeError, setFcastSample})(FcastSample);
