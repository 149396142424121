class TsChart {
    
    static PRIMARY = ['#0E5C91', '#D33742', '#F57A3D', '#FEC954', '#19BABB', '#7F4B6B'];
    static NEUTRAL = ['#000000', '#222934', '#6C727F', '#D3D6DB', '#F4F5F7'];
    static LAND    = ['#4E4B47', '#9D9793', '#DFDFDF'];
    static SEA     = ['#1989A2', '#6FD0DF', '#E7F3F1'];

    static g_counter = 0;

	constructor(timeunits, timeutc, timeindex, yname, y, parentRef) {

        TsChart.g_counter++;
        this.id = TsChart.g_counter;

        this.varname = yname;

        // timeindex and timeutc are usually the same

		this.timeunits = timeunits;
		this.timeutc   = timeutc;
		this.x     = timeindex;
		this.yname = yname;
		this.y     = y;

		this.xmin = 0;
		this.xmax = 0;
		this.ymin = 0;
		this.ymax = 0;

		this.x_padding = 0;
		this.y_padding = 0;

		this.x_offset  = 0;
		this.y_offset  = 0;

		this.x_offsetMin = 0;
		this.y_offsetMin = 0;
		this.x_offsetMax = 0;
		this.y_offsetMax = 0;

		this.x_step = 1;

		this.timeformat = ['minute', 'hour', 'day', 'week', 'biweek', 'month', 'quarter', 'halfyear', 'year'];
		this.months     = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		this.weeks      = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		this.quarters   = ['Q1', 'Q2', 'Q3', 'Q4'];

		this.canvas = 'undefined';
		this.ctx    = 'undefined';

		this.colorLine = TsChart.PRIMARY[0]; //"#0d6efd";
	    this.colorText = TsChart.NEUTRAL[1]; //"#222";
	    this.colorGrid = TsChart.NEUTRAL[2]; //"#888";

		this.handleTslineOver = this.handleTslineOver.bind(this);
        this.resizeWindow     = this.resizeWindow.bind(this);
        
        //console.log("TSChart", timeunits, timeutc, timeindex);

		this.exampleTimeStamp = "Sep 1950";
		switch (timeunits) {
			case 'index':
				this.exampleTimeStamp = ' ' + y.length.toString();
				break;
			default:
		}

        if (parentRef) {
            this.setupCanvas(parentRef);
        }
	}

    static deleteCharts(parentRef) {
		if (!parentRef || !parentRef.childNodes) {
            return;
        }
  		var n = parentRef.childNodes.length;
		if (n < 1) {
			return;
		}
		for (var j = n-1; j >= 0; j--) {
      		parentRef.removeChild(parentRef.childNodes[j]);
    	}
	}

    setupCanvas(parentRef) {

		let canvas = document.createElement("canvas");
		canvas.className = 'tsplot';
		parentRef.appendChild(canvas);
        
        var ctx = canvas.getContext('2d');

        this.parentRef = parentRef;
		this.canvas    = canvas;
		this.ctx       = ctx;

		this.resizeCanvas();
		this.draw();

        window.addEventListener('resize', this.resizeWindow);
        canvas.addEventListener("mousemove", this.handleTslineOver);
	}

    resizeWindow() {
        this.resizeCanvas();
		this.draw();
    }
      
	format_time(timeunits, utctime, noyear, noday) {
		var date, year, qtr, hr, str;
		switch (timeunits) {
	    	case 'minute':
	    		date  = new Date(utctime);
	    		//toTimeString(): 15:58:00 GMT-0500 (Central Daylight Time)
	    		if (noyear) {
	    			str = date.toTimeString().split(' ').splice(0,1).join(' ');
	    		}
	    		else {
	    			str = date.toTimeString().split(' ').splice(0,2).join(' ');
	    		}
	    		return(str);
	    	case 'hour':
	    		date  = new Date(utctime);
	    		hr = date.toTimeString().split(' ')[0];
	    		hr = hr.split(':').splice(0,2).join(':');
	    		if (noyear) {
	    			str = date.toDateString().split(' ').splice(1,2).join(' ');
	    		}
	    		else {
	    			str = date.toDateString().split(' ').splice(1,2).join(' ');
	    		}
	    		return(str+' '+hr);
	    	case 'day':
	    		date  = new Date(utctime);
	    		//toDateString(): Sun Aug 30 2020
	    		if (noyear) {
	    			str = date.toDateString().split(' ').splice(1,2).join(' ');
	    		}
	    		else {
                    str = date.toDateString().split(' ');
                    if (noday) {
                        str = str[1] + ' ' + str[3];
                    }
                    else {
                        str = str.splice(1).join(' ');
                    }
	    		}
	    		return(str);
			case 'week':
            case 'biweek':
	    		date = new Date(utctime);
	    		if (noyear) {
	    			str = date.toDateString().split(' ').splice(1,2).join(' ');
	    		}
	    		else {
	    			str = date.toDateString().split(' ');
                    if (noday) {
                        str = str[1] + ' ' + str[3];
                    }
                    else {
                        str = str.splice(1).join(' ');
                    }
	    		}
	    		return(str);
	    	case 'month':
	    		date  = new Date(utctime);
	    		year = date.getUTCFullYear();
				return(this.months[date.getMonth()] + ' ' + year.toString());
	    	case 'quarter':
	    		date  = new Date(utctime);
	    		year = date.getUTCFullYear();
	    		qtr = Math.floor(date.getMonth()/4);
				return(this.quarters[qtr] + ' ' + year.toString());
	    	case 'year':
	    		date  = new Date(utctime);
	    		year = date.getUTCFullYear();
				return(year.toString());
			case 'index':
			default:
				return(utctime.toString());
	    }
	}

	resizeCanvas() {

        this.canvas.width = this.parentRef.clientWidth*0.80;
		if(this.canvas.width > 800) {
            this.canvas.width = 800;
        }
		this.canvas.height = this.canvas.width / 4;

        // setup high DPI text
		// Get the device pixel ratio, falling back to 1.
		//const dpr = window.devicePixelRatio || 1;
        const dpr = 1;
		// Get the size of the canvas in CSS pixels.
		var rect = this.canvas.getBoundingClientRect();
		// Give the canvas pixel dimensions of their CSS
		// size * the device pixel ratio.
		this.canvas.width  = rect.width  * dpr;
		this.canvas.height = rect.height * dpr;

		// Scale all drawing operations by the dpr, so you
		// don't have to worry about the difference.
		this.ctx.scale(dpr, dpr);

        // maximum
        this.x_padding = 4;
		this.y_padding = 4;
		this.x_offset  = 36;
		this.y_offset  = 36;
        this.ctx.font = "14px Arial";

        // sample text
        const metrics = this.ctx.measureText('-12.345');
        var r = Math.min(
            Math.floor(this.canvas.width/18) /  metrics.width, 
            Math.floor(this.canvas.height/12) / metrics.actualBoundingBoxAscent);

        let newsize = Math.min(Math.max(Math.floor(14*r),1), 16);
        this.ctx.font  = newsize + "px Arial";
        this.x_padding = Math.floor(4*r);
		this.y_padding = Math.floor(4*r);
		this.x_offset  = Math.floor(36*r);
		this.y_offset  = Math.floor(36*r);
	}

	handleTslineOver(event) {
		var i, x, xval, xtime, metrics, xx, yy;

        x = -1;
		if (event.offsetX < this.x_offsetMin || 
			event.offsetY < this.y_offsetMin) {
		}
		else if (event.offsetX > this.x_offsetMax || 
			event.offsetY > this.y_offsetMax) {
		}
		else {
			x = event.offsetX - this.x_offsetMin;
		}

		xval = '';
		i = -1;
		if (x >= 0) {
			i = Math.floor(this.x.length*x/this.x_size);
		}
		if (i >= 0 && i < this.x.length) {
			xval  = this.y[i];
			xtime = this.format_time(this.timeunits, this.timeutc[i]);
			xval  = '        ' + xtime + ': ' + xval.toString();
		}
		xval = this.yname + xval;

		metrics = this.ctx.measureText(this.yname);
        yy = metrics.actualBoundingBoxAscent + 2*this.y_padding;
        xx = this.x_size/2-metrics.width/2;
		this.ctx.clearRect(xx, this.y_padding, this.x_size/2, this.y_offset-this.y_padding);
        this.ctx.fillText(xval, xx, yy);
	}

	/**
	 * Base64 to Blob
	 *
	 * @param {string} data Data.
	 * @param {string} type Content type.
	 * @return {!Blob} Blob.
	 */
	base64toBlob = function(data, type) {
		//atob(): decodes a string of data which has been encoded using Base64 encoding
		const bytes = window.atob(data);
		let length = bytes.length;
		let out = new Uint8Array(length);
		// Loop and convert.
		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}
		return new Blob([out], { type: type });
	};

	save() {
		var image = this.canvas.toDataURL("image/png");
		//show it in current page
		//document.write('<img src="'+image+'"/>');
		image = image.split(',')[1];
		let blob = this.base64toBlob(image, 'image/png');

        let link = document.createElement("a");
        link.download = 'tschart.png';
        //link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
	}

	draw() {
		const timeunits = this.timeunits;
        const timeutc   = this.timeutc;
		const canvas = this.canvas;
		const ctx    = this.ctx;
        const yname  = this.yname;
        const x = this.x;
		const y = this.y;

		var n, i, j, xval, yval, xx1, yy1, xx2, yy2, xticks;
	    var metrics, date;

		if (!canvas || !ctx) {
			return;
		}

        if (x.length !== y.length || x.length !== timeutc.length) {
			return;
		}

		this.xmin = Math.min(...x);
		this.xmax = Math.max(...x);

        yval = y.filter((v) => ((v!=='.') && !isNaN(v)));
		this.ymin = Math.min(...yval);
		this.ymax = Math.max(...yval);
        if (this.ymax === this.ymin) {
            this.ymax = this.ymin + 1;
        }

		// measure price value text
        yval = -Math.max(Math.abs(this.ymin), Math.abs(this.ymax));
        //yval = yval.toFixed(2);
        yval = yval.toPrecision(4);
	    metrics = ctx.measureText(yval);

	    if (this.x_offset < metrics.width + this.x_padding) {
	    	this.x_offset = Math.floor(metrics.width + this.x_padding + 1);
	    }
	    if (this.y_offset < metrics.actualBoundingBoxAscent + this.y_padding) {
	    	this.y_offset = Math.floor(metrics.actualBoundingBoxAscent + this.y_padding + 1);
	    }

		this.x_size = Math.floor((canvas.width  - 2*this.x_offset - 2*this.x_padding));
    	this.y_size = Math.floor((canvas.height - 2*this.y_offset - 2*this.y_padding));

		this.rx = (this.x_size)/(this.xmax-this.xmin);
	    this.ry = (this.y_size)/(this.ymax-this.ymin);

		this.x_offsetMin = this.x_offset + this.x_padding;
		this.y_offsetMin = this.y_offset + this.y_padding;
		this.x_offsetMax = this.x_offset + this.x_padding + this.x_size;
		this.y_offsetMax = canvas.height - this.y_padding;

	    n = x.length;
	    if (n > 1000) {
	        this.x_step = Math.floor(n/1000);
	    }

	    switch (timeunits) {
	    	case 'minute':
	    		xticks = Math.floor(n/3600);
	    		break;
	    	case 'hour':
	    		xticks = Math.floor(n/60);
	    		break;
	    	case 'day':
	    		xticks = Math.floor(n/30);
	    		break;
			case 'week':
	    		xticks = Math.floor(n/4);
	    		break;
	    	case 'month':
	    		xticks = Math.floor(n/12);
	    		break;
	    	case 'quarter':
	    		xticks = Math.floor(n/4);
	    		break;
	    	case 'year':
	    		xticks = 1;
	    		break;
	    	default:
	    		xticks = 1;
	    }
	    if (xticks <= 1) {
	    	xticks = n - 1;
	    }
        if (xticks > 100) {
            xticks = 100;
        }
        metrics = ctx.measureText(this.exampleTimeStamp);
	    if (xticks > 0.75*this.x_size/metrics.width) {
	    	xticks = Math.floor(0.5*this.x_size/this.x_offset + 0.5);
	    }

        ctx.strokeStyle = this.colorLine;
		ctx.fillStyle = this.colorText;
		ctx.lineWidth = 1;

	    // draw horizontals
		ctx.beginPath();
	    ctx.strokeStyle = this.colorGrid;
	    ctx.setLineDash([2,2]);
	    for (i = 0; i <= 4; i++) {
	    	xx1 = this.x_offset + this.x_padding;
	    	xx2 = this.x_offset + this.x_padding + this.x_size;
	    	
	    	yval = this.ymin+i*(this.ymax-this.ymin)/4;
	    	//yval = yval.toFixed(2);
            yval = yval.toPrecision(4);
	    	
	    	yy1 = canvas.height - this.y_offset - this.y_padding - (yval-this.ymin)*this.ry;
	    	ctx.moveTo(xx1, yy1);
			ctx.lineTo(xx2, yy1);
	    	ctx.stroke();

	    	metrics = ctx.measureText(yval);
	    	ctx.fillText(yval, this.x_padding, yy1 + metrics.actualBoundingBoxAscent/2);
	    }

        metrics = ctx.measureText(yname);
        yy1 = metrics.actualBoundingBoxAscent + 2*this.y_padding;
        ctx.fillText(yname, this.x_size/2-metrics.width/2, yy1);

		// draw verticals
	    //ctx.lineWidth = 1;
	    //ctx.fillStyle = colorLine;
	    //ctx.strokeStyle = 'rgba(128, 128, 128, 1)';

		yy1 = this.y_offset + this.y_padding;
	    yy2 = canvas.height - this.y_offset - this.y_padding;

	    ctx.beginPath();
	    for (i = 0; i <= xticks; i++) {
	    	j = Math.floor((n-1)*i/xticks);
	    	xval = x[j];
	    	xx1 = this.x_offset + this.x_padding + (xval-this.xmin)*this.rx;
			ctx.moveTo(xx1, yy1);
			ctx.lineTo(xx1, yy2);
	    	ctx.stroke();
	    	date = this.format_time(timeunits, timeutc[j], false, true);
	    	metrics = ctx.measureText(date);
	    	xx1 = xx1 - metrics.width/2;	
	    	ctx.fillText(date, xx1, canvas.height - this.y_padding);
	    }

		ctx.setLineDash([]);

		ctx.lineWidth = 2;
		ctx.strokeStyle = this.colorLine;

	    xx1 = this.x_offset + this.x_padding + (x[0]-this.xmin)*this.rx;
	    yy1 = canvas.height - this.y_offset - this.y_padding - (y[0]-this.ymin)*this.ry;
	    for (i = 1; i < n; i += this.x_step) {
	        xx2 = this.x_offset + this.x_padding + (x[i]-this.xmin)*this.rx;
	        yy2 = canvas.height - this.y_offset - this.y_padding - (y[i]-this.ymin)*this.ry;
            if (isNaN(yy2)) {
                continue;
            }
            if (isNaN(yy1)) {
                xx1 = xx2; yy1 = yy2;
                continue;
            }
            ctx.beginPath();
            ctx.moveTo(xx1, yy1);
            ctx.lineTo(xx2, yy2);
            ctx.stroke();
            xx1 = xx2; yy1 = yy2;
	    }
	}

}

export default TsChart;