import Moment from 'react-moment';
import {Accordion, Col, Row} from 'react-bootstrap';

function ModelDescription({loglik, short, hasname}) {
    let {vars, cparams} = loglik;

    if (!vars || !cparams) {
        return (null);
    }
    let gr1vars = vars[0].varnames.join(' ');
    let gr2vars = '';
    let cvalues = '';
    if (cparams.length >= 1) {
        if (loglik.classname === 'BVAR') {
            cvalues = `${cparams[0].value[0]}`;
        }
    }
    if (vars.length > 1) {
        if (gr2vars !== '') {
            gr2vars += ', ';
        }
        gr2vars += vars[1].varnames.join(' ');
        for (var i = 2; i < vars.length; i++) {
            if (!vars[i]) {
                continue;
            }
            gr2vars += ', ' + vars[i].varnames.join(' ');
        }
    }
    if (cvalues !== '') {
        if (gr2vars !== '') {
            gr2vars += '; ' + cvalues;
        }
        else {
            gr2vars = cvalues;
        }
    }
    let vardescr = vars.map((g, i) => {
        if (!vars[i]) {
            return('');
        }
        let varnames = g.varnames.length > 0 ? g.varnames.join(' ') : 'none';
        return (
        <Row className="m-2" key={i}>
            <Col style={{'color':'gray', 'fontStyle':'normal', 'textAlign':'right'}}>
                {g.groupname}:
            </Col>
            <Col style={{'fontStyle':'normal', 'textAlign':'left'}}>
                {varnames}
            </Col> 
        </Row>);
    });
    if (short) {
        return (
            <div className="">
                <h4 className="p-2">
                    {hasname ? 
                        <span style={{'color':'gray', 'fontStyle':'normal'}}>
                            {loglik.name + ': '}
                        </span> : ''
                    }
                    {gr1vars} ~ {loglik.classname}({gr2vars})
                </h4>
            </div>
        );
    }
    return (
        <div className="bmodel-description">

            <Accordion defaultActiveKey="0">
            <Accordion.Item className="m-1" key="0">
                <Accordion.Header className="text-center !important">
                    {hasname ? 
                    <Row style={{'width':'80%', 'margin':'auto'}}>
                        <Col style={{'color':'gray', 'textAlign':'right'}}>
                            {loglik.name}:
                        </Col>
                        <Col style={{'textAlign':'left'}}>
                            {gr1vars} ~ {loglik.classname}({gr2vars})
                        </Col> 
                    </Row> 
                    :
                    <h4 className="p-2">
                        {gr1vars} ~ {loglik.classname}({gr2vars})
                    </h4>
                    }
            </Accordion.Header>
            <Accordion.Body className="p-2">
            <h5 className="m-2" style={{'fontStyle': 'normal'}}>
                    {loglik.description + ' model'}
                </h5>
                <h5 className="p-1">
                    {vardescr}
                </h5>
                
                <Row className="m-2">
                    <Col style={{'color':'gray', 'fontStyle':'normal', 'textAlign':'right'}}>
                        Last updated:
                    </Col>
                    <Col style={{'fontStyle':'normal', 'textAlign':'left'}}>
                        <Moment format="Do MMM YYYY">{loglik.updatedAt}</Moment>
                    </Col> 
                </Row>
            </Accordion.Body>
            </Accordion.Item>

            </Accordion>

        </div>
    );
}

export default ModelDescription;